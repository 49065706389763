import React from 'react';
import PropTypes from 'prop-types';

function Button({
    onClick, title, disabled, width, padding, fontSize,
    }) {
    return (
        <button
            type="button"
            className={[`bg-tl-red rounded-full ${padding} text-white ${fontSize} ${width} hover:bg-red-900`,
                disabled ? 'text-gray-300 bg-gray-500' : 'text-white'].join(' ')}
            onClick={onClick}
        >
            {title}
        </button>
    );
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string,

};

Button.defaultProps = {
    disabled: false,
    width: 'w-full',
    padding: 'py-3 px-6',
    fontSize: 'text-sm',
};

export default Button;
