/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Modal from 'react-modal';
import { IoClose } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import Tooltip from 'react-simple-tooltip';
import {
    callMe, CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE, CONTACT_METHOD_VIDEO, modalCustomStyles,
} from '../utils';
import useAppStore from '../contexts/useAppStore';
import AppointmentForm from './AppointmentForm';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import ButtonInverted from './elements/ButtonInverted';
import EmergencyEmailForm from './EmergencyEmailForm';
import AgentAffiliation from './elements/AgentAffiliation';

function AgentLabel({ agent }) {
    const {
        firstName, lastName, city, state,
    } = agent;

    const [showPinBar, setShowPinBar] = useState(false);

    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, setIsAppointmentFormModalOpen, setIsEmergencyFromModalOpen, isEmailFormModalOpen, isPhoneFormModalOpen, isAppointmentFormModalOpen, isEmergencyFromModalOpen, closeFormModal,
    } = useAppStore();

    const destinations = agent.agentDestinations ? agent.agentDestinations.map((x) => (x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area))) : [];
    const interests = agent.agentInterests ? agent.agentInterests.map((x) => x.interestName) : [];
    const suppliers = agent.agentSuppliers ? agent.agentSuppliers.map((x) => x.supplierName) : [];
    const icAgent = agent.icAgent ? '1' : '0';
    const icBusinessName = agent.icBusinessName === '' ? null : agent.icBusinessName;
    const canonical = agent.isVacationAgent === true ? 'https://www.vacation.com/agent/' + agent.agentId : window.location.origin + window.location.pathname;

    const pinnedBar = () => (
        <div className="flex flex-col md:flex-row justify-between pt-0 md:pt-4 pb-2 w-full gap-2 md:gap-4">
            <div className="flex flex-col md:flex-row gap-0 items-center flex-1">
                <div className="md:border-r md:px-2">
                    <h1 className="tl-cyan text-xl uppercase text-center md:text-left">{`${firstName} ${lastName}`}</h1>
                </div>
                <div className="font-medium text-l text-center md:px-2 md:text-left">{`${city}, ${state} Travel Agent`}</div>

            </div>
            <div className="flex flex-row gap-3 flex-1">
                {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                    <div className="w-full ">
                        <ButtonInverted
                            disabled={agent.isOffline}
                            title="EMAIL ME"
                            onClick={() => (!agent.isOffline ? setIsEmailFormModalOpen(true) : handleNoClick)}
                        />
                    </div>
                )}

                {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                    <div className="w-full">
                        <ButtonInverted
                            disabled={agent.isOffline}
                            title="CALL ME"
                            onClick={!agent.isOffline ? () => {
                                callMe(agent);
                                setIsPhoneFormModalOpen(true);
                            } : handleNoClick}
                        />
                    </div>
                )}
            </div>

        </div>
    );

    const listenToScroll = () => {
        const heightToHideFrom = 450;

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) { setShowPinBar(true); } else { setShowPinBar(false); }
    };
    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('resize', listenToScroll);
        };
    }, []);
    const handleNoClick = (e) => {
        e.preventDefault();
    };

    return (
        <>

            <Helmet>
                <title>{`${firstName} ${lastName} : ${city}, ${state} Travel Agent`}</title>
                <meta name="title" content={`${firstName} ${lastName} ${city}, ${state} Travel Agent`} />
                <meta
                    name="description"
                    content={`${firstName} is a ${city}, ${state} travel agent specializing in ${destinations.map((x) => ` ${x}`)}, ${interests.map((x, index) => {
                        if (interests.length - 1 === index) {
                            return ` and ${x}`;
                        }

                        return ` ${x}`;
                    })}. Contact ${firstName} today.`}
                />
                <meta
                    property="og:image"
                    content={agent.agentPhotoFileName}
                />
                <script type="application/ld+json">
                    {`
           {
            "@context": "https://schema.org",
            "@type": "TravelAgency",
            "name": "${firstName.replace(/["]+/g, '')} ${lastName.replace(/["]+/g, '')}",
            "telephone": "${agent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}",
            "description": "${agent.overviewTitle.replace(/["]+/g, '')}",
            "url": "https://www.travelleaders.com/agent/${agent.agentId}",
            "logo": "https://www.travelleaders.com/images/logo_TL.png",
            "image": "${agent.agentPhotoFileName}",
            "sameAs": [${agent.agentConnections.filter((x) => !(['by Email', 'by Phone', 'Video Appointment'].includes(x.contactMethod.replace(/["]+/g, '')))).map((x) => (`"${x.contactValue.replace(/["]+/g, '')}"`))}],
            "knowsAbout":[ ${destinations.map((x) => `"${x}"`)}
             ${interests.length > 0 ? ',' : ''}${interests.map((x) => `"${x}"`)}
             ${suppliers.length > 0 ? ',' : ''}${suppliers.map((x) => `"${x}"`)}
           ],
            "review": [

              ${agent.agentReviews && agent.agentReviews.slice(0, 10).map((x) => (`{

                    "@type": "Review",
                    "author": {
                        "@type": "Person",
                        "name": "${x.customerNickname.replace(/["]+/g, '')}"
                    },
                    "about": "${x.customerDestination.replace(/["]+/g, '')}",
                    "reviewBody": "${x.review.replace(/["]+/g, '')}",
                    "reviewRating": {
                      "@type": "Rating",
                      "bestRating": "5",
                      "ratingValue": "${x.rating}",
                      "worstRating": "1"
                    }

                  }`))
                        }         
            ],
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "${city.replace(/["]+/g, '')}",
              "addressRegion": "${state.replace(/["]+/g, '')}"
            }
            ${agent.agentRating.totalReviews > 0 ? (`
              ,"aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "${agent.agentRating ? agent.agentRating.rating : 0}",
              "reviewCount": "${agent.agentRating ? agent.agentRating.totalReviews : 0}"
                }
            `) : ''}
          }
          `}
                </script>
                <link rel="canonical" href={canonical} />
            </Helmet>
            <div className={[showPinBar ? 'opacity-100' : 'opacity-0 hidden-tw', 'transition-all fixed right-0 left-0 top-16 bg-white px-6 max-w-screen-2xl mx-auto z-20 border-b border-gray-normal shadow-md'].join(' ')}>{pinnedBar()}</div>
            <div>
                {agent.preferredAgent === true ? (
                    <div className="mb-4 relative">
                        <Tooltip placement="bottom" padding={5} content="A Super Agent is a top agent based on their detailed profile, level of expertise, and positive customer feedback.">
                            <img src="/images/icon-super-agent.svg" height="23" width="100" alt="Super Agent" loading="lazy" />
                        </Tooltip>
                    </div>
                ) : ('')}
            </div>
            <h1 className="tl-cyan text-2xl md:text-4xl uppercase text-center md:text-left">{`${firstName} ${lastName}`}</h1>
            <div className="font-medium text-l md:text-xl text-center md:text-left">
                <h2>{`${city}, ${state} Travel Agent`}</h2>
            </div>
            <div className="">
                <AgentAffiliation
                    icAgent={icAgent}
                    agencyLevel={agent.agencyLevel}
                    agencyRecordType={agent.agencyRecordType}
                    icBusinnessName={icBusinessName}
                    agencyName={agent.hostAgency}
                    hostAgency={agent.hostAgency}
                    securityLevel={agent.securityLevel}
                    hostCity={agent.hostAgencyCity}
                    hostState={agent.hostAgencyState}
                />
            </div>
            {agent.agentConnections.length > 0 && (
                <div className="flex flex-col md:flex-row gap-2 md:gap-2 w-full mt-4 md:mt-8">
                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                        <div className="w-full md:w-1/4">
                            <ButtonInverted
                                disabled={agent.isOffline}
                                title="EMAIL ME"
                                onClick={() => (!agent.isOffline ? setIsEmailFormModalOpen(true) : handleNoClick)}
                            />
                        </div>
                    )}
                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                        <div className="w-full md:w-1/4">

                            <ButtonInverted
                                disabled={agent.isOffline}
                                title="CALL ME"
                                onClick={!agent.isOffline ? () => {
                                    callMe(agent);
                                    setIsPhoneFormModalOpen(true);
                                } : handleNoClick}
                            />
                        </div>
                    )}
                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
                        <div className="w-full md:w-1/4">
                            <ButtonInverted
                                disabled={agent.isOffline}
                                title="VIDEO APPT"
                                onClick={() => (!agent.isOffline ? setIsAppointmentFormModalOpen(true) : handleNoClick)}
                            />
                        </div>
                    )}
                </div>
            )}
            {agent.isOffline && <div className="flex justify-center items-center w-full mt-6 text-xl text-text-grey-900 font-semibold cursor-pointer">{agent.isOfflineMsg ? agent.isOfflineMsg : 'I am currently not accepting customers inquiries'}</div>}
            {agent.isOffline && <div className="flex justify-center items-center w-full text-red-700 text-text-grey-900 font-semibold cursor-pointer text-xs" onClick={() => setIsEmergencyFromModalOpen(true)}>Have an Emergency? Contact Me Here</div>}
            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen || isAppointmentFormModalOpen || isEmergencyFromModalOpen}
                onRequestClose={() => closeFormModal()}
                style={modalCustomStyles}
                contentLabel="Form Modal"
                ariaHideApp={false}
            >
                <div>
                    <div className="flex justify-end cursor-pointer" onClick={() => closeFormModal()}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agent={agent} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agent={agent}
                            phone={agent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}

                    {isAppointmentFormModalOpen && (
                        <AppointmentForm
                            agent={agent}
                        />
                    )}
                    {isEmergencyFromModalOpen && <EmergencyEmailForm agent={agent} />}
                </div>
            </Modal>

        </>
    );
}

export default AgentLabel;
