/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-return-assign */
/* eslint-disable no-plusplus */
import React, {
    useEffect, useState,
} from 'react';
import parser from 'html-react-parser';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import API from '../services/API';
import Loading from './elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import sanitizeHtml from 'sanitize-html-react';
import { PROMO_IMAGE_URL } from '../utils';
import PropTypes from 'prop-types';

function PromoCategoryList({ promoType, promoTitle, introTxt }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const history = useHistory();
    let supplier;
    if (promoType === 'resort') { supplier = 'resort collection partners'; } else { supplier = promoType; }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const myIp = query.get('ip') || null;

    const userLocationCookie = new Cookies();

    const getPromos = () => {
        setLoading(true);
        API.getPromotions({
            promotionType: 'Featured Supplier',
            rows: 99,
            displayChannel: userLocationCookie.get('userLocation').country_code === 'CA' ? 7 : 1,
            agencyId: 0,
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getPromosUserLocation = async () => {
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                setLoading(true);
                API.getPromotions({
                    promotionType: 'Featured Supplier',
                    rows: 99,
                    displayChannel: ipResult.data.country_code === 'CA' ? 7 : 1,
                    agencyId: 0,
                }).then((result) => {
                    if (result.responseStatus === 1) {
                        setData(result.data);
                    } else { toast(result.error.message); }
                    setLoading(false);
                });
            } else {
                toast(ipResult.error.message);
            }
        });
    };
    useEffect(() => {
        if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
            getPromosUserLocation();
        } else if ((userLocationCookie.get('userLocation')) && (myIp === null)) {
            getPromos();
        }
    }, []);

    /*used to determine total promos by promotype.
     this determines if the promotions area shows*/
    let i = 0;
    data.map((x) => (x.supplierCategory.toLowerCase() === supplier.toLowerCase()) ? i += 1 : (''));

    return (
        <>
            {data.length > 0
                && (i > 0)
                && (
                    <div className="mx-4 py-10 text-center" key="1">
                        <ToastContainer />
                        <h2 className="tl-cyan uppercase text-4xl tracking-wide">
                            {promoTitle}
                        </h2>
                        <p className="md:mx-4 mb-4 leading-tight text-base">
                            {introTxt}
                        </p>
                        <div className="content flex items-center py-4 space-x-2 text-sm justify-center">
                            <img
                                src="/images/icon-star-exclusive.png"
                                alt="Exclusive Offer"
                                className="h-6 w-6 mx-2"
                                loading="lazy"
                            />
                           Indicates special offer only available when booking with a Travel Leaders agent.
                        </div>
                        {!loading && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                {data.map((x) => (x.supplierCategory.toLowerCase() === supplier.toLowerCase()) ? (
                                        <div key={x.promotionId}>
                                            <div
                                                className="flex flex-col items-center cursor-pointer"
                                                onClick={() => history.push(`/promotions/${x.promotionId}?category=${promoType}`)}
                                            >
                                                <div className="relative">
                                                    <img
                                                        src={`${PROMO_IMAGE_URL()}/${x.fileName}`}
                                                        className="h-52 object-cover block rounded-lg hover:scale-105 transform ease-in-out duration-1000"
                                                    alt=""
                                                    loading="lazy"
                                                    />
                                                    {' '}
                                                    {x.exclusivePromo === true ? <img src="/images/icon-star-exclusive.png" alt="Exclusive Offer" className="w-6 mx-2  absolute top-2" loading="lazy" /> : ''}
                                                </div>
                                                <div className="w-full items-left cursor-pointer text-left">
                                                <div className="font-bold uppercase mt-2">{x.supplierList.length === 0 ? '' : x.supplierList.length === 1 ? x.supplierList[0].supplierName : 'Travel Leaders Supplier Partners'}</div>
                                                <h3 className="tl-cyan text-xl font-semibold max-w-md">{parser(sanitizeHtml(x.headline))}</h3>
                                                    <div className="text-base text-gray-500  max-w-md">
                                                    {x.teaserText.length > 200 ? parser(`${sanitizeHtml(x.teaserText.slice(0, 200))}...<div class="tl-red uppercase font-bold cursor-pointer hover:text-black">Read more &#x25B6;</div><p>`)
                                                        : parser(`${sanitizeHtml(x.teaserText)} <div class="tl-red uppercase font-bold cursor-pointer hover:text-black">Read more &#x25B6;</div><p>`)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (''))}
                                </div>
                            </>
                        )}

                    </div>
                )}
            {loading && <Loading />}
        </>
    );
}

PromoCategoryList.propTypes = {
    promoType: PropTypes.string,
    promoTitle: PropTypes.string,
    introTxt: PropTypes.string,
};

PromoCategoryList.defaultProps = {
    promoType: 'Featured Supplier',
    promoTitle: 'Specials',
    introTxt: '',
};
export default PromoCategoryList;
