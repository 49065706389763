/* eslint-disable react/forbid-prop-types */
import React, {
    useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import API from '../services/API';
import Loading from './elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { AD_IMAGE_URL } from '../utils';

function AdsList() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const today = new Date().toLocaleDateString();
    const getAds = () => {
        setLoading(true);
        API.getAds().then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };
    useEffect(() => {
        getAds();
    }, []);
    return (
        <>
            {data.length > 0
                && (
                <div className="">
                    <ToastContainer />
                    {!loading && (
                        <>
                            <div className="grid overflow-hidden grid-cols-1 md:grid-cols-6 md:grid-rows-2 gap-4">
                                {data.slice(0, 5).map((x, index) => (
                                    <div
                                        key={index}
                                        className={`box ${index === 0 ? 'md:row-span-2 md:col-span-2'
                                            : index === 1 ? 'md:col-span-2'
                                                : index === 2 ? 'md:row-start-2 md:col-start-3'
                                                    : index === 3 ? 'md:row-start-2 md:col-start-4'
                                                        : index === 4 ? 'md:row-span-2 md:col-span-2'
                                                            : ''}`}
                                    >
                                        <div className="w-full h-full relative hover:opacity-50 transition-all duration-1000 text-white">
                                            {(x.promotionID > 0) && (moment(x.endDate).format('YYYYMMDD') > moment(today).format('YYYYMMDD')) ? (
                                                <Link to={`promotions/${x.promotionID}`}>
                                                    <img
                                                        src={`${AD_IMAGE_URL()}${x.adImg}`}
                                                        alt=""
                                                        className="masonry rounded h-full w-full object-cover"
                                                        loading="lazy"
                                                    />
                                                        <div className="absolute rounded-b -bottom-0 w-full bg-black bg-opacity-50 p-2 text-white">{x.promotionText}</div>
                                                </Link>
                                            ) : (
                                                    <Link to={`/agents?slctDestination=${x.defaultCategory}&destinationId=${x.locationId}`}>
                                                        <img
                                                            src={`${AD_IMAGE_URL()}${x.defaultImg}`}
                                                            alt=""
                                                            className="masonry rounded h-full w-full object-cover"
                                                            loading="lazy"
                                                        />
                                                        <div className="absolute rounded-b -bottom-0 w-full bg-black bg-opacity-50 p-2 text-white">{x.defaultCategory}</div>
                                                    </Link>
                                                )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                )}
            {loading && <Loading />}
        </>
    );
}

export default AdsList;
