/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import React, {
    useEffect, useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../components/elements/Loading';
import ArticlesList from '../components/ArticlesList';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';

function BlogCategory() {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [category, setCategory] = useState(query.get('category') || '');
    const categoryArr = category.split('~');
    const [categoryType, categoryName] = categoryArr;
    const [title, setTitle] = useState();

    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (categoryName) {
            const text = categoryName.toLowerCase() === 'travel tips' ? 'Travel Tips and Ideas' : `${categoryName} Articles`;
            setTitle(text);
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Travel Blog',
                url: '/travelbetter',
            },
            {
                label: text,
            },
            ]);
        }
    }, [categoryName]);

    return (
        <div className=" max-w-screen-2xl mx-auto bg-white">
            <ToastContainer />
            <Helmet>
                <title>
                    Viewing all
                    {' '}
                    {categoryName}
                    {' '}
                    Articles | TravelLeaders.com
                </title>
                <meta name="title" content={`Viewing all ${categoryName} Articles | TravelLeaders.com`} />
                <meta
                    name="description"
                    content={`See news, tips, and ideas about ${categoryName} written by the experts who know it best.`}
                />
            </Helmet>

            <div className="min-h-screen pb-40 md:px-10 px-2">
                <div className="flex flex-col md:gap-10 gap-4">
                    <div className="ml-2 pt-12">
                        <BreadCrumb links={links} />
                    </div>
                    <div className="flex-col items-center justify-center text-center md:mb-10">
                        <h1 className="tl-cyan uppercase text-4xl tracking-wide">
                            {title}
                        </h1>
                        <div className="h-1.5 bg-blue-dark w-28 mb-2 mt-6 md:hidden" />

                    </div>
                    <div className="flex flex-col gap-4 items-start max-w-screen-5xl mx-4 md:mx-auto md:-ml-2">
                        {categoryName
                            && (
                                <ArticlesList
                                    ArticlesCategory={categoryName}
                                    ArticlesReturned="100"
                                    ArticlesDisplayTitle
                                    ArticleType={categoryType}
                                    DisplayArticleCount
                                />
                            )}
                        <div className="text-sm font-semibold text-red-700 cursor-pointer  flex justify-end gap-1 uppercase w-full" onClick={() => history.push('/travelbetter')}>
                            <div>
                                See all articles
                            </div>
                            <div>{'>'}</div>
                        </div>
                    </div>
                </div>

            </div>

            {loading && <Loading />}
        </div>
    );
}

export default BlogCategory;
