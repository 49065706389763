import React from 'react';
import PropTypes from 'prop-types';

function Input({ onChange, value, placeholder }) {
    return (
        <input placeholder={placeholder} className="border border-gray-400 rounded-full py-2 px-4 w-full text-xs" value={value} onChange={(e) => onChange(e.target.value)} />
    );
}

Input.defaultProps = {

    placeholder: '',
    value: '',
};
Input.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
};
export default Input;
