/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable no-confusing-arrow */
/* eslint-disable prefer-template */
import React, {
    useEffect, useState,
} from 'react';
import parser from 'html-react-parser';
import { useHistory, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import sanitizeHtml from 'sanitize-html-react';
import Loader from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import ButtonInverted from '../components/elements/ButtonInverted';
import UnifiedSearch from '../components/UnifiedSearch';
import ArticlesSlider from '../components/ArticlesSlider';
import WhyComponent from '../components/WhyComponent';
import { BLOG_IMAGE_URL } from '../utils';
import urlSlug from 'url-slug';

function Blogs() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const links = [{
        label: 'Home',
        url: '/',
    },
    {
        label: 'TravelBetter',
    },
    ];

    const getCategories = () => {
        setLoading(true);
        API.getCategories().then((result) => {
            if (result.responseStatus === 1) {
                setCategories(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getCategories();
        window.scrollTo(0, 0);
    }, []);

    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title>Travel News and Information from Travel Leaders</title>
                <meta name="title" content="Travel News and Information from Travel Leaders" />
                <meta
                    name="description"
                    content="Get travel information from our experts' first-hand experience and knowledge. See tips and news about your next trip, be it leisurely vacation or business."
                />
            </Helmet>
            <Hero title="Travel Better Blog" imageFile="hero-blog.webp" />
            <div className=" max-w-screen-2xl mx-auto">
                <div className="min-h-screen">
                    <div className="-mt-44 relative left-0 md:mx-20 right-0 z-10">
                        <div className="bg-white md:shadow-xl rounded px-8 py-6 z-40 text-center">
                            <div className="mb-6 text-lg">
                                Welcome to the Travel Leaders&apos; Travel Better Blog. Here you will find articles that showcase our first-hand knowledge while providing useful travel tips and inspiration for your globetrotting dreams. Indulge your inner wanderlust as you start your travel planning.
                            </div>
                            <div className="flex flex-col md:flex-row gap-2 sm:gap-4 justify-between">
                                {categories.map((x, index) => (x.categoryName.toLowerCase() !== 'featured') ? (
                                    <div className="flex flex-col justify-center flex-1" key={index}>
                                        <ButtonInverted
                                            title={x.categoryName}
                                            onClick={() => goToAnchor(x.categoryName)}
                                        />
                                    </div>
                                ) : (''))}
                            </div>
                            <div className="my-4">
                                <UnifiedSearch />
                            </div>
                        </div>
                    </div>
                    <div className="md:-mt-56 md:pt-72 flex w-full bg-footer-slate py-10">
                        <ArticlesSlider slideData="featured" />
                    </div>
                    <div className="flex flex-col gap-10">
                        <div className="flex flex-col gap-4 items-start justify-center  max-w-screen-5xl mx-auto">
                            {categories.map((x) => (x.categoryName.toLowerCase() !== 'featured') ? (
                                <div key={x.categoryName}>
                                    <CategoryBlogs name={x.categoryName} />
                                </div>
                            ) : (''))}
                        </div>
                    </div>
                </div>
                <div className="mt-10 md:mt-20 bg-light-blue">
                    <div className="md:relative md:-top-8">
                        <WhyComponent
                            displayStats="hidden"
                            barColor="bg-dark-blue-bar"
                            inactiveColor="text-white"
                        />
                    </div>
                </div>
            </div>
            {loading && <Loading />}
        </>
    );
}

export default Blogs;

export function CategoryBlogs({ name }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const history = useHistory();

    const getBlogs = () => {
        setLoading(true);
        API.getBlogs({
            rows: 5,
            name,
            type: 'c',
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };
    useEffect(() => {
        getBlogs();
    }, []);

    function renderSwitch(param) {
        switch (param) {
            case 'travel tips':
                return 'travel tips';
            case 'destinations':
                return 'Destination Stories';
            case 'experiences':
                return 'Experience Stories';
            default:
                return param + ' Stories';
        }
    }

    return (
        <>
            {data.length > 0
                && (
                    <div className="">
                        <ToastContainer />
                        <div className="flex flex-col justify-between w-full mt-16">
                        <h3 className="text-2xl md:text-3xl font-semibold tl-cyan uppercase my-2 m-auto" id={name}>{name}</h3>
                            {data.length > 4 && !loading && (
                            <div className="text-sm font-semibold tl-red cursor-pointer flex justify-center md:justify-end gap-1 uppercase md:-mt-8 mb-4 md:mb-8 md:pr-20" onClick={() => history.push(`/travelbetter_category?category=C~${name}`)}>
                                See more
                                {' '}
                                {renderSwitch(name.toLowerCase())}
                                {'>'}
                            </div>
                            )}
                        </div>
                        {!loading && (
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mx-4 md:mx-20">
                            {data.slice(0, 4).map((x) => (
                                <div
                                    className="flex flex-col"
                                    key={x.storyId}
                                >
                                    <Link to={`/travelbetter/${x.storyId}/${urlSlug(x.title)}?category=C~${name}`}>
                                        <img
                                            src={`${BLOG_IMAGE_URL()}${x.thumbnailImage}`}
                                            className="h-auto block rounded-lg hover:scale-105 transform ease-in-out duration-1000"
                                            alt=""
                                            loading="lazy"
                                        />
                                        {' '}
                                            <div className="text-base tl-black-900  max-w-md pt-4 hidden md:block">
                                                {x.shortDescription.length > 100 ? parser(`${sanitizeHtml(x.shortDescription.slice(0, 100))}...<span class="mx-2 tl-red cursor-pointer hover:text-black">Read more</span><p>`)
                                                    : parser(`${sanitizeHtml(x.shortDescription)}...<span class="mx-2 tl-red cursor-pointer hover:text-black">Read more</span><p>`)}
                                            </div>
                                    </Link>
                                    <div />
                                </div>
                            ))}
                        </div>
                        )}
                        {loading && (
                            <div className="my-20 flex justify-center items-center w-full">
                                <Loader
                                    type="ThreeDots"
                                    color="#b91c1c"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        )}
                    </div>
                )}
        </>
    );
}
