/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import urlSlug from 'url-slug';
import { BLOG_IMAGE_URL } from '../utils';
import Button from './elements/Button';
import API from '../services/API';
import 'react-slideshow-image/dist/styles.css';

function ArticlesSlider({ slideData }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const history = useHistory();
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '150px',
    };

    const properties = {
        duration: 3000,
        autoplay: false,
        arrows: false,
        indicators: (index) => (<i className="fa fa-circle text-white px-1 cursor-pointer hover:text-red-700 indicator" aria-hidden="true" />),
        slidesToShow: 1,
        slidesToScroll: 1,
        transitionDuration: 500,
    };

    const getBlogs = () => {
        setLoading(true);
        API.getBlogs({
            rows: 10,
            name: slideData,
            type: 'c',
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <>
            {data.length > 0
                && (
                    <div className="bg-footer-slate w-full md:px-20  text-center">
                    <h3 className="text-2xl md:text-3xl font-semibold text-white uppercase mb-6 m-auto">Featured</h3>
                        {!loading && (
                            <Slide {...properties}>
                                {data.map((x, index) => (
                                    <>
                                        <div
                                            className="each-slide relative z-0 cursor-pointer"
                                            key={index}
                                            style={style}
                                            onClick={() => history.push(`/travelbetter/${x.storyId}/${urlSlug(x.title)}`)}
                                        >
                                            <img style={{ minHeight: '300px', objectFit: 'cover' }} src={`${BLOG_IMAGE_URL()}/${x.heroImage}`} alt={`${x.title}`} loading="lazy" />
                                            <div className="absolute text-white font-bold uppercase text-2xl md:text-3xl z-10">
                                                <div className="mb-4" style={{ textShadow: '1px 1px 5px #000000' }}>{x.title}</div>
                                                <Button
                                                    title="LEARN MORE"
                                                    onClick={() => history.push(`/travelbetter/${x.storyId}/${urlSlug(x.title)}`)}
                                                    width="max-w-sm"
                                                />
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </Slide>
                        )}
                        {loading && (
                            <div className="my-20 flex justify-center items-center w-full">
                                <Loader
                                    type="ThreeDots"
                                    color="#b91c1c"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        )}
                    </div>
                )}
        </>
    );
}

ArticlesSlider.propTypes = {
    slideData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ArticlesSlider;
