/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
// const API = 'https://localhost:44342';
// const API = 'https://qa.travelleaders.com';
// const API = 'http://dev.travelleaders.com';
const API = '';

const fetchInterests = () => fetch(`${API}/lookup/getInterests`, { method: 'GET', mode: 'cors' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchStates = () => fetch(`${API}/lookup/getStates`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchDestinations = () => fetch(`${API}/lookup/getDestinations`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchSuppliers = ({ travelType }) => fetch(`${API}/lookup/getSuppliers?travelType=${travelType}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchRelatedDestByInterest = ({ interest }) => fetch(`${API}/lookup/GetRelatedDestinationsByInterest?interest=${interest}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchRelatedDestByState = ({ state }) => fetch(`${API}/lookup/GetRelatedDestinationsByState?state=${state}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchRelatedIntByState = ({ state }) => fetch(`${API}/lookup/GetRelatedInterestsByState?state=${state}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchRelatedMetrosByState = ({ state }) => fetch(`${API}/lookup/GetRelatedMetrosByState?state=${state}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchAgentCount = () => fetch(`${API}/lookup/getAgentCount`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchFaceted = ({
    destination, city, interest, state, language, metroRegion, zip,
    supplier,
    type,
}) => fetch(`${API}/lookup/${type}`,
    {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            destination,
            city,
            interest,
            state,
            language,
            metroRegion,
            zip,
            supplier,
        }),
    })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchAgents = ({
    zip, name, interest, destination, state,
    metroRegion,
    language,
    supplier,
    city,
    agentId,
    agencyId,
    locality,
    pageSize = 20,
    currentPage = 0,
}) => fetch(`${API}/agent/getAgents?ZIP=${zip}
        &Name=${name}
        &AgentInterest=${encodeURIComponent(interest)}
        &AgentDestination=${encodeURIComponent(destination)}
        &AgentState=${encodeURIComponent(state)}
        &AgentMetroRegion=${encodeURIComponent(metroRegion)}
        &AgentLanguage=${encodeURIComponent(language)}
        &AgentCity=${encodeURIComponent(city)}
        &AgentSupplier=${encodeURIComponent(supplier)}
        &AgentId=${encodeURIComponent(agentId)}
        &AgencyId=${encodeURIComponent(agencyId)}
        &Locality=${encodeURIComponent(locality)}
        &CurrentPage=${currentPage}
        &PageSize=${pageSize}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendEmailToAgent = (data) => fetch(`${API}/customer/sendEmail`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendEmailToAgentAudit = (data) => fetch(`${API}/customer/SendEmailAudit`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendPhoneToAgent = (data) => fetch(`${API}/customer/sendPhone`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendEmailPromoToAgent = (data) => fetch(`${API}/customer/sendEmailPromo`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const agentBio = ({ agentId, agentPreview }) => fetch(`${API}/agent/getAgentFullBio?agentId=${agentId}&preview=${agentPreview}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const agentAlbum = ({ agentId, contentType, contentId }) => fetch(contentType
    ? `${API}/agent/GetAgentPhotoAlbum?agentId=${agentId}&contentType=${contentType}&contentId=${contentId}`
    : `${API}/agent/GetAgentPhotoAlbum?agentId=${agentId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));
const agentVideos = ({ agentId, contentType, contentId }) => fetch(
    contentType ? `${API}/agent/GetAgentVideoAlbum?agentId=${agentId}&contentType=${contentType}&contentId=${contentId}`
        : `${API}/agent/GetAgentVideoAlbum?agentId=${agentId}`, { method: 'GET' },
)
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getVimeoVideoMetaData = ({ videoId }) => fetch(`https://vimeo.com/api/v2/${videoId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendReview = (data) => fetch(`${API}/customer/AddHelpful`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendEmailOptOut = (data) => fetch(`${API}/customer/AddEmailOptOut`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const additionalBio = ({ agentId, bioId }) => fetch(`${API}/agent/getAdditionalBio?agentId=${agentId}&bioId=${bioId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const experimentalPost = ({
    agentId, bioId, blogId, returnAll,
}) => fetch(`${API}/agent/getExpermintalPosts?agentId=${agentId}&bioId=${bioId}&blogId=${blogId}&returnAll=${returnAll}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },

    }));

const agentItineraries = ({
    agentId, bioId, returnAll, itineraryId = 0, preview = false,
}) => fetch(`${API}/agent/getAgentItineraries?agentId=${agentId}&bioId=${bioId}&returnAll=${returnAll}&itineraryId=${itineraryId}&preview=${preview}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },

    }));

const getBlogs = ({
    name, rows, order, type,
}) => fetch(`${API}/blog/stories?DisplayChannel=1&rows=${rows}&order=${order}&name=${name}&type=${type}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getBlog = ({ storyId, storyTitle, preview }) => fetch(`${API}/blog/story?storyId=${storyId}&storyTitle=${storyTitle}&preview=${preview}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getBlogAuthors = ({
    authorId, authorName,
}) => fetch(`${API}/blog/authors?authorId=${authorId}&authorName=${authorName}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));
const agentMatchToInterest = ({
    id, destination, interest, supplier, returnAgents, agencyId, latitude, longitude, locality, promotionId,
}) => fetch(`${API}/agent/agentMatchToInterest?agentId=${id}&agencyId=${agencyId}&agentDestination=${destination}&agentInterest=${interest}&agentSupplier=${supplier}&returnNumOfAgents=${returnAgents}&latitude=${latitude}&longitude=${longitude}&locality=${locality}&promotionId=${promotionId}`, {
    method: 'GET',
})
        .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getPopularDestinations = () => fetch(`${API}/lookup/getPopularDestinations`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getCategories = () => fetch(`${API}/blog/categories`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getBlogCategories = () => fetch(`${API}/blog/blogCategories`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getBlogInterests = () => fetch(`${API}/blog/blogInterests`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getBlogDestinations = () => fetch(`${API}/blog/blogDestinations`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getBlogSuppliers = () => fetch(`${API}/blog/blogSuppliers`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getPromotions = ({
    promotionType, rows, displayChannel, agencyId, region,
}) => fetch(`${API}/promotion/promotions?PromotionType=${promotionType}&RowCount=${rows}&PromotionChannel=${displayChannel}&Agencyid=${agencyId}&Region=${region}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getPromotionDetail = ({ promotionId, preview }) => fetch(`${API}promotion/promotionDetail?PromotionId=${promotionId}&preview=${preview}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getAds = () => fetch(`${API}/promotion/homePageAds`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getPromoCategoryCounts = () => fetch(`${API}/promotion/promoCounts`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getIpLocation = ({ myIp }) => fetch(`${API}/customer/GetIPLocation?myIp=${myIp}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendEmailUtility = (data) => fetch(`${API}/utility/sendEmail`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getAdverts = ({
    id, destination, interest, supplier,
}) => fetch(`${API}/agent/getAdvertisements?Destination=${destination}&Interest=${interest}&Supplier=${supplier}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));


const sendAdvertClick = (data) => fetch(`${API}/agent/saveAdvertismentClick`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));


export default {
    fetchInterests,
    fetchStates,
    fetchDestinations,
    fetchSuppliers,
    fetchRelatedDestByInterest,
    fetchRelatedDestByState,
    fetchRelatedIntByState,
    fetchRelatedMetrosByState,
    fetchFaceted,
    fetchAgents,
    fetchAgentCount,
    sendEmailToAgent,
    sendEmailToAgentAudit,
    sendEmailPromoToAgent,
    sendPhoneToAgent,
    sendEmailUtility,
    agentBio,
    agentAlbum,
    agentVideos,
    getVimeoVideoMetaData,
    sendReview,
    sendEmailOptOut,
    additionalBio,
    experimentalPost,
    agentItineraries,
    getBlogs,
    getBlog,
    getBlogAuthors,
    agentMatchToInterest,
    getPopularDestinations,
    getCategories,
    getPromotions,
    getPromotionDetail,
    getAds,
    getPromoCategoryCounts,
    getIpLocation,
    getBlogCategories,
    getBlogInterests,
    getBlogDestinations,
    getBlogSuppliers,
    getAdverts,
    sendAdvertClick,
};
