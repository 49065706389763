/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function ArrowDown({ customClass = 'md:bg-gray-200 bg-green-light', large = false }) {
    return (
        <div className={['rounded-full h-5 w-5 flex justify-center items-center mr-2', customClass].join(' ')}>
            {!large && (
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8L9.33013 0.5H0.669873L5 8Z" fill="white" />
                </svg>
            )}
            {large && (
                <svg width="16" height="14" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8L9.33013 0.5H0.669873L5 8Z" fill="white" />
                </svg>
            )}
        </div>
    );
}

export default ArrowDown;
