/* eslint-disable react/no-unescaped-entities */
/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import parser from 'html-react-parser';
import Modal from 'react-modal';
import { IoClose, IoStarOutline } from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import { useHistory, Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html-react';
import {
    callMe, CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE, CONTACT_METHOD_VIDEO, CONTACT_METHOD_FB, CONTACT_METHOD_TW, CONTACT_METHOD_LI, CONTACT_METHOD_IG, CONTACT_METHOD_YT, modalCustomStyles,
} from '../utils';
import useAppStore from '../contexts/useAppStore';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import AppointmentForm from './AppointmentForm';
import ButtonInverted from './elements/ButtonInverted';
import AgentAffiliation from './elements/AgentAffiliation';
import SocialIcon from './elements/SocialIcon';

function AgentLabelBlog({ agent, blogAttribute, blog }) {
  const {
    firstName, lastName, city, state, agentPhotoFileName, addlBioId,
  } = agent;

    const history = useHistory();

    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, setIsAppointmentFormModalOpen, isEmailFormModalOpen, isPhoneFormModalOpen, isAppointmentFormModalOpen, closeFormModal,
    } = useAppStore();

  return (
      <>
          <div className="w-full p-6 md:my-4 bg-light-blue relative z-20">
              <h2 className="text-3xl tl-cyan text-center block pb-6 w-full uppercase px-4 md:px-20">We are here to help you turn your travel dreams into reality. Contact us today!</h2>
              <div className="bg-white shadow-xl p-4 md:mx-20">
                  <div className="flex flex-col md:flex-row gap-5">
                      <div className="w-full md:w-1/4 self-center">
                          <Link to={`/agent/${agent.agentId}`}>
                            <img className="min-w-full rounded" src={agentPhotoFileName} alt={firstName} loading="lazy" />
                          </Link>
                      </div>
                      <div className="w-full md:w-2/4 self-top text-center md:text-left">
                          <div className="">
                              {agent.preferredAgent === true ? (
                                  <div className="mb-2 md:mb-4"><img className="mx-auto md:mx-0" src="/images/icon-super-agent.svg" height="23" width="100" alt="Super Agent" loading="lazy" /></div>
                              ) : ('')}
                          </div>
                          <h2 className="text-3xl tl-cyan uppercase mb-5">
                              {`${firstName} ${lastName}`}
                          </h2>
                          <div className="text-lg font-bold mb-2 md:mb-5">
                              <h2>{`${city}, ${state} Travel Agent`}</h2>
                          </div>
                          <div className="font-bold mb-2 md:mb-5">
                              <AgentAffiliation
                                  icAgent={agent.icAgent.toString() === 'true' ? '1' : '0'}
                                  agencyLevel={agent.agencyLevel}
                                  agencyRecordType={agent.agencyRecordType}
                                  icBusinnessName={agent.icBusinessName}
                                  agencyName={agent.hostAgency}
                                  hostAgency={agent.hostAgency}
                                  securityLevel={agent.securityLevel}
                                  hostCity={agent.hostAgencyCity}
                                  hostState={agent.hostAgencyState}
                              />
                          </div>
                          <div className="flex flex-col md:flex-row gap-2">
                              {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                  <div className="w-full md:w-1/3">
                                      <ButtonInverted
                                          title="EMAIL ME"
                                          onClick={() => setIsEmailFormModalOpen(true)}
                                      />
                                  </div>
                              )}
                              {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                  <div className="w-full md:w-1/3">
                                      <ButtonInverted
                                          title="CALL ME"
                                          onClick={
                                              () => {
                                                  callMe(agent);
                                                  setIsPhoneFormModalOpen(true);
                                              }
                                          }
                                      />
                                  </div>
                              )}
                          </div>
                      </div>
                      <div className="w0full md:w-1/4 text-center text-xl self-center">
                          {agent && agent.totalReviews > 0
                              && (
                              <div className="flex flex-col gap-1">
                                  <Link to={`/agent/${agent.agentId}?s=review`} className="w-full">
                                      <StarRatings
                                          rating={agent.rating}
                                          starRatedColor="#0A7790"
                                          numberOfStars={5}
                                          name="rating"
                                          starDimension="40px"
                                          starSpacing="2px"
                                      />
                                      <div className="cursor-pointer my-2">
                                          {agent.totalReviews}
                                          {' '}
                                          {agent.totalReviews > 1 ? 'reviews' : 'review'}
                                      </div>
                                      <div className="font-bold">
                                          {((agent.recommended / agent.totalReviews) * 100).toFixed(2)}
                                        % Recommended
                                      </div>
                                      <div className="">
                                          {`${agent.yearsActive} Years of Experience`}
                                      </div>
                                  </Link>
                                  <div className="flex gap-2 self-center">
                                      <div>
                                          {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_FB) && (
                                              <>
                                                  <SocialIcon
                                                      iconType="fa-facebook-square"
                                                      iconSize="fa-lg"
                                                      iconColor="text-red-500"
                                                      iconURL={agent.agentConnections.find((x) => x.contactMethod === CONTACT_METHOD_FB).contactValue}
                                                  />
                                              </>
                                          )}
                                      </div>
                                      <div>
                                          {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_TW) && (
                                              <>
                                                  <SocialIcon
                                                      iconType="fa-twitter-square"
                                                      iconSize="fa-lg"
                                                      iconColor="text-red-500"
                                                      iconURL={agent.agentConnections.find((x) => x.contactMethod === CONTACT_METHOD_TW).contactValue}
                                                  />
                                              </>
                                          )}
                                      </div>
                                      <div>
                                          {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_LI) && (
                                              <>
                                                  <SocialIcon
                                                      iconType="fa-linkedin"
                                                      iconSize="fa-lg"
                                                      iconColor="text-red-500"
                                                      iconURL={agent.agentConnections.find((x) => x.contactMethod === CONTACT_METHOD_LI).contactValue}
                                                  />
                                              </>
                                          )}
                                      </div>
                                      <div>
                                          {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_IG) && (
                                              <>
                                                  <SocialIcon
                                                      iconType="fa-instagram"
                                                      iconSize="fa-lg"
                                                      iconColor="text-red-500"
                                                      iconURL={agent.agentConnections.find((x) => x.contactMethod === CONTACT_METHOD_IG).contactValue}
                                                  />
                                              </>
                                          )}
                                      </div>
                                      <div>
                                          {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_YT) && (
                                              <>
                                                  <SocialIcon
                                                      iconType="fa-youtube"
                                                      iconSize="fa-lg"
                                                      iconColor="text-red-500"
                                                      iconURL={agent.agentConnections.find((x) => x.contactMethod === CONTACT_METHOD_YT).contactValue}
                                                  />
                                              </>
                                          )}
                                      </div>
                                  </div>
                              </div>
                              )}
                      </div>
                  </div>
              </div>
          </div>
          <Modal
              isOpen={isEmailFormModalOpen || isPhoneFormModalOpen || isAppointmentFormModalOpen}
              onRequestClose={() => closeFormModal()}
              style={modalCustomStyles}
              contentLabel="Form Modal"
              ariaHideApp={false}
          >
              <div>
                  <div className="flex justify-end cursor-pointer" onClick={() => closeFormModal()}>
                      <IoClose className="text-red-500 text-right" />
                  </div>
                  {isEmailFormModalOpen && <EmailForm agent={agent} />}
                  {isPhoneFormModalOpen && (
                      <PhoneForm
                          agent={agent}
                          phone={agent.phone}
                          callBack={() => {
                              setIsPhoneFormModalOpen(false);
                              setIsEmailFormModalOpen(true);
                          }}
                      />
                  )}
                  {isAppointmentFormModalOpen && (
                      <AppointmentForm
                          agent={agent}
                      />
                  )}
              </div>
          </Modal>
      </>
    );
}

export default AgentLabelBlog;
