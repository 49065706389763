/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/API';

function RelatedDestByInt({
    interest,
}) {
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState([]);

    const fetch = (agent) => {
        setIsFetching(true);
        API.fetchRelatedDestByInterest({
            interest: interest || '',
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setIsFetching(false);
        });
    };

    useEffect(() => {
        fetch();
    }, [interest]);


    return (
        <>
            {data.length > 0 && (
                <div className="py-2">
                    <ToastContainer />
                    <div className="text-xl font-bold">{`Related Destinations for ${interest}`}</div>
                    <div className="flex flex-col">
                        <div className="flex flex-col items-start text-base">
                                    {data.map((x) => (
                                        <div
                                            key={x.destination}
                                            className="cursor-pointer font-normal"
                                        >
                                            <Link to={`/agents?slctInterest=${encodeURIComponent(interest)}&slctDestination=${encodeURIComponent(x.destination)}&destinationId=${x.destinationId}`}>
                                                {`${interest} Travel Agents for ${x.destination}`}
                                            </Link>
                                        </div>
                                    ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default RelatedDestByInt;
