/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
    useParams,
    useHistory,
    Link,
    useLocation,
} from 'react-router-dom';
import {
    IoGlobeOutline, IoCalendarOutline, IoLocation, IoCheckmark, IoExpand, IoChevronBack, IoAddCircle, IoCloseCircleOutline, IoClose, IoScanOutline,
} from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import SlideDown from 'react-slidedown';
import Modal from 'react-modal';
import ReactImageGallery from 'react-image-gallery';
import { Helmet } from 'react-helmet';
import API from '../services/API';
import AgentLabel from '../components/AgentLabel';
import BreadCrumb from '../components/BreadCrumb';
import {
    getStateName, modalCustomStyles,
} from '../utils';
import Loading from '../components/elements/Loading';

function Itinerary() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const { id, itineraryId, bioId } = useParams();
    const [agent, setAgent] = useState();
    const [bio, setBio] = useState();
    const [previewMode, setPreviewMode] = useState(query.get('preview') || false);
    const [loading, setLoading] = useState(false);
    const [itineraryFound, setItineraryFound] = useState(true);
    const [itinerary, setItinerary] = useState();
    const [expanded, setExpanded] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [images, setImages] = useState([]);
    const history = useHistory();
    const [links, setLinks] = useState([{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a travel Agent',
        url: '/find_travel_agent',
    },
    ]);

    const [openedList, setOpenedList] = useState([]);

    const onItemClicked = (item) => {
        if (openedList.includes(item)) {
            setOpenedList([...openedList.filter((x) => x !== item)]);
        } else {
            openedList.push(item);
            setOpenedList([...openedList]);
        }
    };

    const getItinerary = () => {
        setLoading(true);
        API.agentItineraries({
            agentId: id,
            bioId: 0,
            itineraryId,
            returnAll: 'false',
            preview: previewMode,
        }).then((result) => {
            if (result.responseStatus === 1) {
                setItinerary(result.data[0]);
                if (result.data[0] === undefined) {
                    setItineraryFound(false);
                }
            }
            setLoading(false);
        });
    };

    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,
            agentPreview: previewMode,

        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    getItinerary();
                }
            }
            setLoading(false);
        });
    };
    const getAdditionalBio = () => {
        setLoading(true);
        API.additionalBio({
            agentId: id,
            bioId,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setBio(result.data);
            }
            setLoading(false);
        });
    };

    const metaDescription = () => `See a travel itinerary curated by ${`${agent.firstName} ${agent.lastName}`}, who specializes in ${itinerary.visitedCountries} vacations. Discover what ${agent.firstName} can do for you`;

    useEffect(() => {
        if (expanded && itinerary) {
            getAdditionalBio();
            setOpenedList(itinerary.itineraryDays.map((x) => x.day));
        } else { setOpenedList([]); }
    }, [expanded, itinerary]);

    const agentBio = () => (
        <div className="flex flex-col gap-4  ">
            <BreadCrumb links={links} />
            <div className="text-gray-500 text-sm font-semibold flex flex-row items-center cursor-pointer gap-1" onClick={() => history.push(`/agent/${id}`)}>
                <IoChevronBack className=" text-right" />
                <div>
                    {' '}
                  Return to My Main profile
                </div>
            </div>
            <div className="flex flex-col gap-10 md:gap-20 md:flex-row justify-between items-center">

                <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-center flex-1 w-full px-10 md:px-0">
                    <div>
                        <img
                            alt=""
                            src={agent.agentPhotoFileName}
                            className="w-80 md:w-64 mx-auto rounded-lg"
                            loading="lazy"
                        />

                    </div>
                    <div className="flex flex-col items-center md:items-start  w-full">
                        <AgentLabel
                            agent={agent}
                        />

                    </div>
                </div>
                <div className="text-xl text-center">
                    {agent.agentRating && agent.agentRating.totalReviews > 0
                        && (
                            <div
                                className="flex flex-col gap-1 cursor-pointer"
                                onClick={() => executeScroll(reviewsRef)}
                            >
                                <div>

                                    <StarRatings
                                        rating={agent.agentRating.rating}
                                        starRatedColor="#0A7790"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="40px"
                                        starSpacing="2px"
                                    />

                                </div>
                                <div
                                    className="cursor-pointer"

                                >
                                    {agent.agentRating.totalReviews}
                                    {' '}
                                    {agent.agentRating.totalReviews > 1 ? 'reviews' : 'review'}
                                </div>
                                <div className="font-semibold">
                                    {' '}
                                    {((agent.agentRating.recommended / agent.agentRating.totalReviews) * 100).toFixed(2)}
                                    % Recommended
                                </div>
                            </div>
                        )}

                    {agent.yearsActive && (
                        <div>
                            {agent.yearsActive}
                            {' '}
                            Years of Experience
                        </div>
                    )}
                    <div className="text-red-600 text-4xl mt-4 flex flex-row gap-2 justify-center">

                        {agent.agentConnections.map((x) => (
                            <div key={x.contactMethod}>
                                {x.contactMethod === 'Facebook'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">
                                            <img
                                                alt="facebook"
                                                src="https://www.travelleaders.com/images/ico_fb_black.png"
                                                loading="lazy"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'Instagram'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">
                                            <img
                                                alt="instagram"
                                                src="https://www.travelleaders.com/images/ico_ig_black.png"
                                                loading="lazy"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'Twitter'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="twitter"
                                                src="https://www.travelleaders.com/images/ico_tw_black.png"
                                                loading="lazy"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'YouTube'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="youtube"
                                                src="https://www.travelleaders.com/images/ico_yt_black.png"
                                                loading="lazy"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'Pinterest'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="youtube"
                                                src="https://www.travelleaders.com/images/ico_pt_black.png"
                                                loading="lazy"
                                            />
                                        </a>
                                    )}

                                {x.contactMethod === 'LinkedIn'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="linkedIn"
                                                src="https://www.travelleaders.com/images/ico_Li_black.png"
                                                loading="lazy"
                                            />
                                        </a>
                                    )}

                                {x.contactMethod === 'My Blog'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="youtube"
                                                src="https://www.travelleaders.com/images/ico_bl_black.png"
                                                loading="lazy"
                                            />
                                        </a>
                                    )}
                            </div>
                        ))}

                    </div>
                </div>

            </div>
        </div>
    );

    const header = () => (
        <div className="flex flex-col">
            <div className="md:p-10 mt-10 flex flex-col bg-footer-slate gap-4">
                <div className="text-xl md:text-3xl text-white text-center px-2 pb-2 md:px-0 uppercase">{itinerary.title}</div>
                <div className={'relative' /* onClick={() => setIsModalOpen(true)}*/}>
                    <img
                        className="w-full object-cover "
                        src={itinerary.itineraryImage}
                        alt=""
                        loading="lazy"
                    />

                </div>
                {/*<div className="flex justify-center text-white text-2xl md:text-4xl cursor-pointer" onClick={() => setIsModalOpen(true)}>*/}
                {/*    <IoScanOutline />*/}
                {/*</div>*/}

            </div>
            <div className="flex flex-col md:flex-row justify-between md:px-20 pb-6 px-2 bg-light-blue -mx-2 md:-mx-10">
                <div className="tl-cyan font-bold text-base md:text-lg  px-2 mt-4 flex flex-row items-center gap-3">
                    <span className="bg-cyan rounded-full p-3">
                        <IoGlobeOutline className="text-white text-3xl" />
                    </span>
                    <div className="flex flex-col">
                        <span>COUNTRIES VISITED:</span>
                        <span className="text-black font-light text-sm md:text-base">
                            {itinerary.visitedCountries}
                        </span>
                    </div>
                </div>
                <div className="tl-cyan font-bold text-base md:text-lg  px-2 mt-4 flex flex-row items-center gap-3">
                    <span className="bg-cyan rounded-full p-3">
                        <IoLocation className="text-white text-3xl" />
                    </span>
                    <div className="flex flex-col">
                        <span>PLACES VISITED:</span>
                        <span className="text-black font-light text-sm md:text-base">
                            {itinerary.visitedCities}
                        </span>
                    </div>
                </div>
                <div className="tl-cyan font-bold text-base md:text-lg  px-2 mt-4 flex flex-row items-center gap-3">
                    <span className="bg-cyan rounded-full p-3">
                        <IoCalendarOutline className="text-white text-3xl" />
                    </span>
                    <div className="flex flex-col">
                        <span>SUGGESTED DURATION:</span>
                        <span className="text-black font-light text-sm md:text-base">
                            {`${itinerary.itineraryDays.length} Days`}
                        </span>
                    </div>

                </div>
            </div>
            <div className="p-4 md:p-10 text-black text-sm md:text-lg md:ml-10 text-justify md:text-left">
                {itinerary.overview}
            </div>
        </div>
    );

    const body = () => (
        <div className="flex flex-col  gap-10 md:px-10">
            <div className="self-end tl-red pr-2 md:pr-0 text-sm md:text-base uppercase">
                {!expanded
                    ? (
                        <div className="flex flex-row cursor-pointer items-center gap-2" onClick={() => setExpanded(true)}>
                            <div>Expand All Days</div>
                            <div className="font-bold text-2xl">
                                <IoAddCircle />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-row cursor-pointer items-center gap-2" onClick={() => setExpanded(false)}>
                            <div>Close All Days</div>
                            <div className="font-bold text-2xl">
                                <IoCloseCircleOutline />
                            </div>
                        </div>
                    )}
            </div>

            {itinerary.itineraryDays.map((x) => (
                <div className="flex flex-col gap-4  md:gap-10 " key={`day-${x.day}`}>
                    <div
                        className="flex flex-col gap-2 md:p-8 p-4 md:shadow-2xl rounded-t-3xl md:rounded-xl md:border md:border-gray-50 border border-gray-200"
                    >
                        <div className="flex flex-row  justify-between md:items-center">
                            <div className="flex flex-row gap-2 items-center">
                                <div className="bg-cyan rounded-full w-14 h-14 md:h-20 md:w-20 flex flex-col items-center justify-center text-white text-sm md:text-base font-semibold">
                                    <span>DAY</span>
                                    <span className="text-xl md:text-3xl">{x.day}</span>
                                </div>

                                {x.dayDestinations.map((y, index) => (
                                    <div
                                        className="flex flex-row gap-2 items-center font-bold text-xs md:text-2xl uppercase"
                                        key={y.locationId}
                                    >
                                        <div>{y.location.split('|')[0]}</div>
                                        {(index + 1 < x.dayDestinations.length) && <div>-</div>}
                                    </div>
                                ))}
                            </div>
                            <div
                                onClick={() => onItemClicked(x.day)}
                                className="md:flex flex-row gap-2 justifiy-center items-center cursor-pointer tl-red uppercase"
                            >
                                {!openedList.includes(x.day)
                                    && (
                                        <>
                                            <div className="hidden-tw md:flex">Expand Day</div>
                                            <div className="font-bold text-2xl">
                                                <IoAddCircle />
                                            </div>
                                        </>
                                    )}
                                {openedList.includes(x.day)
                                    && (
                                        <>
                                            <div className="hidden-tw md:flex">Close Day</div>
                                            <div className="font-bold text-2xl">
                                                <IoCloseCircleOutline />
                                            </div>
                                        </>
                                    )}
                            </div>

                        </div>

                    </div>
                    <SlideDown className="my-dropdown-slidedown">
                        {openedList.includes(x.day)
                            && (
                                <div className="flex flex-col md:flex-row gap-8 md:gap-16 mx-4 md:mx-10">
                                    <div className="flex-1  flex flex-col gap-4">
                                        <div className="tl-cyan text-sm md:text-xl font-semibold uppercase">
                                            {x.dayTitle}
                                        </div>
                                        <div className="text-xs md:text-sm text-gray-800">
                                            {x.dayDescription}
                                        </div>
                                        <div className="tl-cyan text-sm md:text-xl  font-semibold">
                                            HIGHLIGHTS:
                                        </div>
                                        {x.dayHighlights.map((y) => (
                                            <div className="flex flex-row gap-2 items-center" key={y.highlightId}>
                                                <div><IoCheckmark className="tl-cyan font-bold text-base md:text-xl" /></div>
                                                <div className="md:font-bold font-semibold text-xs md:text-base">{y.highlight1}</div>
                                            </div>
                                        ))}

                                    </div>
                                {x.dayImage.length > 0 ? (
                                    <div className="flex-1 mx-0 md:mx-10">
                                            <img
                                                className="w-full mx-auto rounded-lg h-72 object-cover "
                                                src={x.dayImage}
                                                alt=""
                                                loading="lazy"
                                            />
                                    </div>
                                ) : ''}
                                </div>
                            )}
                    </SlideDown>
                </div>
            ))}

        </div>
    );

    const finalThoughts = () => (
        <div className="p-4 md:px-20 md:py-10 bg-light-blue -mx-2 md:-mx-10 rounded-tl-3xl md:rounded-none text-black text-justify md:text-left text-base md:text-lg flex flex-col mt-10">
            <div className="tl-cyan text-2xl md:text-5xl font-light mb-2 md:mb-6">FINAL THOUGHTS</div>
            <div>
                {itinerary.finalThoughts}
            </div>
            <div>
                <Link to={`/agent/${agent.agentId}/itineraries`} className="block tl-red uppercase my-4 md:my-2 hover:text-red-900">
                    <i className="fa fa-caret-left" aria-hidden="true" />
                    Back to all Itineraries
                </Link>
            </div>
        </div>
    );

    const itineraryNotFound = () => (
        <div className="mx-4 md:mx-20 py-20 text-center">
            <h2 className="tl-cyan uppercase text-4xl tracking-wide">Sorry this Itinerary is not available</h2>
            <div>
                <h3 className="uppercase text-xl my-2 font-semibold tracking-wide">This itinerary does not exist, or has not been published yet</h3>
            </div>
        </div>
        );

    const collectImages = () => {
        setImages([itinerary.itineraryImage].concat(itinerary.itineraryDays.map((x) => x.dayImage)));
    };

    useEffect(() => {
        if (agent && itinerary) {
            const breadCrumbLinks = [{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?state=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
                url: `/agent/${agent.agentId}`,
            },
            ];

            if (bio) {
                breadCrumbLinks.push({
                    label: bio.displayTitle,
                    url: `/agent/${agent.agentId}/bio/${bioId}`,
                });
            }

            breadCrumbLinks.push({
                label: itinerary.title,
            });

            setLinks(breadCrumbLinks);

            collectImages();
        }
    }, [agent, itinerary, bio]);

    useEffect(() => {
        getAgentBio();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className=" max-w-screen-2xl mx-auto">
            {agent && (
                <div className="h-full flex flex-col pt-24 md:pt-12 px-2 md:px-8 text-gray-900">
                    {agentBio()}
                    {itinerary && (
                        <>
                            <Helmet>
                                <title>
                                    {`${itinerary.title} | Travel Leaders`}
                                </title>
                                <meta name="title" content={`${itinerary.title}  | Travelleaders.com`} />
                                <meta
                                    name="description"
                                    content={metaDescription()}
                                />
                            </Helmet>
                            {header()}
                            {body()}
                            {finalThoughts()}
                            <Modal
                                isOpen={isModalOpen}
                                onRequestClose={() => setIsModalOpen(false)}
                                style={modalCustomStyles}
                                contentLabel="Modal"
                                ariaHideApp={false}
                            >
                                <div className="max-w-6xl">
                                    <div className="flex justify-end cursor-pointer" onClick={() => setIsModalOpen(false)}>
                                        <IoClose className="text-blue-dark text-right" />
                                    </div>
                                    <div className=" px-4">

                                        <div>
                                            <div className="text-blue-dark text-xl font-medium text-center mb-6">
                                                ITINERARY GALLERY
                                            </div>
                                            <ReactImageGallery
                                                items={images.map((y) => ({
                                                    original: y,
                                                    thumbnail: y,
                                                    originalTitle: '',

                                                }))}
                                                additionalClass="max-w-xs md:max-w-2xl"
                                            />
                                        </div>

                                    </div>
                                </div>
                            </Modal>
                        </>
                    )}
                    {(itineraryFound === false && document.readyState === 'complete') && (
                        <>
                            {itineraryNotFound()}
                        </>
                    )}
                </div>
            )}
            {loading && <Loading />}
        </div>
    );
}

export default Itinerary;
