/* eslint-disable react/prop-types */
/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStateName } from '../../utils';

function RelatedDestIntByGeo({
    state,
    metro,
    interest,
    destination,
    destinationId,
}) {
    function getStateCode(stateName) {
        const stateCodes = {
            'Alabama': 'AL',
            'Alaska': 'AK',
            'Arizona': 'AZ',
            'Arkansas': 'AR',
            'California': 'CA',
            'Colorado': 'CO',
            'Connecticut': 'CT',
            'Delaware': 'DE',
            'Florida': 'FL',
            'Georgia': 'GA',
            'Hawaii': 'HI',
            'Idaho': 'ID',
            'Illinois': 'IL',
            'Indiana': 'IN',
            'Iowa': 'IA',
            'Kansas': 'KS',
            'Kentucky': 'KY',
            'Louisiana': 'LA',
            'Maine': 'ME',
            'Maryland': 'MD',
            'Massachusetts': 'MA',
            'Michigan': 'MI',
            'Minnesota': 'MN',
            'Mississippi': 'MS',
            'Missouri': 'MO',
            'Montana': 'MT',
            'Nebraska': 'NE',
            'Nevada': 'NV',
            'New Hampshire': 'NH',
            'New Jersey': 'NJ',
            'New Mexico': 'NM',
            'New York': 'NY',
            'North Carolina': 'NC',
            'North Dakota': 'ND',
            'Ohio': 'OH',
            'Oklahoma': 'OK',
            'Oregon': 'OR',
            'Pennsylvania': 'PA',
            'Rhode Island': 'RI',
            'South Carolina': 'SC',
            'South Dakota': 'SD',
            'Tennessee': 'TN',
            'Texas': 'TX',
            'Utah': 'UT',
            'Vermont': 'VT',
            'Virginia': 'VA',
            'Washington': 'WA',
            'West Virginia': 'WV',
            'Wisconsin': 'WI',
            'Wyoming': 'WY',
            'District of Columbia': 'DC',
            'Puerto Rico': 'PR',
            'Alberta': 'AB',
            'British Columbia': 'BC',
            'Manitoba': 'MB',
            'New Brunswick': 'NB',
            'Newfoundland and Labrador': 'NL',
            'Nova Scotia': 'NS',
            'Northwest Territories': 'NT',
            'Nunavut': 'NU',
            'Ontario': 'ON',
            'Prince Edward Island': 'PE',
            'Québec': 'QC',
            'Saskatchewan': 'SK',
            'Yukon': 'YT',
        };

        // Convert the input stateName to title case (e.g., 'new york' becomes 'New York')
        const formattedStateName = stateName.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        // Look up the state code in the dictionary
        const stateCode = stateCodes[formattedStateName];
        return stateCode || ''; // Return '' if the state name is not found
    }

    return (
        <>
            <div className="col-span-1 md: col-span-2 text-center text-xl font-bold">Related Searches</div>
            <div className="pb-2">
                <ToastContainer />
                <div className="flex flex-col">
                    <div className="flex flex-col items-start text-base">
                        <div className="cursor-pointer font-normal">
                            <Link to={`/agents?slctState=${getStateCode(state)}&slctInterest=${encodeURIComponent(interest)}&destinationId=${destinationId}&slctDestination=${encodeURIComponent(destination)}`}>
                                {`${destination} Travel Agents in ${getStateName(state)} for ${interest}`}
                            </Link>
                        </div>
                        <div className="cursor-pointer font-normal">
                            <Link to={`/agents?slctState=${getStateCode(state)}&destinationId=${destinationId}&slctDestination=${encodeURIComponent(destination)}`}>
                                {` ${destination} Travel Agents in ${getStateName(state)}`}
                            </Link>
                        </div>
                        <div className="cursor-pointer font-normal">
                            <Link to={`/agents?slctState=${getStateCode(state)}&slctInterest=${encodeURIComponent(interest)}`}>
                                {`${interest} Travel Agents in ${getStateName(state)}`}
                            </Link>
                        </div>
                        <div className="cursor-pointer font-normal">
                            <Link to={`/agents?slctInterest=${encodeURIComponent(interest)}`}>
                                {`${interest} Travel Agents`}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {metro.length > 0 ? (
                <div className="pb-2">
                    <div className="flex flex-col">
                        <div className="flex flex-col items-start text-base">
                            <div className="cursor-pointer font-normal">
                                <Link to={`/agents?slctState=${getStateCode(state)}&slctMetro=${encodeURIComponent(metro)}&slctInterest=${encodeURIComponent(interest)}&destinationId=${destinationId}&slctDestination=${encodeURIComponent(destination)}`}>
                                    {`${destination} Travel Agents in the ${metro} Metro for ${interest}`}
                                </Link>
                            </div>
                            <div className="cursor-pointer font-normal">
                                <Link to={`/agents?slctState=${getStateCode(state)}&slctMetro=${encodeURIComponent(metro)}&destinationId=${destinationId}&slctDestination=${encodeURIComponent(destination)}`}>
                                    {`${destination}  Travel Agents in the ${metro} Metro`}
                                </Link>
                            </div>
                            <div className="cursor-pointer font-normal">
                                <Link to={`/agents?slctState=${getStateCode(state)}&slctMetro=${encodeURIComponent(metro)}&slctInterest=${encodeURIComponent(interest)}`}>
                                    {`${interest} Travel Agents in the ${metro} Metro`}
                                </Link>
                            </div>
                            <div className="cursor-pointer font-normal">
                                <Link to={`/agents?&destinationId=${destinationId}&slctDestination=${encodeURIComponent(destination)}`}>
                                    {`${destination} Travel Agents`}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
}

export default RelatedDestIntByGeo;
