/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import Cookies from 'universal-cookie';
import LocationDropdown from './elements/LocationDropdown';
import Logo from './Logo';

function Header() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [hideNav, setHideNav] = useState(query.get('nav') || '');
    const [preview, setPreview] = useState(query.get('preview') || 'false');
    let displayNav = hideNav;
    const navCookie = new Cookies();
    // Check/Use Nav if in param/cookie

    if ((displayNav === '0') && (preview === 'false')) {
        navCookie.set('hideNav', '0',
            {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }
    //set cookie for 2 minutes when PREVIEW is used
    if ((displayNav === '0') && (preview === 'true')) {
        navCookie.set('hideNav', '0',
            {
                expires: new Date(Date.now() + (2 * 60 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }
    // unset cookie if nav=1, shows navigation
    if (displayNav === '1') {
        navCookie.remove('hideNav', { path: '/', sameSite: 'lax' });
    }

    if (navCookie.get('hideNav')) {
        displayNav = navCookie.get('hideNav');
    }


    const primaryNavigation = [
        {
            name: 'FIND A TRAVEL AGENT',
            link: '/find_travel_agent',
        },
    ];
    const secondaryNavigation = [
        {
            name: 'TRAVEL SPECIALS',
            link: '/promotions',
        },
        {
            name: 'TRAVEL BLOG',
            link: '/travelbetter',
        },
        {
            name: 'TRAVEL INSPIRATION',
            link: '/inspiration',
        },
        {
            name: 'AGENCY MAP SEARCH',
            link: '/agency_locator',
        },
    ];
    if (displayNav === '0') {
        return '';
    }
    return (
        <>
            <div className="header md:hidden bg-white z-50 w-full fixed flex">
                <Menu right className="bg-white justify-end right-0" itemClassName="my-4">
                    {primaryNavigation.map((x) => (

                        <a key={x.name} href={x.link} className={['text-sm font-semibold border-gray-500 tl-red mx-auto px-4 hover:text-black mt-0.5'].join(' ')}>{x.name}</a>

                    ))}
                    {secondaryNavigation.map((x) => (

                        <a key={x.name} href={x.link} className={['text-sm font-semibold border-gray-500 tl-red mx-auto px-4 hover:text-black'].join(' ')}>{x.name}</a>

                    ))}
                    <LocationDropdown />
                </Menu>
                <div className="flex flex-row gap-4 p-4">
                    <Logo />
                </div>
            </div>
            <div className="hidden-tw md:flex justify-between py-2 w-full fixed z-50 shadow-2xl bg-white">
                <div className="flex space-x-4">
                    <div className="p-2">
                        <Logo />
                    </div>
                    <div className="p-2">
                        <ul className="md:flex hidden-tw uppercase list-none p-0">
                            {primaryNavigation.map((x, index) => (
                                <li className="" key={x.name}>
                                    <a href={x.link} className={['text-base font-semibold tl-red hover:text-black', index + 1 !== primaryNavigation.length ? ' border-r-2' : ''].join(' ')}>{x.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="flex space-x-4">
                    <div className="py-3">
                        <ul className="md:flex hidden-tw uppercase list-none p-0">
                            {secondaryNavigation.map((x, index) => (
                                <li className="" key={x.name}>
                                    <a href={x.link} className={['block text-sm font-normal tl-red mx-auto px-4 hover:text-black', index + 1 !== secondaryNavigation.length ? ' border-r-2' : ''].join(' ')}>{x.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="p-2">
                        <LocationDropdown />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
