import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function Loading() {
    return (
        <div className="fixed w-full h-full top-0  left-0 flex justify-center items-center z-50 bg-black opacity-50">
            <Loader
                type="Plane"
                color="#00BFFF"
                height={100}
                width={100}
            />
        </div>
    );
}

export default Loading;
