/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useEffect, useState,
} from 'react';
import Cookies from 'universal-cookie';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const userLocationCookie = new Cookies();

const slideImages = [
    {
        url: 'https://travelleaders.canto.com/direct/image/4nksj91te57i36e2hs56psck44/tAPWwrWHFr5gy--drGgFD532OI8/original?content-type=image%2Fpng&name=ACV_White.png',
        caption: 'Air Canada Vacations',
        locale: 'CA',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/7u8ls4soel5ip34rvleupmq22o/fqD3ovpbJiAOTxlE868-IZ63ZlM/original?content-type=image%2Fpng&name=AMA_White.png',
        caption: 'AmaWaterways',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/9mtk065dnh79bem40cn673og55/yrUPT9omkM6_NOjr9ZgK1sMH7tE/original?content-type=image%2Fpng&name=Avalon_White.png',
        caption: 'Avalon Waterways',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/7f9h4pngul5kbd7or90pe1mi6m/I9Zt7sIMwFi3ZuXmdqmBQftDmx4/original?content-type=image%2Fpng&name=Atlas_White.png',
        caption: 'Atlas Ocean Voyages',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/124q1gqca52on5ia56m88gc87f/YWCQ1aw_1UvAXQzWiKMkb9b4Jrg/original?content-type=image%2Fpng&name=Beaches_White.png',
        caption: 'Beaches',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/28kp9j4u4p5sneg87unt7ffj1f/7fawdfgVswsFvZEVQ9IibeN7J5M/original?content-type=image%2Fpng&name=CEL_White.png',
        caption: 'Celebrity',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/die2khd3s92ir4llre43fa5v43/6Of1aSWBIBkQ2sbOXzjMiv99opw/m800/800',
        caption: 'Disney',
        locale: 'US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/6bcl97d8353vf4cj0nak9n2b1s/Q9DQTOJt1eaLFu2VVtPKNXFU5UU/original?content-type=image%2Fpng&name=Funjet_White.png',
        caption: 'Funjet',
        locale: 'US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/u7q5cgm1q950n7fk0h2mci8l6p/slNhWkvSl2nGq3KTpQfuT7Tq_ck/original?content-type=image%2Fpng&name=Globus_White.png',
        caption: 'Globus',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/7j4vmh4if175j3v50a1dauii1c/UDaN_D2HpITXgIzsKhzbCjdpxic/original?content-type=image%2Fpng&name=HAL-Stacked_White.png',
        caption: 'Holland America Line',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/e0kv98u9n92vr5itivqfpae86d/9IEYExZXkwH4FPP-ba42Su3O3ks/original?content-type=image%2Fpng&name=NCL_White.png',
        caption: 'Norwegian Cruise Line',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/kpud32ofr57p3eaq49s7n6sv0c/4nQz43jmFv_HzvcL5l7mnzW4Z3U/original?content-type=image%2Fpng&name=Oceania_White.png',
        caption: 'Oceania',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/6vun3kj78t0jleebmrb6btgm3r/WWutI4b6lhMi_TTYan62eGqjuh0/original?content-type=image%2Fpng&name=Palace-Resorts_White.png',
        caption: 'Palace Resorts',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/skj85okbqh21v1kc622sdsfc7s/Z79oMGlDncyMyX04XbUWz9-DgCI/original?content-type=image%2Fpng&name=Princess_White.png',
        caption: 'Princess Cruises',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/ch2rt2e1lh6955onl9e1qvmb1j/-yJGWB4rZGD9-m71B6XjKbP54gs/original?content-type=image%2Fpng&name=RSSC_White.png',
        caption: 'Regent Seven Seas Cruises',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/ql7l3ni7vd37fa5vg31t70ht59/0y2q7_KwjnFbeWlHdUbYbf9afTM/original?content-type=image%2Fpng&name=RCI_White.png',
        caption: 'Royal Caribbean International',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/1g7fhii3tt3m1f04kffmepuh1e/bcfQ1X7FBR5AXGhNgzBFFu8AIok/original?content-type=image%2Fpng&name=Sandals_White.png',
        caption: 'Sandals Resorts',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/oshmb73en95250gjulc26uct0l/wK2GwsBjl8EcKAIAOgSQnF2MJmw/original?content-type=image%2Fpng&name=Seabourn_White.png',
        caption: 'Seabourn Cruises',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/bnlihia0dl5ip6f5ii540kmv34/BYr606PxmH9RP3hSH3tR7LnZyr4/original?content-type=image%2Fpng&name=Silversea_White.png',
        caption: 'Silversea Cruises',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/ejd6kr58c92h784r88j9ojkl52/VWJwykFuOfo-Q6v5IMO6KKwxdTc/original?content-type=image%2Fpng&name=Sunwing_White.png',
        caption: 'Sunwing Vacations',
        locale: 'CA',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/r8kco7fa4l2tne4bkv8k2c5r2k/A7AAx9CYt3kcDEEueq3D4iahtH8/original?content-type=image%2Fpng&name=Viking_White.png',
        caption: 'Viking Cruises',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/q3ika7oikh2f76443b23ch0264/CypTEluyQvD00j88vZERY9iJ0IM/m800/800',
        caption: 'Virgin Voyages',
        locale: 'CA,US',
    },
    {
        url: 'https://travelleaders.canto.com/direct/image/hca6juo56h3i1d0iggqn9u3g7r/szLs-TySPJcuU5A0sr2vB92WVU8/original?content-type=image%2Fpng&name=Westjet_White.png',
        caption: 'WestJet Vacations',
        locale: 'CA',
    },
];

const HomePartners = () => {
    const [locale, setLocale] = useState('US');

    useEffect(() => {
        if (userLocationCookie.get('userLocation')) {
            if (userLocationCookie.get('userLocation').country_code === 'CA') {
                setLocale(userLocationCookie.get('userLocation').country_code);
            }
        }
    }, []);


    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '150px',
        padding: '20px',
        maxHeight: '150px',
    };

    const properties = {
        duration: 3000,
        autoplay: true,
        indicators: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: false,
                    infinte: false,
                },
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
        transitionDuration: 500,
    };
    return (
        <div className="bg-footer-slate w-full py-12 px-4 text-center">
            <h3 className="uppercase text-xl mb-2 font-semibold tracking-wide text-white">Travel Leaders top supplier partners</h3>
            <Slide {...properties}>
                {slideImages
                    .filter((slideImage) => slideImage.locale.includes(locale))
                    .map((slideImage, index) => (
                    <div className="each-slide" key={index} style={style}>
                            <img src={`${slideImage.url}`} alt={`${slideImage.caption}`} loading="lazy" style={{ maxHeight: '65px' }} />
                    </div>
                ))}
            </Slide>
        </div>
    );
};

export default HomePartners;
