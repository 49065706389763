import React from 'react';
import PropTypes from 'prop-types';

function Button({
     onClick, title, disabled, width, padding, fontSize,
    }) {
    return (
        <button
            type="button"
            className={[`bg-white rounded-full ${padding} text-red ${fontSize} ${width} border border-tl-red hover:bg-tl-red hover:text-white`,
                disabled ? 'text-gray-300 border-gray-300' : 'text-red-600 border-red-600'].join(' ')}
            onClick={onClick}
        >
            {title}
        </button>
    );
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    width: 'w-full',
    padding: 'py-3 px-6',
    fontSize: 'text-sm',
};

export default Button;
