/* eslint-disable react/style-prop-object */
/* eslint-disable prefer-template */
import React, {
    useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import { useLocation, useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import parser from 'html-react-parser';
import Cookies from 'universal-cookie';
import { IoClose } from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import ShowMoreText from 'react-show-more-text';
import StickyBox from 'react-sticky-box';
import Hero from '../components/Hero';
import WhyComponent from '../components/WhyComponent';

import {
    callMe, CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE, PROMO_IMAGE_URL, modalCustomStyles, randomIntFromInterval,
} from '../utils';
import useAppStore from '../contexts/useAppStore';
import API from '../services/API';
import EmailForm from '../components/EmailFormPromos';
import PhoneForm from '../components/PhoneForm';
import Button from '../components/elements/Button';
import ButtonInverted from '../components/elements/ButtonInverted';
import Loading from '../components/elements/Loading';
import BreadCrumb from '../components/BreadCrumb';

function Promotion() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [agentData, setAgentData] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState();
    const [links, setLinks] = useState([]);
    const [showPinBar, setShowPinBar] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search.toLowerCase());
    }

    function addSupplier(count, supplierName) {
        let appendUrl = '';
        if (count === 1) {
            appendUrl = `?supplier=${encodeURIComponent(supplierName)}`;
        }
        return appendUrl;
    }
    const query = useQuery();
    const [category, setCategory] = useState(query.get('category') || null);
    const myIp = query.get('ip') || null;
    const [preview, setPreview] = useState(query.get('preview') || 'false');
    const [rowCount, setRowCount] = useState(query.get('rowcount') || null);
    const [nav, setNav] = useState(query.get('nav') || '1');
    const [agentId, setAgentId] = useState(query.get('agentid') || 0);
    const [agencyId, setAgencyId] = useState(query.get('agencyid') || 0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [mobAgentPosition, setMobAgentPosition] = useState('0px');
    const agentCookie = new Cookies();
    let returnAgents = 3;
    let useAgencyId = agencyId;
    let useAgentId = agentId;

    // if rowcount parameter exists and is integer, set maxium agents allowed to be returned
    if ((rowCount) && (!Number.isNaN(+rowCount))) {
        rowCount <= 12 ? returnAgents = rowCount : null;
    }
    // Check/Use AgentId if in param/cookie
    if (agentId !== 0) {
        returnAgents = 1;
        agentCookie.set('ckAgentId', agentId,
            {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }

    if (agentCookie.get('ckAgentId')) {
        useAgentId = agentCookie.get('ckAgentId');
        returnAgents = 1;
    }
    //Check/Use AgencyId if in param/cookie
    if (agencyId !== 0) {
        agentCookie.set('ckAgencyId', agencyId,
            {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }

    if (agentCookie.get('ckAgencyId')) {
        useAgencyId = agentCookie.get('ckAgencyId');
    }

    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal, getAgentCount,
    } = useAppStore();

    const getPromotionDetail = () => {
        setLoading(true);
        API.getPromotionDetail({ promotionId: id, preview }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.errorMsg === null) {
                    setData(result.data);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const userLocationCookie = new Cookies();

    const getMatchedAgentsUserLocation = async () => {
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*GET CLOSEST AGENTS*/
                API.agentMatchToInterest({
                    id: useAgentId,
                    agencyId: useAgencyId,
                    destination: data.promotionDestinations.length > 0 ? data.promotionDestinations.map((x) => x.locationID).join(',') : 0,
                    interest: data.promotionInterests.length > 0 ? data.promotionInterests.map((x) => x.interestID).join(',') : 0,
                    supplier: data.promotionSuppliers.length > 0 ? data.promotionSuppliers.map((x) => x.supplierID).join(',') : 0,
                    latitude: ipResult.data.latitude,
                    longitude: ipResult.data.longitude,
                    returnAgents,
                    locality: ipResult.data.country_code,
                    promotionId: id,
                }).then(async (agentResult) => {
                    if (agentResult.responseStatus === 1) {
                        setAgentData(agentResult.data);
                    } else {
                        toast(agentResult.error.message);
                    }
                });
            } else {
                toast(ipResult.error.message);
            }
        });
    };
    const getMatchedAgents = async () => {
        API.agentMatchToInterest({
            id: useAgentId,
            agencyId: useAgencyId,
            destination: data.promotionDestinations.length > 0 ? data.promotionDestinations.map((x) => x.locationID).join(',') : 0,
            interest: data.promotionInterests.length > 0 ? data.promotionInterests.map((x) => x.interestID).join(',') : 0,
            supplier: data.promotionSuppliers.length > 0 ? data.promotionSuppliers.map((x) => x.supplierID).join(',') : 0,
            latitude: userLocationCookie.get('userLocation').latitude,
            longitude: userLocationCookie.get('userLocation').longitude,
            returnAgents,
            locality: userLocationCookie.get('userLocation').country_code,
            promotionId: id,
        }).then(async (result) => {
            if (result.responseStatus === 1) {
                setAgentData(result.data);
            } else {
                toast(result.error.message);
            }
        });
    };

    useEffect(() => {
        if (data) {
            if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
                getMatchedAgentsUserLocation();
            } else if ((userLocationCookie.get('userLocation')) && (myIp === null)) {
                getMatchedAgents();
            }
        }
    }, [data]);

/*Check if Cookie Consent has been check if not then move mobile agent view higher*/
    useEffect(() => {
        const handleScroll = () => {
            if (!agentCookie.get('cookieconsent_status')) {
                setMobAgentPosition('125px');
            } else {
                setMobAgentPosition('0px'); // Reset to initial position or any other desired value
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const myStyle = {
        backgroundColor: '#022439',
        bottom: mobAgentPosition,
        position: 'fixed',
    };

    useEffect(() => {
        if (data) {
            const base = [{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Travel Specials',
                url: '/promotions',
            }];
            if (category) {
                const text = `${category.charAt(0).toUpperCase() + category.slice(1)}s`;

                base.push({
                    label: text,
                    url: `/promotion_category?category=${category}`,
                });
            }
            base.push({
                label: parser(`${data.title}`),
            });

            setLinks(base);
        }
    }, [category, data]);

    useEffect(() => {
        getPromotionDetail();
        window.scrollTo(0, 0);
    }, []);

    const emailMe = (agent) => {
        setSelectedAgent(agent);
        setIsEmailFormModalOpen(true);
    };

    const closeModal = () => {
        closeFormModal();
    };

    const listenToScroll = () => {
        const heightToHideFrom = 450;

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) { setShowPinBar(true); } else { setShowPinBar(false); }
    };
    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('resize', listenToScroll);
        };
    }, []);

    const nowDate = new Date();
    const today = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();

    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    function heroImage() {
        let heroProps = { imageType: 'SmallHeader', imageHeight: '260px', imageCSS: '' };
        if (windowWidth >= 568) {
            heroProps = { imageType: 'LargeHeaderNat', imageHeight: '600px', imageCSS: '' };
        }
        return heroProps;
    }

    return (
        <>
            <ToastContainer />
            {data
                && (
                    <>
                        <Helmet>
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
                            <link rel="preconnect" href="https://fonts.googleapis.com" />
                            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                            <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
                            <title>
                                {data.shortDescription}
                                {' '}
                              | TravelLeaders.com
                            </title>
                            <meta name="title" content={`${data.title}`} />
                            <meta
                                name="description"
                                content={`${data.shortDescription.substring(0, 165)}`}
                            />
                        {/*FACEBOOK META */}
                            <meta
                                property="og:title"
                                content={data.title}
                            />
                            <meta
                            property="og:description"
                            content={data.promotionSocialInfo.find((x) => x.socialSiteType === 'FB') !== undefined ? (
                                `${data.promotionSocialInfo.find((x) => x.socialSiteType === 'FB').description}`
                            ) : (
                                    `${data.shortDescription}`
                                )}
                            />
                            <meta
                                property="og:image"
                            content={data.promotionSocialInfo.find((x) => x.socialSiteType === 'FB') !== undefined ? (
                                `${PROMO_IMAGE_URL()}${data.promotionSocialInfo.find((x) => x.socialSiteType === 'FB').fileName}`
                            ) : data.promotionImages.find((x) => x.imageType === 'LargeHeaderNat') !== undefined ? (
                                `${PROMO_IMAGE_URL()}${data.promotionImages.find((x) => x.imageType === 'LargeHeaderNat').fileName}`
                            ) : ''}
                            />
                            <meta property="og:type" content="article" />
                            <meta
                                property="og:url"
                                content={`${window.location.origin}${window.location.pathname}`}
                            />
                            <meta property="og:site_name" content="Travel Leaders" />
                            <meta property="fb:admins" content="631463929" />
                            <meta property="fb:app_id" content="130781060338611" />
                        {/*TWITTER META */}
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:site" content="@travelleaders" />
                            <meta name="twitter:creator" content="@travelleaders" />
                            <meta name="twitter:title" content={`${data.title}`} />
                            <meta
                                name="twitter:description"
                                content={data.promotionSocialInfo.find((x) => x.socialSiteType === 'TW') !== undefined ? (
                                    `${data.promotionSocialInfo.find((x) => x.socialSiteType === 'TW').description}`
                                ) : (
                                        `${data.shortDescription}`
                                    )}
                            />
                            <meta
                                name="twitter:image"
                                content={data.promotionSocialInfo.find((x) => x.socialSiteType === 'TW') !== undefined ? (
                                    `${PROMO_IMAGE_URL()}${data.promotionSocialInfo.find((x) => x.socialSiteType === 'TW').fileName}`
                                ) : data.promotionImages.find((x) => x.imageType === 'LargeHeaderNat') !== undefined ? (
                                        `${PROMO_IMAGE_URL()}${data.promotionImages.find((x) => x.imageType === 'LargeHeaderNat').fileName}`
                                    ) : ''}
                            />
                            <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />
                        </Helmet>
                        <div className="w-full relative">
                            <div className="ml-2 mt-4 md:mt-0 absolute z-50 hidden md:block">
                                {nav !== '0' ? <BreadCrumb links={links} inverted /> : '' }
                            </div>
                        <Hero
                            title={parser(`${data.title}`)}
                            imageFile={data.promotionImages.find((x) => x.imageType === heroImage().imageType) !== undefined ? (
                                `${PROMO_IMAGE_URL()}${data.promotionImages.find((x) => x.imageType === heroImage().imageType).fileName}`
                            ) : 'https://via.placeholder.com/1920x550/CCC?text=HERO'}
                            useAbsouluteImageUrl
                            additionalCSS="p-0"
                            textCSS="text-shadow"
                            height={heroImage().imageHeight}
                            imageCSS={heroImage().imageCSS}
                        />
                            <div className="max-w-screen-2xl mx-auto min-h-screen">
                                {preview === 'true' ? (
                                    <div className="fixed w-flex w-full top-16 bg-yellow-500 z-10 bg-opacity-80 border-b-4 border-dashed border-red-600 p-5 text-center z-50">
                                        <h2 className="text-2xl uppercase font-bold">YOU ARE VIEWING THIS PAGE IN PREVIEW MODE.</h2>
                                        <div className="font-bold underline">Please do not share this preview to consumers</div>
                                        <div className="text-l font-bold">
                                        Display Dates:
                                            {' '}
                                            {moment(data.displayStartDate).format('L')}
                                            {' - '}
                                            {moment(data.displayEndDate).format('L')}
                                        </div>
                                    {data.promotionTimedContent.length > 0 ? (
                                        data.promotionTimedContent.map((x, index) => (
                                            <div>
                                                {index === 0 ? <div key={x.index} className="font-bold text-xl">TIMED CONTENT</div> : ''}
                                                {`${moment(x.startDate).format('L')} - ${moment(x.endDate).format('L')} : ${x.bodyTitle}`}
                                            </div>
                                            ))
                                    ) : ''}
                                    </div>
                                ) : ''}
                                <div className="md:-mt-16 relative left-0 md:ml-20 md:mr-52 right-0 z-10">
                                    <div className="flex flex-wrap justify-center space-x-4 bg-white md:shadow-xl rounded px-4 py-6 z-40 text-center">
                                            {data.promotionImages.find((x) => x.imageType === 'MultiSupplierBanner') !== undefined ? (
                                                <>
                                                <img className="object-contain" src={`${PROMO_IMAGE_URL()}${data.promotionImages.find((x) => x.imageType === 'MultiSupplierBanner').fileName}`} alt="Supplier Logo" title="" loading="lazy" />
                                                </>
                                            ) : (
                                                    data.promotionSuppliers.map((x, index) => (
                                                        <>
                                                            {data.promotionSuppliers.length < 5 ? (
                                                            <div key={x.index} className={data.promotionSuppliers.length > 2 ? 'w-1/5 flex justify-center py-2' : 'w-1/3 -mt-20 md:mt-0 p-2 md:px-0 bg-white rounded-xl md:rounded-none shadow-xl md:shadow-none md:w-1/6 flex justify-center'}>
                                                                <img className="object-contain" src={`${PROMO_IMAGE_URL()}${x.supplierLogo}`} alt={x.supplierName} title={x.supplierName} loading="lazy" />
                                                            </div>
                                                            ) : '' }
                                                        </>
                                                    ))
                                            )}
                                        <div className={data.promotionSuppliers.length > 2 ? 'w-full' : 'w-full md:w-2/3 text-center'}>
                                            <div className="w-full pt-1 pb-4 font-bold text-lg md:text-xl">
                                                {parser(data.teaserText.length > 1 ? data.teaserText : data.shortDescription)}
                                            </div>
                                            <div className={`flex flex-col md:flex-row ${data.promotionSuppliers.length > 3 ? 'justify-center' : 'justify-center'}`}>
                                                <div className="border-b md:border-b-0 py-2 md:py-0 md:pr-4 text-sm md:font-bold">
                                                    Booking Period: Today -
                                                    {' '}
                                                    {moment(data.bookingEndDate).format('l')}
                                                </div>
                                                <div className="py-2 md:py-0 text-sm md:font-bold">
                                                    Travel Period:
                                                    {' '}
                                                    {data.travelPeriod}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="min-h-screen">
                                {data && (
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div className="w-full px-4 md:ml-10 md:px-10 py-10 divPromotionBody">
                                            <div dangerouslySetInnerHTML={{ __html: data.promotionBody }} />
                                            {/*Timed Content*/}
                                            {data.promotionTimedContent.length > 0 ? (
                                                data.promotionTimedContent.map((x, index) => (
                                                    <>
                                                        {((Date.parse(x.endDate) >= Date.parse(today) && (Date.parse(x.startDate) <= Date.parse(today))) || (preview === 'true')) ? (
                                                            <div key={x.promotionBodyId} className="w-full divPromotionBody">
                                                                <div dangerouslySetInnerHTML={{ __html: x.bodyDetail }} />
                                                            </div>
                                                        ) : ''}
                                                    </>
                                                ))
                                            ) : ''}
                                            {/*Inspiration Content*/}
                                            {data.promotionInspirationContent.length > 0 ? (
                                                data.promotionInspirationContent.map((x, index) => (
                                                    <>
                                                        <div key={x.inspirationId} className="w-full divPromotionBody border-b border-dashed mb-12">
                                                            <h2 className="uppercase my-4">{x.title}</h2>
                                                            <div className="mb-4">
                                                                <img
                                                                    src={`${PROMO_IMAGE_URL()}/PromoImage/${x.filename}`}
                                                                    alt={x.altTitle}
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{ __html: x.content }} />
                                                        </div>
                                                    </>
                                                ))
                                            ) : ''}
                                        </div>
                                        {agentData.slice(0, 1).map((mainAgent) => (
                                            <>
                                                <StickyBox
                                                    key={mainAgent.agentId}
                                                    offsetTop={200}
                                                    offsetBottom={0}
                                                    className="hidden-tw md:block"
                                                    style={
                                                        {
                                                            backgroundColor: '#022439',
                                                            color: '#FFFFFF',
                                                            zIndex: '10',
                                                            minWidth: '150px',
                                                            minHeight: '250px',
                                                            marginTop: '20px',
                                                            marginBottom: '20px',
                                                            marginRight: '20px',
                                                            borderRadius: '5px',
                                                            boxShadow: '3px 3px 5px 0px #7C7C7C',
                                                        }
                                                    }
                                                >
                                                    <div className="w-36 h-36 uppercase text-xs text-center p-2 m-auto">
                                                        Plan your next trip with
                                                        <div className="h-20 w-20 mx-auto rounded-full overflow-hidden my-2">
                                                            <img
                                                                alt={mainAgent.firstName}
                                                                src={mainAgent.agentPhotoFileName}
                                                                className=""
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <div className="font-bold">{mainAgent.firstName}</div>
                                                        <div className="capitalize">
                                                            {mainAgent.city}
                                                            {', '}
                                                            {mainAgent.state}
                                                        </div>
                                                        <div className="flex flex-col md:flex-row gap-1 md:gap-1 w-full mt-2 justify-center">
                                                            {mainAgent.agentConnections.length > 0 && (
                                                                <>
                                                                {mainAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                                                    <div className="w-full md:w-1/2">
                                                                        <Button
                                                                            title="EMAIL"
                                                                            onClick={() => emailMe(mainAgent)}
                                                                            padding="p-1"
                                                                            fontSize="text-xs"
                                                                        />
                                                                    </div>
                                                                )}
                                                                {mainAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                                                    <div className="w-full md:w-1/2">
                                                                        <Button
                                                                            title="CALL"
                                                                            onClick={() => callMe(mainAgent, () => {
                                                                                setSelectedAgent(mainAgent);
                                                                                setIsPhoneFormModalOpen(true);
                                                                            })}
                                                                            padding="p-1"
                                                                            fontSize="text-xs"
                                                                        />
                                                                    </div>
                                                                    )}
                                                                </>
                                                            )}
                                                                {/*if agent id is not found use generic approver email*/}
                                                                {mainAgent.agentId === 0 ? (
                                                                    <>
                                                                        <div className="w-full md:w-1/2">
                                                                            <Button
                                                                                title="EMAIL"
                                                                                onClick={() => emailMe(mainAgent)}
                                                                                padding="p-1"
                                                                                fontSize="text-xs"
                                                                            />
                                                                        </div>
                                                                        <div className="w-full md:w-1/2">
                                                                            <Button
                                                                                title="CALL"
                                                                                onClick={() => callMe(mainAgent, () => {
                                                                                    setSelectedAgent(mainAgent);
                                                                                    setIsPhoneFormModalOpen(true);
                                                                                })}
                                                                                padding="p-1"
                                                                                fontSize="text-xs"
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ) : ''}
                                                        </div>
                                                        <div className="w-full">
                                                            {(useAgentId === 0) && (useAgencyId === 0) ? (
                                                                <Link to={`/agent/${mainAgent.agentId}${addSupplier(data.promotionSuppliers.length, data.promotionSuppliers.length > 0 ? data.promotionSuppliers[0].supplierName : null)}`} className="bg-tl-red rounded-full p-1 text-white text-xs hover:bg-red-900 block my-2">VIEW BIO</Link>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                </StickyBox>
                                                <div className={[showPinBar ? 'opacity-100' : 'opacity-0 hidden-tw', 'fixed bottom-0 w-full text-white p-4 text-center z-10 hiddenAgent'].join(' ')} style={myStyle}>
                                                    <div className="-mt-14 border h-20 w-20 mx-auto rounded-full overflow-hidden my-2">
                                                        <img
                                                            alt={mainAgent.firstName}
                                                            src={mainAgent.agentPhotoFileName}
                                                            className=""
                                                            loading="lazy"
                                                        />
                                                    </div>
                                                    <div className="font-bold uppercase">Let&apos;s Plan Your Next Trip</div>
                                                    <div className="font-bold">
                                                        <Link to={`/agent/${mainAgent.agentId}${addSupplier(data.promotionSuppliers.length, data.promotionSuppliers.length > 0 ? data.promotionSuppliers[0].supplierName : null)}`} className="bg-tl-red rounded-full p-1 text-white text-xs hover:bg-red-900 block my-2">
                                                            {mainAgent.firstName}
                                                        </Link>
                                                    </div>
                                                        <div className="flex flex-row gap-2 w-full mt-2 justify-center">
                                                        {mainAgent.agentConnections.length > 0 && (
                                                            <>
                                                                {mainAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                                                    <div className="w-1/2">
                                                                        <Button
                                                                            title="EMAIL"
                                                                            onClick={() => emailMe(mainAgent)}
                                                                            padding="p-1"
                                                                            fontSize="text-xs"
                                                                        />
                                                                    </div>
                                                                )}
                                                                {mainAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                                                    <div className="w-1/2">
                                                                        <Button
                                                                            title="CALL"
                                                                            onClick={() => callMe(mainAgent, () => {
                                                                                setSelectedAgent(mainAgent);
                                                                                setIsPhoneFormModalOpen(true);
                                                                            })}
                                                                            padding="p-1"
                                                                            fontSize="text-xs"
                                                                        />
                                                                    </div>
                                                                    )}
                                                            </>
                                                        )}
                                                            {/*if agent id is not found use generic approver email*/}
                                                            {mainAgent.agentId === 0 ? (
                                                                <>
                                                                    <div className="w-1/2">
                                                                        <Button
                                                                            title="EMAIL"
                                                                            onClick={() => emailMe(mainAgent)}
                                                                            padding="p-1"
                                                                            fontSize="text-xs"
                                                                        />
                                                                    </div>
                                                                    <div className="w-1/2">
                                                                        <Button
                                                                            title="CALL"
                                                                            onClick={() => callMe(mainAgent, () => {
                                                                                setSelectedAgent(mainAgent);
                                                                                setIsPhoneFormModalOpen(true);
                                                                            })}
                                                                            padding="p-1"
                                                                            fontSize="text-xs"
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : ''}
                                                        </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                )}
                                {returnAgents > 0 ? (
                                    <div className="w-full pt-10 pb-8 px-4 bg-light-blue relative z-20">
                                        <div className="mx-4 md:mx-40 py-10 text-center">
                                            {((data.promotionSuppliers.length === 1) && (data.promotionInterests.length === 0) && (data.promotionDestinations.length === 0)) ? (
                                                <h2 className="tl-cyan uppercase text-4xl tracking-wide" dangerouslySetInnerHTML={{ __html: `Connect with one of these ${data.promotionSuppliers[0].supplierName.replace('®', '<sup>®</sup>')} specialists today!` }} />
                                            ) : (
                                                    <h2 className="tl-cyan uppercase text-4xl tracking-wide">We are here to help you turn your travel dreams into reality. contact us today!</h2>
                                                )}
                                        </div>
                                        <div className={`grid grid-cols-1 md:grid-cols-${returnAgents <= 3 ? returnAgents : '3'} gap-4 mx-4 lg:mx-40`}>
                                            {agentData.map((featAgent, index) => (
                                                <div
                                                    className="flex flex-col bg-white shadow-lg p-4 rounded text-center"
                                                    key={featAgent.agentId}
                                                >
                                                    <div className="flex-grow">
                                                        <div className="mx-auto w-36 h-36 overflow-hidden object-cover border rounded-lg">
                                                            <Link to={`/agent/${featAgent.agentId}${addSupplier(data.promotionSuppliers.length, data.promotionSuppliers.length > 0 ? data.promotionSuppliers[0].supplierName : null)}`} className="hover:opacity-75" style={(useAgentId === 0) && (useAgencyId === 0) ? { margin: '0' } : { pointerEvents: 'none' }}>
                                                                <img
                                                                    src={featAgent.agentPhotoFileName}
                                                                    alt={`${featAgent.firstName} ${featAgent.lastName}`}
                                                                    className="min-w-full"
                                                                    loading="lazy"
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="w-full text-xl tl-red font-semibold mt-2 mb-1 uppercase">
                                                            {featAgent.firstName}
                                                            {' '}
                                                            {featAgent.agentId !== 0 ? featAgent.lastName : ''}
                                                        </div>
                                                        <div className="w-full text-sm text-gray-500 mb-1">
                                                            {featAgent.city}
                                                            {', '}
                                                            {featAgent.state}
                                                        </div>
                                                        <div className="w-full text-sm mb-1">
                                                            {featAgent.websiteDisplayTitle}
                                                        </div>
                                                        <div className="mb-1 h-8">
                                                            {featAgent.agentRating.rating > 0
                                                                && (
                                                                    <StarRatings
                                                                        rating={featAgent.agentRating.rating}
                                                                        starRatedColor="#0A7790"
                                                                        numberOfStars={5}
                                                                        name="rating"
                                                                        starDimension="28px"
                                                                        starSpacing="4px"
                                                                    />
                                                                )}
                                                        </div>
                                                        <div className="mb-1 text-red uppercase">
                                                            {(useAgentId === 0) && (useAgencyId === 0) ? (
                                                                <Link to={`/agent/${featAgent.agentId}${addSupplier(data.promotionSuppliers.length, data.promotionSuppliers.length > 0 ? data.promotionSuppliers[0].supplierName : null)}`} className="tl-red uppercase text-xs text-semibold">VIEW BIO &gt;</Link>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-4">
                                                        {featAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                                            <div className="col-span-1">
                                                                <ButtonInverted
                                                                    title="EMAIL ME"
                                                                    onClick={() => emailMe(featAgent)}
                                                                />
                                                            </div>
                                                        )}
                                                        {featAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                                            <div className="col-span-1">
                                                                <ButtonInverted
                                                                    title="CALL ME"
                                                                    onClick={() => callMe(featAgent, () => {
                                                                        setSelectedAgent(featAgent);
                                                                        setIsPhoneFormModalOpen(true);
                                                                    })}
                                                                />
                                                            </div>
                                                        )}
                                                        {/*if agent id is not found use generic approver email*/}
                                                        {featAgent.agentId === 0 ? (
                                                            <>
                                                                <div className="col-span-1">
                                                                    <ButtonInverted
                                                                        title="EMAIL ME"
                                                                        onClick={() => emailMe(featAgent)}
                                                                    />
                                                                </div>
                                                                <div className="col-span-1">
                                                                    <ButtonInverted
                                                                        title="CALL ME"
                                                                        onClick={() => callMe(featAgent, () => {
                                                                            setSelectedAgent(featAgent);
                                                                            setIsPhoneFormModalOpen(true);
                                                                        })}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : ''}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {(useAgentId === 0) && (useAgencyId === 0) ? (
                                            <div className="text-center mt-4">
                                                {((data.promotionSuppliers.length === 1) && (data.promotionInterests.length === 0) && (data.promotionDestinations.length === 0)) ? (
                                                    <Link to={`/agents?slctSupplier=${encodeURIComponent(data.promotionSuppliers[0].supplierName)}`} className="tl-red uppercase text-l font-bold" dangerouslySetInnerHTML={{ __html: `Select other ${data.promotionSuppliers[0].supplierName.replace('®', '<sup>®</sup>')} agents who can help you plan the perfect trip` }} />
                                                ) : (
                                                        <Link to="/find_travel_agent" className="text-red-700 uppercase text-l font-bold">{`Select from ${getAgentCount().toLocaleString()} other agents who can help you plan the perfect trip`}</Link>
                                                    )}
                                            </div>
                                        ) : ''}
                                    </div>
                                ) : '' }
                                {(data.terms.length > 0) || (data.promotionTimedContent.length > 0) ? (
                                    <div className="flex flex-col gap-2 px-4  md:mx-10 md:px-10 py-10 terms relative z-10 bg-white">
                                        <h3 className="font-bold m-0 p-0 italic">Terms &amp; Conditions</h3>
                                        {data.promotionDisplayChannels.some((channel) => channel.displayChannelId === 7)
                                            && !data.promotionDisplayChannels.some((channel) => channel.displayChannelId === 1) ? (
                                                <div className="text-base font-bold tracking-normal tl-red">Please note: This is a Canada-specific promotion. Any fares shown are in CAD.</div>) : ''}
                                        <div className="text-base font-bold">Travel advisors may charge an additional planning fee depending on the complexity of the trip.</div>
                                        <div className="">
                                            Vacation Code:
                                            &nbsp;
                                            <span className="underline font-bold">
                                                {data.promotionCode}
                                            </span>
                                        </div>
                                        <ShowMoreText
                                            lines={data.terms.length > 350 ? 3 : 0}
                                            more="View Full Terms"
                                            less="Show less"
                                            anchorClass="text-red-600 hover:text-red-900"
                                            expanded={false}
                                            truncatedEndingComponent="..."
                                        >
                                            <div className="divPromotionTerms" dangerouslySetInnerHTML={{ __html: data.terms }} />
                                            {data.promotionTimedContent.map((x, index) => (
                                                <>
                                                    {((Date.parse(x.endDate) >= Date.parse(today) && (Date.parse(x.startDate) <= Date.parse(today))) || (preview === 'true')) ? (
                                                        <div className="divPromotionTerms" key={x.promotionBodyId}>
                                                            <div dangerouslySetInnerHTML={{ __html: x.terms }} />
                                                        </div>
                                                    ) : ''}
                                                </>
                                            ))}
                                        </ShowMoreText>
                                    </div>
                                ) : ''}
                                </div>
                            </div>
                            <div className="mt-10 md:mt-20 bg-light-blue">
                                <div className="md:relative md:-top-8">
                                    <WhyComponent
                                        displayStats="hidden"
                                        barColor="bg-dark-blue-bar"
                                        inactiveColor="text-white"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            {(!data && loading === false) && (
                <div className="mx-4 md:mx-20 py-20 text-center">
                    <h2 className="tl-cyan uppercase text-4xl tracking-wide">Sorry, promotion has expired or is invalid</h2>
                    <div>
                        <h3 className="uppercase text-xl my-2 font-semibold tracking-wide">
                            It looks like the promotion you have selected is no longer available.
                            <br />
                            <a href="/promotions" className="tl-red hover:text-black ">Don’t worry, we have plenty of other promotions to choose from</a>
                        </h3>
                    </div>
                </div>
            )}
            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agent={selectedAgent} promotion={data} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agent={selectedAgent}
                            phone={selectedAgent.agentId === 0 ? selectedAgent.hostAgencyPhone : selectedAgent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}
                </div>
            </Modal>
            {loading && <Loading />}
        </>
    );
}

export default Promotion;
