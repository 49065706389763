/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html-react';


function AgentItineraries({
    agent,
    itineraries,
    limit,
    title,
}) {
    return (
        <div className="md:-mx-20 -mx-2 md:px-20 py-14 flex flex-col bg-white">
            <h2 className="text-center md:text-left tl-cyan uppercase text-4xl tracking-wide px-4">
                {title}
            </h2>
            {itineraries.slice(0, limit).map((x) => (
                <div className="" key={x.itineraryId}>
                    <div className="md:flex gap-4 mx-4 my-8">
                        <div className="w-full m-auto md:w-1/3 overflow-hidden rounded h-38" style={{ boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)' }}>
                            <Link to={`/agent/${agent.agentId}/itinerary/${x.itineraryId}`}>
                                <img src={x.itineraryImage} className="h-48 object-cover m-auto" alt={x.title} loading="lazy" />
                            </Link>
                        </div>
                        <div className="w-full">
                            <h3 className="text-center md:text-left my-2 md:my-0 text-xl font-bold uppercase">
                                {x.title}
                            </h3>
                            <div className="flex flex-col md:flex-row">
                                <div className="w-full md:w-1/2 py-2 md:py-4">
                                    <div className="flex float-left w-8 h-8 bg-cyan rounded-full text-center text-white justify-center items-center">
                                        <i className="fa fa-globe" aria-hidden="true" />
                                    </div>
                                    <div className="float-left pl-4">
                                        <div className="uppercase tl-cyan font-semibold -mt-1">Countries visited</div>
                                        <div className="-mt-1">
                                            {x.visitedCountries}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 py-2 md:py-4">
                                    <div className="flex float-left w-8 h-8 bg-cyan rounded-full text-center text-white justify-center items-center">
                                        <i className="fa fa-calendar" aria-hidden="true" />
                                    </div>
                                    <div className="float-left pl-4">
                                        <div className="uppercase tl-cyan font-semibold -mt-1">Suggested Duration</div>
                                        <div className="-mt-1">
                                            {x.itineraryDays.length && (
                                                <>
                                                    {`${x.itineraryDays.length} Days`}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <Link to={`/agent/${agent.agentId}/itinerary/${x.itineraryId}`}>
                                    {x.overview.length > 250 ? parser(`${sanitizeHtml(x.overview.slice(0, 250))}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)
                                        : parser(`${sanitizeHtml(x.overview)}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {itineraries.length > limit ? (
                <>
                    <Link to={`/agent/${agent.agentId}/itineraries`} className="bg-white rounded-full py-2 px-2 text-center tl-red text-sm border border-red-600 md:w-1/4 uppercase mx-auto hover:bg-red-600 hover:text-white">
                        View all Itineraries
                    </Link>
                </>
            ) : ''}
        </div>
    );
}

AgentItineraries.propTypes = {
    agent: PropTypes.object.isRequired,
    itineraries: PropTypes.array.isRequired,
    limit: PropTypes.number,
    title: PropTypes.string,
};

AgentItineraries.defaultProps = {
    limit: 3,
    title: 'My Itineraries',
};

export default AgentItineraries;
