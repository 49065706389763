/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TLLogo } from '../utils';

function Logo() {
    const router = useHistory();
    return (
        <img
            alt=""
            src={TLLogo}
            className="w-60 inline-block cursor-pointer"
            onClick={() => router.push('/')}
            loading="lazy"
        />
    );
}

export default Logo;
