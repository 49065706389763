/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import useAppStore from '../contexts/useAppStore';
import 'react-accessible-accordion/dist/fancy-example.css';

function WhyComponent({
    displayStats,
    displayValueProp,
    barColor,
    inactiveColor,
}) {
    const {
        getAgentCount,
    } = useAppStore();
    const [selected, setSelected] = useState(1);
    const totalYearsinBusiess = (new Date().getFullYear() - 1947);
    const totalAgentCount = getAgentCount();
    const totalAwards = 65;
    const totalPartnerships = 300;
    const reccomended = 99.23;
    const positiveReviews = '130,000';
    const averageRating = 4.98;
    const text1 = () => (
        <div className="flex flex-col gap-6 md:max-w-5xl text-center tl-blue-dark">
            <div>
                <p className="text-lg leading-7">
                    <strong>Travel Leaders Network is the largest network of professional travel agents in North America.</strong>
                    &nbsp;Wherever you want to go, however you want to get there — we have a travel agent for you.
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:mb-20 md:max-w-5xl mx-auto">

                <div className="grid grid-cols-3 gap-2">
                    <div className="col-span-1 justify-self-end">
                        <img
                            src="/images/icon-loupe.png"
                            alt="Magnifying Loupe"
                            height="75"
                            width="75"
                            loading="lazy"
                        />
                    </div>
                    <div className=" col-span-2 text-left text-base">
                        <div className="uppercase font-bold">Explore</div>
                        <div className="text-sm">Our database of travel experts & specialists</div>
                    </div>

                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="col-span-1 justify-self-end">
                        <img
                            src="/images/icon-phone.png"
                            alt="Phone"
                            height="75"
                            width="75"
                            loading="lazy"
                        />
                    </div>
                    <div className=" col-span-2 text-left text-base">
                        <div className="uppercase font-bold">Connect</div>
                        <div className="text-sm">With an agent via text, email or phone</div>
                    </div>

                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="col-span-1 justify-self-end">
                        <img
                            src="/images/icon-plane.png"
                            alt="Airplane"
                            height="75"
                            width="75"
                            loading="lazy"
                        />
                    </div>
                    <div className=" col-span-2 text-left text-base">
                        <div className="uppercase font-bold">Enjoy</div>
                        <div className="text-sm">A stress-free vacation with a professional by your side</div>
                    </div>

                </div>
            </div>
        </div>
    );

    const text2 = () => (
        <div className="flex flex-col gap-6 md:max-w-5xl text-center">
            <div>
                <p>
                    <strong>Using an Agent Enhances Your Overall Travel Experience.</strong>
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:mb-20 md:max-w-5xl mx-auto">

                <div className="grid grid-cols-3 gap-2">
                    <div className="col-span-1 justify-self-end">
                        <img
                            src="/images/icon-clock.png"
                            alt="Stopwatch"
                            height="75"
                            width="75"
                            loading="lazy"
                        />
                    </div>
                    <div className=" col-span-2 text-left text-base">
                        <div className="uppercase font-bold">Save Time</div>
                        <div className="text-sm">Let our expertise and research help guide you</div>
                    </div>

                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="col-span-1 justify-self-end">
                        <img
                            src="/images/icon-wine.png"
                            alt="Wine and Glass"
                            height="75"
                            width="75"
                            loading="lazy"
                        />
                    </div>
                    <div className=" col-span-2 text-left text-base">
                        <div className="uppercase font-bold">Travel Benefits</div>
                        <div className="text-sm">Enjoy exclusive offers only available through travel agents</div>
                    </div>

                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="col-span-1 justify-self-end">
                        <img
                            src="/images/icon-balloon.png"
                            alt="Balloon"
                            height="75"
                            width="75"
                            loading="lazy"
                        />
                    </div>
                    <div className=" col-span-2 text-left text-base">
                        <div className="uppercase font-bold">Custom Service</div>
                        <div className="text-sm">We can craft unique experiences tailored just for you</div>
                    </div>

                </div>
            </div>
        </div>
    );

    const text3 = () => (
        <div className="flex flex-col gap-6 md:max-w-5xl text-center">
            <div>
                <p>
                    <strong>Local Agents. Global Experts.</strong>
                </p>
                <p>Our travel agents are respected worldwide by a massive network of cruise, resort and tour companies, giving us special advantages to making your vacation truly unique.</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-10 md:max-w-5xl mx-auto">

                <div className="text-center">
                    <h3 className="tl-cyan text-4xl font-extrabold">
                        {totalYearsinBusiess}
                    </h3>
                    <p className="my-0">Years Serving the Travel Industry</p>
                </div>

                <div className="text-center">
                    <h3 className="tl-cyan text-4xl font-extrabold">
                        {totalAgentCount.toLocaleString()}
                    </h3>
                    <p className="my-0">Travel Experts In North America</p>
                </div>

                <div className="text-center">
                    <h3 className="tl-cyan text-4xl font-extrabold">
                        {totalAwards}
                    </h3>
                    <p className="my-0">Industry Awards Since 2009</p>
                </div>

                <div className="text-center">
                    <h3 className="tl-cyan text-4xl font-extrabold">
                        {totalPartnerships}
                    </h3>
                    <p className="my-0">Global Partnerships</p>
                </div>
            </div>
        </div>
    );

    return (
        <div className="-mt-4 md:mt-0">
            <div className={`bg-footer-slate pt-14 pb-8 px-4 text-white text-center ${displayStats}`}>
                <h2 className="uppercase text-3xl md:text-4xl">Top-rated travel agents</h2>
                <p className="md:max-w-5xl mx-auto pb-10 text-base md:text-lg leading-7">Connect with one of our professional travel agents — some of the best-reviewed agents in the nation — to discover how their expertise can truly enhance your journey. </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:mb-20 md:max-w-5xl mx-auto">
                    <div>
                        <div className="text-6xl font-medium">
                            {`${averageRating}/5`}
                            <i className="far fa-star" aria-hidden="true" />
                        </div>
                        <p className="font-normal">Average Agent Rating by Past Clients</p>
                    </div>
                    <div>
                        <div className="text-6xl font-medium">
                            {`${reccomended} %`}
                        </div>
                        <p className="font-normal">Recommended by Past Clients</p>
                    </div>
                    <div>
                        <div className="text-6xl font-medium">
                            {`${positiveReviews}+`}
                        </div>
                        <p className="font-normal">Positive Reviews for Our Agents</p>
                    </div>
                </div>
            </div>
            <div className={displayValueProp}>
                <section className="justify-center -mt-6 mb-10 hidden-tw md:flex">
                    <div className={`flex flex-row justify-center gap-20  text-xl uppercase rounded-full shadow-xl ${barColor} py-4 ${inactiveColor} relative`}>
                        <div className="pl-8 cursor-pointer" onClick={() => setSelected(1)}>
                            <div role="button" className={selected === 1 ? 'tl-blue-dark bg-white shadow-xl rounded-full relative py-4 px-8 -mt-4 -mb-4  font-semibold -ml-10' : ''}>How does this work?</div>
                        </div>
                        <div className="cursor-pointer" onClick={() => setSelected(2)}>
                            <div role="button" className={selected === 2 ? 'tl-blue-dark bg-white shadow-xl rounded-full relative py-4 px-8 -mt-4 -mb-4  font-semibold ' : ''}>Why use a travel agent?</div>
                        </div>
                        <div className="cursor-pointer" onClick={() => setSelected(3)}>
                            <div role="button" className={selected === 3 ? 'tl-blue-dark bg-white shadow-xl rounded-full relative py-4 px-8 -mt-4 -mb-4  font-semibold' : 'mr-10'}>Why use Travel Leaders?</div>
                        </div>
                    </div>
                </section>

                <section className="justify-center md:mb-20 bg-footer-slate relative md:hidden px-6 py-4">
                    <Accordion allowZeroExpanded preExpanded={['a']} className="Why">
                        <AccordionItem uuid="a" className="border-0">
                            <AccordionItemHeading>
                                <AccordionItemButton className={`uppercase rounded-full shadow-xl ${barColor} py-4 max-w-7xl ${inactiveColor} text-center my-4`}>
                                    How does this work?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="rounded bg-white px-2">
                                {text1()}
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem uuid="b" className="border-0">
                            <AccordionItemHeading>
                                <AccordionItemButton className={`uppercase rounded-full shadow-xl ${barColor} py-4 max-w-7xl ${inactiveColor} text-center my-4`}>
                                    Why use a Travel agent?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="rounded bg-white px-2">
                                {text2()}
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem uuid="c" className="border-0">
                            <AccordionItemHeading>
                                <AccordionItemButton className={`uppercase rounded-full shadow-xl ${barColor} py-4 max-w-7xl ${inactiveColor} text-center my-4`}>
                                    Why use TravelLeaders.com?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="rounded bg-white px-2">
                                {text3()}
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>
                </section>

                <div className="text-lg justify-center md:flex mx-4 md:mx-20 hidden-tw md:block">
                    {selected === 1 && (
                        <div>
                            {text1()}
                        </div>
                    )}
                    {selected === 2 && (
                        <div>
                            {text2()}
                        </div>
                    )}
                    {selected === 3 && (
                        <div>
                            {' '}
                            {text3()}
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}

WhyComponent.propTypes = {
    displayStats: PropTypes.string,
    displayValueProp: PropTypes.string,
    barColor: PropTypes.string,
    inactiveColor: PropTypes.string,
};

WhyComponent.defaultProps = {
    displayStats: 'block',
    displayValueProp: 'block',
    barColor: 'bg-light-blue-bar',
    inactiveColor: 'tl-cyan',
};

export default WhyComponent;
