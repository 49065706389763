import React from 'react';
import PropTypes from 'prop-types';

function Hero({
    title,
    imageFile,
    useAbsouluteImageUrl,
    additionalCSS,
    textCSS,
    height,
    imageCSS,
}) {
    return (
        <div className={`text-4xl flex flex-col justify-center text-center ${additionalCSS}`} style={{ height }}>
            <div
                className={`relative flex flex-col items-center justify-center min-h-full bg-cover bg-center bg-no-repeat ${imageCSS}`}
                style={useAbsouluteImageUrl === true ? { backgroundImage: `url('${imageFile}')` } : { backgroundImage: `url('/images/${imageFile}')` }}
            >
                <div className="absolute inset-0 w-full h-full bg-black bg-opacity-10" />
                    <div className="container relative flex flex-col px-4 md:px-40">
                        <h1 className={`relative z-20 text-2xl md:text-5xl font-bold leading-tight text-white uppercase mt-20 ${textCSS}`}>
                            {title}
                        </h1>
                    </div>
            </div>
        </div>
    );
}

Hero.propTypes = {
    title: PropTypes.string,
    imageFile: PropTypes.string.isRequired,
    useAbsouluteImageUrl: PropTypes.bool,
    additionalCSS: PropTypes.string,
    textCSS: PropTypes.string,
    height: PropTypes.string,
    imageCSS: PropTypes.string,
};

Hero.defaultProps = {
    title: '',
    useAbsouluteImageUrl: false,
    additionalCSS: 'pt-20',
    textCSS: 'text-shadow',
    height: '600px',
    imageCSS: '-mt-44',
};

export default Hero;
