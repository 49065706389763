/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import API from '../../services/API';

import 'react-slidedown/lib/slidedown.css';
import { label } from '../../utils';

function Language({
    destination, city, interest, state, language, metroRegion, zip,
    supplier,
    callback,
    trigger,
}) {
    const type = 'FacetedLanguages';
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState([]);
    const [showFull, setShowFull] = useState(false);

    const fetch = (agent) => {
        setIsFetching(true);
        API.fetchFaceted({
            destination: destination || '',
            city: city || '',
            interest: interest || '',
            state: state || '',
            language: language || '',
            metroRegion: metroRegion || '',
            zip: zip || '',
            supplier: supplier || '',
            type,
        }).then((response) => {
            if (!response || response.error) { setData([]); } else setData(response);
            setIsFetching(false);
        });
    };

    useEffect(() => {
        fetch();
    }, [destination, interest, state, language, supplier, metroRegion, zip]);

    useEffect(() => {
        if (language) { trigger(); }
    }, [language]);

    return (
        <>
            {data.length > 0 && (
                <div className="py-2 pb-4 border-b border-blue-300">

                    <div>{label('Agent language')}</div>

                    <div className="text-4xl flex flex-col">
                        <div className="flex flex-col items-start ">
                            <SlideDown className="my-dropdown-slidedown">
                                <div className="flex flex-col items-start ">
                                    {data.slice(0, showFull ? data.length : 5).map((x) => (
                                        <div
                                            key={x.language}
                                            onClick={() => callback(x.language)}
                                            className={['text-xs mb-2 cursor-pointer ', x.language === language ? 'text-gray-400' : 'hover:text-green-light'].join(' ')}
                                        >
                                            {x.language}
                                        </div>
                                    ))}
                                </div>
                            </SlideDown>
                            {data.length > 5
                                && (
                                    <div className="text-xs mb-2 cursor-pointer font-semibold hover:text-green-light mt-2" onClick={() => setShowFull(!showFull)}>
                                        {!showFull ? (
                                            <div>
                                                More Languages
                                                {` (${data.length - 5})`}
                                            </div>
                                        ) : <div>Close</div>}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Language;
