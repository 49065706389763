/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import React, {
    useEffect, useState,
} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import Loading from '../components/elements/Loading';
import StarRatings from 'react-star-ratings';
import { IoClose } from 'react-icons/io5';
import {
    callMe, CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE, modalCustomStyles,
} from '../utils';
import useAppStore from '../contexts/useAppStore';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';
import PromoListTrendingDest from '../components/PromoListTrendingDest';
import API from '../services/API';
import EmailForm from '../components/EmailForm';
import PhoneForm from '../components/PhoneForm';
import ButtonInverted from '../components/elements/ButtonInverted';

function InspirationCategory() {
    const [loading, setLoading] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [category, setCategory] = useState(query.get('category') || '');
    const myIp = query.get('ip') || null;

    const [title, setTitle] = useState();

    const [agentData, setAgentData] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState();
    const [links, setLinks] = useState([]);

    function renderSwitch(param, item) {
        let regionType1;
        let regionType2;
        let url1;
        let url2;
        let regionTitle;
        let regionIds;

        switch (param) {
            case 'oceania':
                regionTitle = 'Australia & Oceania';
                regionIds = '62223,106339';
                regionType1 = 'Australia';
                regionType2 = 'Oceania';
                url1 = '/agents?destinationId=62223&slctDestination=Australia+%26+New+Zealand';
                url2 = '/agents?destinationId=62223&slctDestination=South%20Pacific';
                break;
            case 'europe':
                regionTitle = 'Europe';
                regionIds = '62227,62230,900022';
                regionType1 = 'Europe';
                regionType2 = '';
                url1 = '/agents?destinationId=62227&slctDestination=Europe';
                break;
            case 'americas':
                regionTitle = 'Americas & The Caribbean';
                regionIds = '62229,62231,106335,106337,106338,62224';
                regionType1 = 'Americas';
                regionType2 = 'Caribbean';
                url1 = '/agents?destinationId=62229&slctDestination=USA';
                break;
            case 'asia':
                regionTitle = 'Asia & The Middle East';
                regionIds = '62226,62228,107508,900023';
                regionType1 = 'Asia';
                regionType2 = 'Middle East';
                url1 = '/agents?destinationId=900023&slctDestination=Asia';
                break;
            default:
                promoTitle1 = '';
                promoTitle2 = '';
                url1 = '';
                url2 = '';
                break;
        }
        if (item === 'region1') {
            return regionType1;
        }
        if (item === 'region2') {
            return regionType2;
        }
        if (item === 'url1') {
            return url1;
        }
        if (item === 'url2') {
            return url2;
        }
        if (item === 'trending') {
            return regionTitle;
        }
        if (item === 'regionId') {
            return regionIds;
        }
        return '';
    }

    const returnAgents = 3;

    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal,
    } = useAppStore();

    const userLocationCookie = new Cookies();
    const getMatchedAgentsUserLocation = async () => {
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*GET CLOSEST AGENTS*/
                API.agentMatchToInterest({
                    id: 0,
                    agencyId: 0,
                    destination: renderSwitch(category, 'regionId'),
                    interest: 0,
                    supplier: 0,
                    latitude: ipResult.data.latitude,
                    longitude: ipResult.data.longitude,
                    returnAgents: 3,
                    locality: ipResult.data.country_code,
                    promotionId: 0,
                }).then(async (agentResult) => {
                    if (agentResult.responseStatus === 1) {
                        setAgentData(agentResult.data);
                    } else {
                        toast(agentResult.error.message);
                    }
                });
            } else {
                toast(ipResult.error.message);
            }
        });
    };

    const getMatchedAgents = async () => {
        API.agentMatchToInterest({
            id: 0,
            agencyId: 0,
            destination: renderSwitch(category, 'regionId'),
            interest: 0,
            supplier: 0,
            latitude: userLocationCookie.get('userLocation').latitude,
            longitude: userLocationCookie.get('userLocation').longitude,
            returnAgents: 3,
            locality: userLocationCookie.get('userLocation').country_code,
            promotionId: 0,
        }).then(async (result) => {
            if (result.responseStatus === 1) {
                setAgentData(result.data);
            } else {
                toast(result.error.message);
            }
        });
    };

    useEffect(() => {
        if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
            getMatchedAgentsUserLocation();
        } else if ((userLocationCookie.get('userLocation')) && (myIp === null)) {
            getMatchedAgents();
        }
    }, []);

    const emailMe = (agent) => {
        setSelectedAgent(agent);
        setIsEmailFormModalOpen(true);
    };

    const closeModal = () => {
        closeFormModal();
    };


    useEffect(() => {
        if (category) {
            setTitle(renderSwitch(category, 'trending'));
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Inspiration',
                url: '/Inspiration',
            },
            {
                label: renderSwitch(category, 'trending'),
            },
            ]);
        }
    }, [category]);

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title>
                    {renderSwitch(category, 'trending')}
                    {' '}
                    Travel Agent Promotions  | Travel Leaders
                </title>
                <meta name="title" content={`View all ${renderSwitch(category, 'trending')} Travel Agent Promotions  | Travel Leaders`} />
                <meta
                    name="description"
                    content={`See a listing of all  ${renderSwitch(category, 'trending')} promotions currently available from Travel Leaders.`}
                />
            </Helmet>

            <div className="min-h-screen md:px-10">
                <div className="flex flex-col gap-10 ">
                    <div className="ml-2  pt-12">
                        <BreadCrumb links={links} />
                    </div>
                    <div className="">
                        {category && (
                            <PromoListTrendingDest
                                promoType="Featured Destination"
                                promoTitle={renderSwitch(category, 'region1')}
                                url={renderSwitch(category, 'url1')}
                                regionType={renderSwitch(category, 'region1')}
                            />
                        )}
                    </div>

                    <div className="w-full py-16 px-4 bg-light-blue relative z-20">
                        <div className="mx-4 md:mx-40 pb-10 text-center">
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">
                                Connect with an
                                {' '}
                                {renderSwitch(category, 'trending')}
                                {' '}
                                Travel Specialist Today!
                            </h2>
                        </div>
                        <div className={`grid grid-cols-1 md:grid-cols-${returnAgents} gap-4 mx-4 lg:mx-40`}>
                            {agentData.map((featAgent, index) => (
                                <div
                                    className="flex flex-col bg-white shadow-lg p-4 rounded text-center"
                                    key={featAgent.agentId}
                                >
                                    <div className="flex-grow">
                                        <div className="mx-auto w-36 h-36 overflow-hidden object-cover border rounded-lg">
                                            <Link to={`/agent/${featAgent.agentId}`} className="hover:opacity-75">
                                                <img
                                                    src={featAgent.agentPhotoFileName}
                                                    alt={`${featAgent.firstName} ${featAgent.lastName}`}
                                                    className="min-w-full"
                                                    loading="lazy"
                                                />
                                            </Link>
                                        </div>
                                        <div className="w-full text-xl tl-red font-semibold mt-2 mb-1 uppercase">
                                            {featAgent.firstName}
                                            {' '}
                                            {featAgent.lastName}
                                        </div>
                                        <div className="w-full text-sm text-gray-500 mb-1">
                                            {featAgent.city}
                                            {', '}
                                            {featAgent.state}
                                        </div>
                                        <div className="w-full text-sm mb-1">
                                            {featAgent.websiteDisplayTitle}
                                        </div>
                                        <div className="mb-1 h-8">
                                            {featAgent.agentRating.rating > 0
                                                && (
                                                    <StarRatings
                                                        rating={featAgent.agentRating.rating}
                                                        starRatedColor="#0A7790"
                                                        numberOfStars={5}
                                                        name="rating"
                                                        starDimension="28px"
                                                        starSpacing="4px"
                                                    />
                                                )}
                                        </div>
                                        <div className="mb-1 text-red uppercase">
                                            <Link to={`/agent/${featAgent.agentId}`} className="tl-red uppercase text-xs text-semibold">See full bio &gt;</Link>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 gap-4">
                                        {featAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                            <div className="col-span-1">
                                                <ButtonInverted
                                                    title="EMAIL ME"
                                                    onClick={() => emailMe(featAgent)}
                                                />
                                            </div>
                                        )}
                                        {featAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                            <div className="col-span-1">
                                                <ButtonInverted
                                                    title="CALL ME"
                                                    onClick={() => callMe(featAgent, () => {
                                                        setSelectedAgent(featAgent);
                                                        setIsPhoneFormModalOpen(true);
                                                    })}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="">
                        {renderSwitch(category, 'region2') !== '' && category && (
                            <PromoListTrendingDest
                                promoType="Featured Destination"
                                promoTitle={renderSwitch(category, 'region2')}
                                url={renderSwitch(category, 'url2')}
                                regionType={renderSwitch(category, 'region2')}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agent={selectedAgent} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agent={selectedAgent}
                            phone={selectedAgent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}
                </div>
            </Modal>
            {loading && <Loading />}
        </>
    );
}

export default InspirationCategory;
