/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

function BreadCrumb({ links, inverted }) {
    const history = useHistory();

    return (
        <div className="flex flex-row gap-0 md:gap-2 w-full my-2 z-20">
            {links.map((link, index) => (
                <div className="flex flex-row gap-1 items-center drop-shadow-2xl font-semibold" key={link.label}>
                    {index > 0 && (
                        <div>
                        <IoChevronForward className={['text-right text-xs', inverted ? 'text-white' : 'text-gray-700 '].join(' ')} />
                        </div>
                    )}
                    <div className={['text-xs', index + 1 === links.length ? '' : 'cursor-pointer hover:text-red-500', inverted ? 'text-white' : 'text-gray-700 '].join(' ')} onClick={() => ((index + 1 === links.length) ? {} : history.push(link.url))}>
                        {link.label}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default BreadCrumb;
