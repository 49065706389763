import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import useAppStore from '../contexts/useAppStore';
import AdsList from '../components/HomeAds';
import ArticlesList from '../components/ArticlesList';
import WhyComponent from '../components/WhyComponent';
import HomeAgents from '../components/HomeAgents';
import FeaturedSuppliers from '../components/FeaturedSuppliers';
import PromoList from '../components/PromoList';

function Home() {
    const { isFetchingAgent } = useAppStore();
    const InterestNavigation = [
        {
            name: 'Destination Weddings',
            link: '/agents?slctInterest=Destination+Weddings',
        },
        {
            name: 'All-Inclusive Packages',
            link: '/agents?slctInterest=All+Inclusive',
        },
        {
            name: 'Family Travel',
            link: '/agents?slctInterest=Family+Vacations',
        },
        {
            name: 'River Cruises',
            link: '/agents?slctInterest=River+Cruises',
        },
        {
            name: 'Caribbean Vacations',
            link: '/agents?slctDestination=Caribbean&destinationId=62224',
        },
        {
            name: 'Honeymoon Planning',
            link: '/agents?slctInterest=Honeymoons',
        },
        {
            name: 'European Tours',
            link: '/agents?slctDestination=Europe&destinationId=62227',
        },
        {
            name: 'Disney & Theme Parks',
            link: '/agents?slctInterest=Theme+Parks',
        },
        {
            name: 'Couples & Romance',
            link: '/agents?slctInterest=Couples+%26+Romance',
        },
        {
            name: 'Safaris & Animal Travel',
            link: '/agents?slctInterest=Safari',
        },
    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

        const heroImages = [
            'header1-italy.webp',
            'header2-greece.webp',
            'header3-phillines.webp',
            'header4-denmark.webp',
            'header5-hawaii.webp',
            'header6-africa.webp',
            'header7-mountains.webp',
            'header8-maldives.webp',
        ];
        const [randomHeroImage, setRandomHeroImage] = useState('');

    const pickRandomHeroImage = () => {
        const randomIndex = Math.floor(Math.random() * heroImages.length);
        const randomImage = heroImages[randomIndex];
        setRandomHeroImage(randomImage);
    };

    function heroImage() {
        let heroProps = { imageHeight: '260px', imageCSS: '-mt-8' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '600px', imageCSS: '-mt-44' };
        }
        return heroProps;
    }
    useEffect(() => {
        pickRandomHeroImage();
    }, []);

    return (
        <>
            <Helmet>
                <title>#1 Travel Agent Network in North America | Travel Leaders</title>
                <meta name="title" content="#1 Travel Agent Network in North America | Travel Leaders " />
                <meta name="description" content="Travel Leaders provides personalized travel packages, deals and services for leisure and business travel via knowledgeable and professional travel agents and experts." />
            </Helmet>
            <Hero
                title="Travel Better with North America's #1 Travel Agent Network"
                imageFile={randomHeroImage}
                height={heroImage().imageHeight}
                imageCSS={heroImage().imageCSS}
                textCSS="text-shadow-xl"
            />
            <div className="max-w-screen-2xl mx-auto">

                <div className="min-h-screen">

                    <div className="-mt-4 md:-mt-44 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                        <SearchBox />
                    </div>

                    <div className="mx-4 md:mx-20 pt-20 text-center">
                        <h2 className="tl-cyan uppercase text-3xl md:text-4xl tracking-wide">Get inspired to travel</h2>
                        <div>
                            <h3 className="uppercase text-lg md:text-xl mb-2 font-semibold tracking-wider tl-black-900 mt-8">Travel Better Blog</h3>
                            <p className="md:mx-48 mb-10 text-base md:text-lg leading-7 tl-black-900">
                                Read our blog posts about firsthand experiences at amazing destinations around the globe. Learn valuable travel tips that can enhance your journey. Then contact our travel professionals to turn these articles and your dreams into reality.
                            </p>
                        </div>
                        <ArticlesList
                            ArticlesCategory="recent"
                            ArticlesReturned="4"
                            ArticlesDisplayTitle={false}
                        />
                    </div>

                    <div className="mx-4 md:mx-20 pt-8 text-center">
                        <div>
                            <h3 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900">Popular Travel Destinations & Types</h3>
                            <p className="md:mx-48 mb-10 text-lg leading-7 tl-black-900">
                                Browse the topics below to find travel agents who are experts on that destination or travel style.
                            </p>
                        </div>
                        <AdsList />
                    </div>

                    <div className="mx-4 md:mx-20 pt-8 text-center">
                        <h3 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900">Our Expert Travel Advisors can help you plan the following types of travel</h3>
                        <p className="md:mx-48 mb-10 text-lg leading-7 tl-black-900">
                            To see all travel types our experts specialize in, explore our
                            {' '}
                            <Link to="/find_travel_agent" className="tl-red hover:text-black underline">Find a Travel Agent</Link>
                            {' '}
                            section.
                        </p>
                        <div className="grid overflow-hidden grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 grid-rows-2 gap-2 mt-6">
                            {InterestNavigation.map((x, index) => (
                                <div className="p-2" key={x.name}>
                                    <a href={x.link} className="block text-base font-bold tl-black-900 hover:text-red-600 bg-white transition-colors duration-500 ease-in-out hover:bg-gray-100 hover:shadow py-4 border border-solid border-gray-200 rounded">{x.name}</a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mt-16">
                    <WhyComponent />
                </div>
                <div className="">
                    <HomeAgents />
                </div>
                <div>
                    <FeaturedSuppliers />
                </div>
                <div className="">
                    <PromoList
                        promoType="Featured Home"
                        promoTitle="Travel Specials"
                        introTxt="Our agents have access to special offers from top travel suppliers around the world. Explore one of the options below to learn more."
                    />
                </div>
            </div>
        </>
    );
}

export default Home;
