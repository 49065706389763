/* eslint-disable react/prop-types */
import React from 'react';
import { wrapperClass } from '../utils';

function AgentTestimonials({ agent }) {
    const {
        agentTestimonials,
    } = agent;
    return (
        <div
            id="Testimonials"
            className={[wrapperClass, ''].join(' bg-light-blue ')}
        >
            <h2 className="tl-cyan uppercase text-4xl tracking-wide px-4">Client Testimonials</h2>
            <div className="flex flex-col gap-4 mt-10 px-4 md:px-20">
                {agentTestimonials.map((x) => (
                    <div className="flex flex-col gap-6 pb-8" key={x.testimonialTitle}>
                        <div className="text-base font-bold">{x.testimonialTitle}</div>
                        <div className="">{x.testimonialText}</div>
                        <div className="text-base font-bold self-end">{x.testimonialCredit}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AgentTestimonials;
