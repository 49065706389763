import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Link } from 'react-router-dom';
import BreadCrumb from '../components/BreadCrumb';
import Hero from '../components/Hero';

function ThankYou() {
    const history = useHistory();

    let user = 'email';
    if (sessionStorage.getItem('user')) {
        user = sessionStorage.getItem('user');
        sessionStorage.removeItem('user');
    }

    const links = [{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Thank You',
    },
    ];
    return (
        <div className=" max-w-screen-2xl mx-auto">
            <Helmet>
                <title>Contact Us | Travel Leaders</title>
                <meta name="title" content="Thank you for contacting us | Travel Leaders" />
                <meta name="description" content="Contact Travel Leaders " />
                <meta
                    name="ROBOTS"
                    content="NOINDEX, NOFOLLOW"
                />
            </Helmet>
            <div className="bg-white min-h-screen">

                <Hero title="Thanks for contacting us" imageFile="hero-thanks.webp" />
                <div className="flex flex-col max-w-5xl mx-auto  text-lg -mt-12 pb-10 border-b-2 border-black px-4 md:px-0">
                    <div className="mb-6">
                        <BreadCrumb links={links} />
                    </div>
                    <div className="font-medium">Dear Customer</div>
                    <div>Thank you for your inquiry.</div>
                    <div className="mt-10">
                        {`I’m excited to learn more about you to help make your travel dreams come true. You can expect to hear back from me via ${user} `}
                        <b>within 24 hours </b>
                        to get started! In the meantime, check out the link below for more travel inspiration.
                    </div>
                </div>
                <div className="flex mx-4 md:mx-20  md:max-w-7xl mx-auto text-center">
                    <div className="flex flex-col md:flex-row justify-evenly w-full py-4 mx-4 gap-10">
                        <div className="flex flex-col gap-4 flex-1 items-center">
                            <img alt="" src="https://www.travelleaders.com/images/thankyoucirc-1.jpg" className="rounded-full w-64 border-4 border-gray-300" loading="lazy" />
                            <div className="cursor-pointer text-xl md:text-2xl font-semibold tl-red hover:text-black" onClick={() => history.push('/about_us')}>GLOBAL DESTINATION EXPERTS</div>
                            <div className=" text-base md:text-lg">Travelleaders.com agents are certified specialists with world-class professional training and extensive world travel experience. Our specialized knowledge provides us insight, firsthand experience and insider connections you can’t find elsewhere.</div>
                        </div>
                        <div className="flex flex-col gap-4 flex-1 items-center">
                            <img alt="" src="https://www.travelleaders.com/images/thankyoucirc-2.jpg" className="rounded-full w-64 border-4 border-gray-300" loading="lazy" />
                            <div className="cursor-pointer text-xl md:text-2xl font-semibold tl-red hover:text-black" onClick={() => history.push('/about_us')}>A PASSION FOR TRAVEL</div>
                            <div className="text-base md:text-lg">
                                From all-inclusive resorts to cruises, tours and more, we are constantly scouring the globe to find the best travel experiences, hot deals and travel tips.
                                    <Link to="/travelbetter" className="cursor-pointer tl-red hover:text-black block">
                                        Explore our blog TravelBetter to learn more!
                                    </Link>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 flex-1 items-center">
                            <img alt="" src="https://www.travelleaders.com/images/thankyoucirc-3.jpg" className="rounded-full w-64 border-4 border-gray-300" loading="lazy" />
                            <div className="cursor-pointer text-xl md:text-2xl font-semibold tl-red hover:text-black" onClick={() => history.push('/about_us')}>AWARD-WINNING SERVICE</div>
                            <div className="text-base md:text-lg">Travelleaders.com has been honored with over 68 prestigious travel industry awards — more than any other travel agency company in North America.</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ThankYou;
