/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import Button from '../components/elements/Button';
import API from '../services/API';

function ContactUs() {
    const [isProcessing, setIsProcessing] = useState(false);
    const history = useHistory();

    const {
        register, handleSubmit, watch, formState: { errors },
        setValue,
    } = useForm();
    const onSubmit = (data) => {
        setIsProcessing(true);
        API.sendEmailUtility(data).then((response) => {
            history.push('/thankYou');
            setIsProcessing(false);
        });
    };


    return (
        <>
            <Helmet>
                <title>Contact Us | Travel Leaders</title>
                <meta name="title" content="Contact Us | Travel Leaders" />
                <meta name="description" content="Contact Travel Leaders " />
            </Helmet>
            <Hero title="Contact Us" imageFile="hero-contact-us.webp?ver=1" />
            <div className=" max-w-screen-2xl mx-auto">
                <div className="">

                    <div className="-mt-44 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                        <SearchBox />
                    </div>
                </div>
                <div className="min-h-screen">
                    <div className="flex flex-col-reverse md:flex-row mx-4 md:mx-20 py-10 text-center">
                        <div className="w-full md:w-1/2 text-left p-4">
                            <div className="leading-tight">
                                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                                    <div className="flex flex-col">
                                        <div>
                                            <label className="text-gray-700 pb-2 pl-2 italic font-semi-bold block">Full Name</label>
                                        </div>
                                        <div>
                                            <input placeholder="Full Name" className="border border-gray-400 rounded-full py-2 px-4 w-full" {...register('fullName', { required: true })} />
                                        </div>
                                        <div>
                                            {errors.fullName && <span className="text-red-500 text-sm">Full name is required</span>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div>
                                            <label className="text-gray-700 pb-2 pl-2 italic font-semi-bold block">Email</label>
                                        </div>
                                        <div>
                                            <input placeholder="Email Address" type="email" className="border border-gray-400 rounded-full py-2 px-4 w-full" {...register('fromEmail', { required: true, pattern: /^\S+@\S+$/i })} />
                                        </div>
                                        <div>
                                            {errors.fromEmail && <span className="text-red-500 text-sm">Valid Email is required</span>}
                                        </div>
                                    </div>

                                    <div className="flex flex-col">
                                        <div>
                                            <label className="text-gray-700 pb-2 pl-2 italic font-semi-bold block">Email Topic</label>
                                        </div>
                                        <div>
                                            <select className="border border-gray-400 bg-white rounded-full py-2 px-4 w-full" {...register('toEmail', { required: true })}>
                                                <option value="">Select</option>
                                                <option value="media">Media Inquiries</option>
                                                <option value="franchise">Franchise Opportunities</option>
                                                <option value="tech">Technical Issues</option>
                                                <option value="default">Other</option>
                                            </select>
                                        </div>
                                        {errors.toEmail && <span className="text-red-500 text-sm">Please select an option that best suits your question</span>}
                                    </div>

                                    <div className="flex flex-col">
                                        <div>
                                            <label className="text-gray-700 pb-2 pl-2 italic font-semi-bold block">Your questions / comments</label>
                                        </div>
                                        <div>
                                            <textarea placeholder="Message" className="border border-gray-400 rounded-xl py-2 px-4 w-full h-48" {...register('comments', { required: true, minLength: 15 })} />
                                            {errors.comments && <span className="text-red-500 text-sm">Question/Comment is required</span>}

                                        </div>
                                    </div>

                                    <div className="my-4">
                                        <button
                                            disabled={isProcessing}
                                            type="submit"
                                            className={[' rounded-full py-2 px-10 text-white text-sm w-full',
                                                isProcessing ? 'bg-gray-300' : 'bg-red-600'].join(' ')}
                                        >
                                            {
                                                isProcessing ? 'Sending email, one moment'
                                                    : 'Submit'
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 text-left p-4">
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">GENERAL INQUIRES</h2>
                            <p className="mb-10">For media inquiries, information on franchise opportunities or technical issues with the website, please contact us using the form below. For travel inquiries, please contact one of our agencies directly.</p>
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">LEISURE TRAVEL INQUIRIES</h2>
                            <p className="mb-10">We would love the opportunity to share our knowledge and expertise with you on your next journey. We are passionate about helping you travel better with us. Please call 800-335-TRIP (8747) or visit our agency locator.</p>
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">BUSINESS TRAVEL INQUIRIES</h2>
                            <p className="mb-10">We look forward to helping you with your business travel management needs. You can visit TravelLeadersBusiness.com or simply call us at 800-819-4000.</p>
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">OUR MAILING ADDRESS</h2>
                            <p className="mb-10">
                                Travel Leaders
                                <br />
                                3033 Campus Drive, Suite W320
                                <br />
                                Plymouth, MN 55441
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row pt-10 pb-32 text-center mx-auto gap-20 bg-light-blue  px-4 md:px-10">
                        <div className="w-full md:w-1/2 text-left">
                            <h3 className="uppercase font-bold text-lg mb-0 md:mb-8">ARE YOU AN AGENCY WHO WANTS TO BECOME A MEMBER?</h3>
                            <p>Our trusted partners help our travel agents provide you with above and beyond travel experiences. We have spent years building valuable relationships with our partners in the travel industry including cruise lines, tour operators, hotels, car rental companies and more.</p>
                            <Button
                                title="Learn More"
                                onClick={() => window.open('https://www.travelleadersnetwork.com/', '_blank')}
                                width="w-full md:w-64"
                            />
                        </div>
                        <div className="w-full md:w-1/2 text-left">
                            <h3 className="uppercase font-bold text-lg mb-0 md:mb-8">ARE YOU AN AGENT WHO WANTS TO JOIN AN AGENCY?</h3>
                            <p>Our trusted partners help our travel agents provide you with above and beyond travel experiences. We have spent years building valuable relationships with our partners in the travel industry including cruise lines, tour operators, hotels, car rental companies and more.</p>
                            <Button
                                title="Learn More"
                                onClick={() => window.open('https://www.travelleadershosts.com/', '_blank')}
                                width="w-full md:w-64"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;
