import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import API from '../../services/API';

function LocationDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const [locale, setLocale] = useState();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const myIp = query.get('ip') || null;
    const userLocationCookie = new Cookies();
    //const menuItems = ['USA', 'CANADA'];
    const defaultClass = 'h-full flex justify-start items-center hover:text-red-600 hover:underline cursor-pointer';
    const menuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const setLocation = (newCountryCode) => {
        const cookieValue = userLocationCookie.get('userLocation');

        if (cookieValue) {
            // Update the country_code
            const updatedValue = { ...cookieValue, country_code: newCountryCode };
            userLocationCookie.set('userLocation', updatedValue, {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                path: '/',
                sameSite: 'lax',
            });

            // Reload the page
            window.location.reload();
        } else {
            setError('UserLocation cookie not found.');
        }
    };

    const getMatchedAgentsUserLocation = async () => {
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*GET CLOSEST AGENTS*/
                setLocale(ipResult.data.country_code);
            } else {
                toast(ipResult.error.message);
            }
        });
    };

    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
            getMatchedAgentsUserLocation();
        } else if ((userLocationCookie.get('userLocation')) && (myIp === null)) {
            setLocale(userLocationCookie.get('userLocation').country_code);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const imageSrc = locale === 'CA' ? '/images/ca-flag.png' : '/images/us-flag.png';
    const altText = locale === 'CA' ? 'Current Region selected is Canada' : 'Current Region selected is United States';
    const imgStyle = {
        width: '100%', height: '100%', maxHeight: '22px', maxWidth: '22px',
    };

    return (
        <div className="relative">
            <button
                type="button"
                className="ml-2 md:-ml-2 md:pt-1 flex"
                onClick={() => setIsOpen(!isOpen)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <img
                    className="w-6 h-6"
                    src={imageSrc}
                    alt={altText}
                    style={imgStyle}
                />
                <span className="inline-block ml-2 mt-1 text-xs md:hidden">
                    {locale === 'CA' ? 'Current Region is Canada' : 'Current region is USA'}
                </span>
            </button>
            {showTooltip && (
                <div className="hidden md:block md:absolute md:top-8 md:-left-16 text-white bg-gray-900 w-20 p-3 text-xs text-center">
                    {`Current Region is ${locale === 'CA' ? 'Canada' : 'USA'}`}
                </div>
            )}
            {isOpen && (
                <div ref={menuRef} id="menu" className="absolute w-40 md:w-32 h-30 bg-gray-100 z-50 top-0 left-8 md:top-8 md:-left-28 p-2">
                    <div className={`${defaultClass} border-b ${locale !== 'CA' ? 'font-bold text-red-700' : ''}`} onClick={() => setLocation('US')}>
                        <div className="pr-4 pb-1 flex-grow">
                            USA
                        </div>
                        <div className="">
                            <img
                                className="w-6"
                                src="/images/us-flag.png"
                                alt="United States"
                                style={imgStyle}
                            />
                        </div>
                    </div>
                    <div className={`${defaultClass} ${locale === 'CA' ? 'font-bold text-red-700' : ''}`} onClick={() => setLocation('CA')}>
                        <div className="pr-4 pt-1 flex-grow">
                            CANADA
                        </div>
                        <div className="">
                            <img
                                className="w-6"
                                src="/images/CA-flag.png"
                                alt="Canada"
                                style={imgStyle}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LocationDropdown;
