/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable no-confusing-arrow */

import React, {
    useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import API from '../services/API';
import Loading from './elements/Loading';
import { SUPPLIER_IMAGE_URL } from '../utils';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

function SupplierCategoryList({ SupplierType }) {
    const [loading, setLoading] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const history = useHistory();

    let supplier;
    if (SupplierType === 'resort') { supplier = 'resort collection partners'; } else { supplier = SupplierType; }

    const getSuppliers = () => {
        setLoading(true);
        API.fetchSuppliers({
            travelType: 'all',
        }).then((result) => {
            if (result.responseStatus === 1) {
                setSupplierData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getSuppliers();
    }, []);

    return (
        <>
            {supplierData.length > 0
                && (
                    <div className="mx-4 py-10 text-center">
                        <ToastContainer />
                        {!loading && (
                            <>
                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2">
                                    {supplierData.map((x) => (x.supplierType.toLowerCase() === supplier.toLowerCase()) ? (
                                        <div key={x.supplierId}>
                                            <div
                                                className="flex flex-col items-center cursor-pointer"
                                                onClick={() => history.push(`/agents?slctSupplier=${x.supplierName}`)}
                                            >
                                                <div className="rounded-full border shadow flex p-3 relative bg-white h-28 md:h-40 w-28 md:w-40 my-2 md:my-4 text-center">
                                                    {x.supplierLogo.includes('.png') ? (
                                                        <img
                                                            src={`${SUPPLIER_IMAGE_URL()}${x.supplierLogo}`}
                                                            className="object-contain block  hover:scale-105 transform ease-in-out duration-1000"
                                                            alt={x.supplierName}
                                                            loading="lazy"
                                                        />
                                                    ) : (
                                                            <div className="flex self-center">
                                                                {x.supplierName}
                                                                {' '}
                                                                Logo Coming Soon
                                                            </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (''))}
                                </div>

                            </>
                        )}

                    </div>
                )}
            {loading && <Loading />}
        </>
    );
}
SupplierCategoryList.propTypes = {
    SupplierType: PropTypes.string.isRequired,
};

export default SupplierCategoryList;
