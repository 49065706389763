/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../services/API';
import { getStateName } from '../../utils';

function RelatedMetrosByState({
    state,
    destination,
    destinationId,
}) {
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState([]);

    const fetch = (agent) => {
        setIsFetching(true);
        API.fetchRelatedMetrosByState({
            state: state || '',
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setIsFetching(false);
        });
    };

    useEffect(() => {
        fetch();
    }, [state]);


    return (
        <>
            {data.length > 0 && (
                <div className="py-2">
                    <ToastContainer />
                    <div className="text-xl font-bold">{`Top Metro Areas for ${destination}`}</div>
                    <div className="flex flex-col">
                        <div className="flex flex-col items-start text-base">
                            {data.map((x) => (
                                <div
                                    key={x.metroRegion}
                                    className="cursor-pointer font-normal"
                                >
                                    <Link to={`/agents?slctState=${x.metroState}&slctMetro=${encodeURIComponent(x.metroRegion)}&slctDestination=${encodeURIComponent(destination)}&destinationId=${destinationId}`}>
                                        {`${x.metroRegion} Travel Agents for ${destination}`}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default RelatedMetrosByState;
