/* eslint-disable prefer-template */
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ShowMoreText from 'react-show-more-text';
import Hero from '../components/Hero';
import WhyComponent from '../components/WhyComponent';
import ButtonInverted from '../components/elements/ButtonInverted';
import Button from '../components/elements/Button';
import useAppStore from '../contexts/useAppStore';

function AboutUs() {
    const {
        getAgentCount,
    } = useAppStore();

    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }

    const totalYearsinBusiess = (new Date().getFullYear() - 1947);
    const totalAgentCount = getAgentCount();
    const totalAwards = 65;
    const totalPartnerships = 300;
    return (
        <>
            <Helmet>
                <title>About Travel Leaders</title>
                <meta name="title" content="About Travel Leaders" />
                <meta name="description" content="Learn about the company, the leadership team, and history of Travel Leaders" />
            </Helmet>
            <Hero title="Local Agents. Global Experts." imageFile="hero-about.webp" additionalCSS="pt-0" />
            <div className=" max-w-screen-2xl mx-auto">
                <div className="min-h-screen">
                    <div className="-mt-44 relative left-0 md:mx-20 right-0 z-10">
                        <div className="bg-white md:shadow-xl rounded px-8 py-6 z-40 text-center">
                            <div className="mb-6 text-lg">
                                Our travel agents are respected worldwide by a massive network of cruise, resort and tour companies, giving us special advantages to making your vacation truly unique.
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 sm:gap-8 justify-between mx-4 md:mx-40">
                                <div className="flex flex-col justify-center flex-1 uppercase">
                                    <ButtonInverted
                                        title="Company History"
                                        onClick={() => goToAnchor('ahistory')}
                                        width="uppercase"
                                    />
                                </div>
                                <div className="flex flex-col justify-center flex-1">
                                    <ButtonInverted
                                        title="Leadership"
                                        onClick={() => goToAnchor('leaders')}
                                        width="uppercase"
                                    />
                                </div>
                                <div className="flex flex-col justify-center flex-1">
                                    <ButtonInverted
                                        title="News Releases"
                                        onClick={() => goToAnchor('news')}
                                        width="uppercase"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid overflow-hidden grid-cols-1 md:grid-cols-2 grid-rows-none gap-8 mx-4 md:mx-20 my-8">
                        <div id="col1">
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide text-center md:text-left">Travel better with a personalized travel experience</h2>
                            <p>There are full-service Travel Leaders travel agencies located throughout North America to help you travel better on your next leisure vacation or business trip.</p>
                            <p>Travel Leaders travel agents will take the time to understand your unique travel interests and aspirations, until we “get you.” We then use our education, first-hand travel experience and relationships with airlines, car, hotel, resort, tour and cruise supplier partners to make sure you enjoy an exceptional personalized travel experience that will create memories to last a lifetime.</p>
                            <p>We are your personal travel advocate to help you with everything from getting you on the next flight, if your flight is canceled, to searching through all of the options to find you the right vacation package, cruise, shore excursions, restaurants and more. We will provide you with tips, advice and insights that only a trained and experienced travel professional can offer. We live and love travel and welcome the opportunity to show you how we can help you travel better.</p>
                            <p>Travel Leaders travel agents are experts who understand where you want to go, how to get you there and the priceless experiences you want to have. We get you and we get you there.</p>
                        </div>
                        <div id="col2">
                            <h3 className="text-xl uppercase text-black font-semibold">Our supplier partners</h3>
                            <p>Our trusted partners help our travel agents provide you with above and beyond travel experiences. We have spent years building valuable relationships with our partners in the travel industry including cruise lines, tour operators, hotels, car rental companies and more. Our partners are best-in-class, and take excellent care of you, our valued customer.</p>
                            <p>
                                <a href="/inspiration#partners" className="tl-red cursor-pointer font-semibold uppercase hover:text-black">
                                    See all our destination partners &nbsp;
                                    <i className="fa fa-caret-right" aria-hidden="true" />
                                </a>
                            </p>
                            <h3 className="text-xl uppercase text-black font-semibold">Our destination partners</h3>
                            <p>We have partnered with the tourism boards of some of the most exciting destinations across the world to offer you the best vacation values and experiences. From Alaska and Australia to Fiji and Florida, our travel agents have worked with our destination representatives to receive specialized training and certifications. They are eager to offer you their expertise to help you plan your dream vacation to any of our distinctive destinations.</p>
                            <h3 className="text-xl uppercase text-black font-semibold">Travel Leaders Network </h3>
                            <p>Travel Leaders Network is comprised of nearly 7,000 travel agency locations – more than one third of North America’s leading travel agencies. With over $17 billion in annual sales volume, Travel Leaders Network is the largest seller of luxury travel, cruises, river cruises and tours in the travel agency industry</p>
                        </div>
                    </div>

                    <div className="-mt-4 md:mt-0 bg-white mb-4">
                        <div className="bg-footer-slate w-full pt-10 pb-8 px-4 text-white text-center">
                            <h2 className="uppercase text-3xl">Top rated travel agents</h2>
                            <p className="md:max-w-3xl mx-auto pb-10">Connect with one of our professional Travel Agents — some of the best-reviewed agents in the nation — to discover how their expertise can truly enhance your journey. </p>
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-20 md:max-w-3xl mx-auto">
                                <div>
                                    <div className="text-6xl font-light">
                                        {totalYearsinBusiess}
                                    </div>
                                    <p className="capitalize font-normal">
                                        Years Serving the Travel Industry
                                    </p>
                                </div>
                                <div>
                                    <div className="text-6xl font-light">
                                        {totalAgentCount.toLocaleString()}
                                    </div>
                                    <p className="capitalize font-normal">Travel Experts Throughout the USA</p>
                                </div>
                                <div>
                                    <div className="text-6xl font-light">
                                        {totalAwards}
                                    </div>
                                    <p className="capitalize font-normal">Industry Awards Since 2009</p>
                                </div>
                                <div>
                                    <div className="text-6xl font-light">
                                        {totalPartnerships}
                                    </div>
                                    <p className="capitalize font-normal">Global Partnerships</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-4 md:mx-20 my-8">
                        <div id="ahistory" className="mb-16" />
                        <div><h2 className="tl-cyan uppercase text-4xl tracking-wide my-8 text-center md:text-left">Company History</h2></div>
                        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                            <div><img src="/images/co_history_bar1.png" className="mx-auto p2" alt="Opening our first office" loading="lazy" /></div>
                            <div><img src="/images/co_history_bar2.png" className="mx-auto p2" alt="Meeting with clients" loading="lazy" /></div>
                            <div><img src="/images/co_history_bar3.png" className="mx-auto p2" alt="Meeting with more clients" loading="lazy" /></div>
                            <div><img src="/images/co_history_bar4.png" className="mx-auto p2" alt="One of our Associate Locations" loading="lazy" /></div>
                            <div><img src="/images/co_history_office.jpg" className="mx-auto p2" alt="Our Plymouth, MN Location" loading="lazy" /></div>
                        </div>
                        <div className="my-4">
                            <h3 className="text-xl uppercase text-black font-semibold">TRAVEL LEADERS IS TRANSFORMING TRAVEL</h3>
                            <p>With a name that boldly embodies our commitment to our vacation and business travel clients via a progressive approach toward each unique travel experience. As one of North America’s Top 10 travel companies, Travel Leaders Group has assisted millions of travelers through our strong roots - the former Carlson Wagonlit Travel Associates, Travel Leaders and Tzell Travel Group joined together in 2008 to form one of the industry’s fastest-growing and robust networks of travel agents. Travel Leaders-branded franchise travel agencies can trace its beginnings to 1984 as the Carlson franchise system “Ask Mr. Foster” Travel, which itself was North America’s first travel agency chain established in 1888.</p>
                            <h3 className="text-xl uppercase text-black font-semibold">HIGHEST RANKED IN BUSINESS TRAVEL NEWS’ BUSINESS 2016 TRAVEL SURVEY</h3>
                            <p>In 2016, for the eighth year in a row, Travel Leaders Group was the highest ranking travel management company in Business Travel News’ annual compendium. Business Travel News is considered “the voice of authority for corporate travel buyers throughout North America for over 20 years.” The rankings are based on the reported volume of airline transactions and verified by the Airlines Reporting Corporation (ARC), which is the premier financial transaction processing and settlement corporation that facilitates the distribution of travel products and related information.</p>
                            <h3 className="text-xl uppercase text-black font-semibold">AMONG TOP AMERICAN TRAVEL SELLERS IN TRAVEL WEEKLY’S 2016 POWER LIST</h3>
                            <p>Travel Weekly placed Travel Leaders Group among the top North American travel companies in its 2016 Power List. Travel Weekly is billed as “the national newspaper of the travel industry.” The rankings are based on participating companies wholly-owned annual sales volume reported for 2015 and specifically do not include franchised operations. Travel Leaders Group wholly-owned operations reported annual sales volume totals in excess of $4 billion for 2015; when franchised and affiliated sales are included, that number increases dramatically to approximately $21 billion.</p>
                            <h3 className="text-xl uppercase text-black font-semibold">RANKED FIRST AMONG TRAVEL FRANCHISE BRANDS IN ENTREPRENEUR’S FRANCHISE 500</h3>
                            <p>Entrepreneur’s Franchise 500 2016 listing named a Travel Leaders Franchise Group brand as the top travel franchise brand for the 21st consecutive year. The rankings are largely based upon the overall number of locations each brand has.</p>
                            <h3 className="text-xl uppercase text-black font-semibold">TRAVEL LEADERS NETWORK HONORED WITH DISTINGUISHED MAGELLAN AWARDS IN 2016</h3>
                            <p>Travel Weekly’s illustrious awards honor “The Best in the Travel Industry.” Travel Weekly awarded Travel Leaders Network with three prestigious Magellan Awards in 2016. In the past five years alone, Travel Leaders Network has been honored with a total of 80 Magellan Awards for its industry-leading programs.</p>
                        </div>

                        <div id="leaders" className="mb-16" />
                        <div className="mt-8"><h2 className="tl-cyan uppercase text-4xl tracking-wide mb-4  text-center md:text-left">Leadership</h2></div>
                        <div className="md:flex space-x-2 my-12">
                            <div className="md:w-1/5">
                                <img src="/images/leadership_mbatt.jpg" alt="Michael Batt" className="mx-auto md:mx-0 mb-10 md-mb-0" loading="lazy" />
                            </div>
                            <div className="md:w-4/5">
                                <h3 className="text-xl uppercase text-black font-semibold">
                                    Michael Batt
                                </h3>
                                <h4 className="tl-cyan font-semibold">
                                    Founder & Chairman, Internova Travel Group
                                </h4>
                                <ShowMoreText
                                    lines={8}
                                    more="Read More ▼"
                                    less="Show less ▲"
                                    anchorClass="text-red-600 hover:text-red-900"
                                    expanded={false}
                                    truncatedEndingComponent="..."
                                >
                                    <p>Michael Batt is the Founder and Chairman of Internova Travel Group, a global leader providing travel services to corporate, leisure, luxury and entertainment markets. Today, Internova Travel Group comprises more than 4,000 employees, 6,000 locations and over 65,000 travel advisors in the U.S., Canada, the U.K., Mexico, France and a presence in more than 80 countries.</p>
                                    <p>As chairman of Internova Travel Group (formerly Travel Leaders Group), Mr. Batt provides strategic direction for the company and has overseen its growth from a collection of successful travel agencies to one of the leading forces in travel today. He has devoted his career to promoting the travel agency distribution channel and the use of travel advisors to enhance the travel experience.</p>
                                    <p>Mr. Batt previously served as an executive with Carlson Companies where he was President of Carlson Leisure Group (CLG) and a Board member of Carlson Wagonlit Travel. Under Mr. Batt, CLG grew significantly with sales growing from a combined $2 billion in 1996 to over $5 billion in 2007.</p>
                                    <p>In January 2008, Mr. Batt led a management buyout of Carlson Leisure Group, and, in April 2008, secured investment support and partnership with One Equity Partners – the private equity arm of JPMorgan Chase. During the past 11 years the company has completed many significant mergers and acquisitions with transactions of note including Tzell Travel Group, Protravel International, Altour, Bonotel Exclusive Travel, Barrhead Travel and Colletts Travel. Under Mr. Batt’s leadership, Travel Leaders Group has expanded through strategic and organic growth to become the travel powerhouse it is today.</p>
                                    <p>Before joining Carlson, Mr. Batt was Executive Vice President, Sales and Marketing for Alamo Rent-A-Car. He was responsible for worldwide sales and marketing for the car rental giant. Prior to Alamo, Mr. Batt served as Marketing Director (and member of the executive board) of British Airways where he was responsible for all advertising and promotion, product development and delivery of service standards. Mr. Batt started his business career working for M&M Mars, enjoying a 12-year tenure with the company in various sales and brand management positions working in the United Kingdom, Spain, Africa and the Middle East.</p>
                                    <p>Mr. Batt was inducted into the British Travel Industry Hall of Fame in 2014 and won the 2017 EY Entrepreneur of the Year Award for New York and was a national finalist. Mr. Batt is a native of the United Kingdom and holds a Bachelor of Science in Economics and Materials from the University of Wales.</p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="md:flex space-x-2 my-12 text-center md:text-left">
                            <div className="w-full md:w-1/5">
                                <img src="/images/leadership_jdohara.jpg" alt="JD O'Hara" className="mx-auto md:mx-0 mb-10 md-mb-0" loading="lazy" />
                            </div>
                            <div className="w-full md:w-4/5">
                                <h3 className="text-xl uppercase text-black font-semibold">
                                    J.D. O’Hara
                                </h3>
                                <h4 className="tl-cyan font-semibold">
                                    CEO, Internova Travel Group
                                </h4>
                                <ShowMoreText
                                    lines={8}
                                    more="Read More ▼"
                                    less="Show less ▲"
                                    anchorClass="text-red-600 hover:text-red-900"
                                    expanded={false}
                                    truncatedEndingComponent="..."
                                >
                                    <p>J.D. O’Hara serves as the Chief Executive Officer for Internova Travel Group, one of the world’s largest privately-held travel services companies providing unrivaled expertise in all facets of leisure, corporate and entertainment travel. With more than 4,000 employees, Internova Travel Group (formerly Travel Leaders Group) is comprised of more than 6,000 travel agency locations with over 65,000 travel advisors in more than 80 countries. Mr. O’Hara became CEO of the company in January 2020 after having served as President.</p>
                                    <p>Mr. O’Hara’s core tenets for Internova Travel Group’s success is to provide impeccable service to its travelers, the highest quality support and tools to its travel advisors, and unsurpassed value to its preferred supplier partners.</p>
                                    <p>In May 2020, Mr. O’Hara announced a major reorganization of the company by aligning its premium brands into several divisions, including ALTOUR, Travel Leaders Group, Protravel International/Tzell Travel and Bonotel Exclusive Travel. The reorganization is aimed at enhancing service and support for its advisors and agencies, increasing value for clients and providing suppliers with greater distribution and marketing opportunities.</p>
                                    <p>Prior to rejoining the company as President in 2018, Mr. O’Hara was Senior Partner at Certares Management, LLC., Internova’s majority owner. Mr. O’Hara also serves as a member of the Board of Directors of Internova Travel Group.</p>
                                    <p>
                                        Mr. O’Hara has played a key role in the growth and evolution of the company since its inception in 2008. He was part of the original management buyout of Carlson Leisure Group from Carlson Companies in January 2008 that eventually led to the formation of Travel Leaders Group. The company most recently ranked 7th among
                                        <i>Travel Weekly</i>
                                        ’s 2019 Power List of the largest travel agencies and ranked 6th among the top travel management companies by
                                        <i>
                                            Business Travel Europe.
                                        </i>
                                    </p>
                                    <p>In his various operating roles within the company and as a member of the Board of Directors, Mr. O’Hara has always had direct oversight of Internova’s vast investment activity, including the mergers and acquisitions of Tzell Travel Group, Nexion Travel Group, Vacation.com, Protravel International, Andrew Harper Travel and ALTOUR, among many more.  As the company sought to expand beyond its core U.S. footprint, Mr. O’Hara led Internova’s international growth strategy with the acquisitions of Mexico-based Corporate Travel Services, and UK-based agencies Colletts Travel and Barrhead Travel, as well as meeting and event services company Your Event Solutions (YES).</p>
                                    <p>Prior to joining Internova, Mr. O’Hara worked in the investment banking industry in New York. He received a Master of Business Administration from Vanderbilt University and a Bachelor of Arts from the University of Waterloo (Canada).</p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="md:flex space-x-2 my-12 text-center md:text-left">
                            <div className="md:w-1/5">
                                <img src="/images/leadership_jlovell.jpg" alt="John Lovell" className="mx-auto md:mx-0 mb-10 md-mb-0" loading="lazy" />
                            </div>
                            <div className="md:w-4/5">
                                <h3 className="text-xl uppercase text-black font-semibold">
                                    John Lovell
                                </h3>
                                <h4 className="tl-cyan font-semibold">
                                    President, Travel Leaders Group
                                </h4>
                                <ShowMoreText
                                    lines={8}
                                    more="Read More ▼"
                                    less="Show less ▲"
                                    anchorClass="text-red-600 hover:text-red-900"
                                    expanded={false}
                                    truncatedEndingComponent="..."
                                >
                                    <p>A travel industry executive for more than two decades, John Lovell, CTC, is President of Travel Leaders Group, a division that includes Internova Travel Group’s consortia operation, its large host agency and its wholly-owned cruise, leisure and specialty agencies.</p>
                                    <p>Mr. Lovell also oversees the company’s partnerships with travel suppliers, which includes cruise lines, tour operators, car rental companies and travel insurance entities.</p>
                                    <p>He is also responsible for the strategic operations, sales, marketing and overall growth of Travel Leaders Network, which recently expanded into 80 countries. One of the largest travel agency organizations in North America, Travel Leaders Network’s thousands of travel agency locations focus on the sale of luxury travel, cruises, river cruises and tours, along with various other types of travel. Travel Leaders Network was formed in 2016 when Travel Leaders Franchise Group, comprising Travel Leaders Associates and Results! Travel, combined with Vacation.com. The Travel Leaders Group division also comprises several Travel Leaders wholly-owned leisure businesses, including All Aboard Travel, CruCon Cruise Outlet, Cruise Specialists and Travel Leaders Vacation Center, Barrhead Travel of Scotland and luxury sports tour operator Roadtrips. </p>
                                    <p>Prior to his current role, Mr. Lovell served as President, Leisure Travel, Supplier Relations &amp; Networks for Travel Leaders Group and as the President of Vacation.com, which, at the time, was the largest travel agency consortium in the U.S. His earlier roles at Vacation.com included Vice President of Sales, Industry Relations and Revenue Management, and Vice President of Sales and Business Development. Mr. Lovell has also held management positions at ANC Rental Corporation, Renaissance Travel Solutions and Hertz Corporation.</p>
                                    <p>Mr. Lovell currently serves on several travel industry advisory boards, including Azamara Club Cruises, Travel Insured International and Holland America. He formerly served as a member of the Board of Directors for both Tourism Cares and the American Society of Travel Advisors (ASTA).</p>
                                    <p>In 2015, Mr. Lovell founded the Family Bonds Foundation, the non-profit giving arm of Travel Leaders Group that supports the charitable needs of those who work within the travel industry as well as the broader community.</p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="md:flex space-x-2 my-12 text-center md:text-left">
                            <div className="md:w-1/5">
                                <img src="/images/leadership_rblock.jpg" alt="Roger Block" className="mx-auto md:mx-0 mb-10 md-mb-0" loading="lazy" />
                            </div>
                            <div className="md:w-4/5">
                                <h3 className="text-xl uppercase text-black font-semibold">
                                   Roger Block
                                </h3>
                                <h4 className="tl-cyan font-semibold">
                                    President, Travel Leaders Network
                                </h4>
                                <ShowMoreText
                                    lines={8}
                                    more="Read More ▼"
                                    less="Show less ▲"
                                    anchorClass="text-red-600 hover:text-red-900"
                                    expanded={false}
                                    truncatedEndingComponent="..."
                                >
                                    <p>Roger E. Block is president of Travel Leaders Network, part of Travel Leaders Group, a North American travel company that generates gross travel sales approaching $21 billion annually and directly employs over 1,000 staff managing operations of more than 7,000 company-owned, franchised and affiliated travel agencies in the United States, Canada, Greece, Ireland, the United Kingdom and Australia. Both Travel Leaders Network and Travel Leaders Group are headquartered in Minneapolis, Minnesota.</p>
                                    <p>In his current role, Mr. Block oversees the strategic development and growth of Travel Leaders Network. With nearly 7,000 Associate (franchise) and Member travel agencies in Canada and the United States, over 35,000 individual travel agents and more than $17 billion in annual sales, Travel Leaders Network is the largest seller of luxury travel, cruises, river cruises and tours in the travel agency industry. Travel Leaders Network was formed in 2017 when Travel Leaders Franchise Group (Travel Leaders Associates and Results! Travel) combined with Vacation.com.</p>
                                    <p>Prior to serving as President of Travel Leaders Network, Block served as the President of Travel Leaders Franchise Group, which was spun off from travel and hospitality company, Carlson, in January 2008. Mr. Block had served as Executive Vice President of Carlson Travel Franchise Group, responsible for the company’s travel franchise operations, including Carlson Wagonlit Travel Associates, Cruise Holidays, Results! Travel and SeaMaster Cruises brands. Under Mr. Block, over a thousand locations were added to the franchise system network.</p>
                                    <p>Mr. Block joined Carlson in 1997 following the acquisition of Travel Agents International (TAI) by Carlson Leisure Group, into which most TAI franchisees converted. Mr. Block was the Chairman, President and Founder of TAI. Before TAI, Block was Senior Vice President, Director and Corporate Treasurer of Community Banks of Florida, Inc., with responsibility for fund management of the Holding Company banks.</p>
                                    <p>In addition to his leadership within Travel Leaders Network, Mr. Block currently serves as Chairman of the Board of the American Society of Travel Agents (ASTA) and is also on ASTA’s Executive Committee as its Chairman. He has been on the ASTA Board since 2012. Mr. Block was one of three founding members and founding officer of ASTA’s Corporate Advisory Committee (CAC) and served as Treasurer from 2003 to 2005, Vice Chairman from 2005 to 2007 and Chairman from 2007 to 2009. Additionally, in tandem with his prior CAC leadership role, Mr. Block previously served on the ASTA Board from 2005 to 2009 and served as an ASTA Executive Committee member from 2007-2009. Previously, Mr. Block served on the board of trustees of ICTA, now The Travel Institute.</p>
                                </ShowMoreText>
                            </div>
                        </div>
                    </div>

                    <div id="news" className="mb-16" />
                    <div className="-mt-4 md:-mb-10 md:mt-0 bg-white mb-4">
                        <div className="bg-footer-slate w-full pt-10 pb-8 px-4 text-white text-center">
                            <h2 className="uppercase text-3xl">News Releases</h2>
                            <p className="md:max-w-3xl mx-auto">Read about our company in the news.</p>
                            <div className="text-center mx-4 lg:mx-96 md:mx-48 sm:mx-20 my-10">
                                <Button
                                    onClick={() => window.open('https://internova.com/brands/travel-leaders-network/', '_blank')}
                                    title="Be In The Know"
                                    width="w-64"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <WhyComponent displayStats="hidden" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs;
