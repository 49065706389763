import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../services/API';

const OptOut = () => {
    const [email, setEmail] = useState('');
    const [optedOut, setOptedOut] = useState(false);
    const [error, setError] = useState('');
    const location = useLocation();
    const brand = 'travelleaders';

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailParam = params.get('email');
        if (emailParam) {
            if (validateEmail(emailParam)) {
                setEmail(emailParam);
                handleOptOut(emailParam);
            } else {
                setError('Invalid email address.');
            }
        } else {
            setError('No email address provided.');
        }
    }, [location.search]);

    const validateEmail = (validEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(validEmail);
    };

    const handleOptOut = async (emailToOptOut) => {
        try {
            await API.sendEmailOptOut({ email: emailToOptOut, brand });
            setOptedOut(true);
        } catch (err) {
            setError('Failed to opt out. Please try again later.');
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            {error ? (
                <h3 className="text-xl font-bold text-red-600">
                    {error}
                </h3>
            ) : optedOut ? (
                <h3 className="text-xl font-bold text-gray-800 text-center">
                    We are sorry to see you go!
                    <br />
                    You have successfully opted out with the email:
                    {email}
                </h3>
            ) : (
                        <h3 className="text-xl font-bold text-gray-800">Opting you out...</h3>
                    )}
        </div>
    );
};

export default OptOut;
