import React from 'react';
import { Helmet } from 'react-helmet';


function NotFound() {
    return (
        <>
            <Helmet>
                <title>Sorry, we can&apos;t find this page | Travel Leaders</title>
                <meta name="title" content="404 Not Found | Travel Leaders " />
                <meta name="description" content="Sorry, this page can't be located, or has been moved" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="grid place-items-center h-full my-10 mx-4 md:mx-48">
                <h2 className="tl-cyan uppercase text-4xl tracking-wide text-center">OH NO, YOU’RE LOST!</h2>
                <p className="my-4 text-center leading-snug">Sadly, the page you are looking for does not exist. What does exist are Travel Leaders travel agents who can guide you to your dream destination much better than this sign will. </p>
                <p className="font-bold my-4 text-center">
                    Find your travel agent&nbsp;
                    <a href="/find_travel_agent" className="tl-red hover:text-black">here</a>
                    &nbsp;or return to our&nbsp;
                    <a href="/" className="tl-red hover:text-black">homepage</a>
                    .
                </p>
                <p className="my-4"><img src="/images/404-signs.webp" alt="Travel Signspost" className="max-h-96" loading="lazy" /></p>
                <div className="image-gallery-slide image-gallery-description" />
            </div>
        </>
    );
}

export default NotFound;
