/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-template */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
    useEffect, useState, useRef, createRef,
} from 'react';
import {
    IoClose,
    IoStarOutline,
    IoChevronBack,
    IoGlobeOutline,
} from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import SlideDown from 'react-slidedown';
import parser from 'html-react-parser';
import Modal from 'react-modal';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ReactImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player';
import Collapsible from 'react-collapsed';
import Skeleton from 'react-loading-skeleton';
import Tooltip from 'react-simple-tooltip';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Helmet } from 'react-helmet';

import {
    getStateName,
    modalCustomStyles, processCertificateImages,
    PROMO_IMAGE_URL,
} from '../utils';
import API from '../services/API';
import WhyComponent from '../components/WhyComponent';
import ListItem from '../components/elements/ListItem';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { AgentMap } from '../components/AgentMap';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-loading-skeleton/dist/skeleton.css';
import AgentLabel from '../components/AgentLabel';
import AgentClientReview from '../components/AgentClientReview';
import AgentTestimonials from '../components/AgentTestimonials';
import AgentItineraries from '../components/AgentItineraries';
import BreadCrumb from '../components/BreadCrumb';
import ButtonInverted from '../components/elements/ButtonInverted';

import sanitizeHtml from 'sanitize-html-react';

const ModalTypes = ['destinations', 'interests', 'suppliers', 'album', 'video', 'travel'];

function Agent() {
    const { id } = useParams();

    function useQuery() {
        return new URLSearchParams(useLocation().search.toLowerCase());
    }

    function CollapsibleSection(props) {
        const config = {
            defaultExpanded: props.defaultExpanded || (window.innerWidth > 768 ? true : false),
            collapsedHeight: props.collapsedHeight || 0,
        };
        const {
            getCollapseProps,
            getToggleProps,
            isExpanded,
        } = Collapsible(config);
        return (
            <div className="collapsibleContainer">
                <div className="collapsible">
                    <div className="header flex justify-center space-x-0" {...getToggleProps()}>
                        <div className="w-full text-l font-semibold text-center uppercase">{props.title}</div>
                        <div className="w-1/12 icon md:hidden">
                            <i className={'fas fa-chevron-circle-' + (isExpanded ? 'up' : 'down')} />
                        </div>
                    </div>
                    <div {...getCollapseProps()}>
                        <div className="content">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const query = useQuery();

    const [links, setLinks] = useState([{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a travel Agent',
        url: '/find_travel_agent',
    },
    ]);

    const [section, setSection] = useState(query.get('s') || '');
    const [previewMode, setPreviewMode] = useState(query.get('preview') || false);
    const [referrer, setReferrer] = useState(query.get('ref') || '');
    const [supplier, setSupplier] = useState(query.get('supplier') || '');
    const [loading, setLoading] = useState(false);
    const [agent, setAgent] = useState();
    const [modalType, setModalType] = useState('');
    const [certificates, setCertificates] = useState([]);
    const history = useHistory();
    const travelRef = useRef(null);
    const certificateRef = useRef(null);
    const testimonialRef = useRef(null);
    const myPlacesRef = useRef(null);
    const myPlacesContainerRef = useRef(null);
    const reviewsRef = useRef(null);
    const myAlbumRef = useRef(null);
    const selectedPlaceRef = useRef(null);
    const itinerariesRef = useRef(null);
    const [agentTitle, setAgentTitle] = useState('');
    const [agentText, setAgentText] = useState('');
    const [itemToShow, setItemToShow] = useState(5);
    const [showFull, setShowFull] = useState(false);
    const [showFullReviews, setShowFullReviews] = useState(false);
    const [reviewIdToScroll, setReviewIdToScroll] = useState(null);
    const [noAgentReturned, setNoAgentReturned] = useState(false);
    const [albums, setAlbums] = useState([]);
    const [selectedAlbumId, setSelectedAlbumId] = useState();
    const [videos, setVideos] = useState([]);
    const [stories, setStories] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState();
    const [additionalBio, setAdditionalBio] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [refs, setRefs] = useState([]);

    const executeScroll = (ref, block = 'start', offset = 120) => {
        if (ref.current) {
            const element = ref.current; // Get the current top position of the element
            const elementTop = element.getBoundingClientRect().top;
            // Get the current scroll position
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // Calculate the target position with the offset
            const targetPosition = elementTop + scrollTop - offset;
            // Scroll to the target position smoothly
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth',
            });
        }
    };
    const executeScrollPlace = () => {
        setTimeout(() => {
            selectedPlaceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 200);
    };

    const prepareAdditionalBio = (data) => {
        if (data.agentAddlSpecializations.some((x) => x.addlBioId !== 0)) {
            setAdditionalBio(data.agentAddlSpecializations);
        }
    };

    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,
            agentPreview: previewMode,
        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    setCertificates(processCertificateImages(result.data));
                    prepareAdditionalBio(result.data);
                    getAgentAlbum();
                    getTravelStories();
                    getItineraries();

                    const drefs = [];
                    result.data.agentReviews.map((x) => {
                        drefs[x.reviewId] = createRef();
                    });

                    setRefs(drefs);
                }
                if (result.data.agentId === 0) {
                    setNoAgentReturned(true);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentAlbum = () => {
        setLoading(true);
        API.agentAlbum({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setAlbums(result.data);
                getAgentVideos();
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getTravelStories = () => {
        setLoading(true);
        API.experimentalPost({
            agentId: id,
            bioId: 0,
            blogId: 0,
            returnAll: 'true',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setStories(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentVideos = () => {
        setLoading(true);
        API.agentVideos({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setVideos(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getItineraries = () => {
        setLoading(true);
        API.agentItineraries({
            agentId: id,
            bioId: 0,
            returnAll: 'true',
            preview: 'false',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setItineraries(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
        window.scrollTo(0, 0);
    }, []);

    // Bug fix for my places read More Review Scrolling issue
    // Opens the accordion first (setShowFullReviews) then scrolls to the correct review id
    useEffect(() => {
        if (showFullReviews && reviewIdToScroll && refs[reviewIdToScroll]) {
            executeScroll(refs[reviewIdToScroll], 'center');
            setReviewIdToScroll(null); // Reset after scrolling
        }
    }, [showFullReviews, reviewIdToScroll, refs]);
    const handleParentButtonClick = (reviewId) => {
        setShowFullReviews(true);
        setReviewIdToScroll(reviewId);
    };
    // End my places reviews fix

    const constructAgentTitle = () => {
        if (agent.agentAddlSpecializations.length === 0) {
            setAgentTitle(agent.overviewTitle.length > 0 ? agent.overviewTitle : agent.websiteDisplayTitle);
            setAgentText(agent.expertiseOverview.length > 0 ? agent.expertiseOverview : agent.bioText);
        }
        if (agent.agentAddlSpecializations.length === 1) {
            setAgentTitle(agent.agentAddlSpecializations[0].displayTitle);
            setAgentText(agent.agentAddlSpecializations[0].bioText);
        } else if (agent.agentAddlSpecializations.length > 1) {
            setAgentTitle(agent.overviewTitle);
            setAgentText(agent.expertiseOverview);
        }
    };

    useEffect(() => {
        if (agent) {
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?slctState=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
            }]);
            constructAgentTitle();
        }
    }, [agent]);

    useEffect(() => {
        if (section !== '' && reviewsRef.current) {
            if (section === 'review') {
                setTimeout(() => {
                    executeScroll(reviewsRef);
                }, 1000);
            }
        }
    }, [section, reviewsRef.current]);
    const closeModal = () => {
        //  closeFormModal();

        setModalType('');
    };

    const loader = () => (
        <div className="max-w-screen-2x px-4 min-h-screen ">
            <div>
                <div className="py-4 w-full inline-block align-top">
                    <Skeleton count={1} height={400} />
                </div>
                <div className="py-4 w-full inline-block align-top">
                    <Skeleton count={1} height={800} />
                </div>
            </div>
        </div>
    );

    const agentBio = () => (
        <div className="flex flex-col gap-4">
            <BreadCrumb links={links} />
            {referrer === 'search' ? (
                    <div className="text-gray-500 text-sm font-semibold flex flex-row items-center cursor-pointer gap-1" onClick={() => { history.goBack(); }}>
                        <IoChevronBack className=" text-right" />
                        <div>
                            {' '}
                            Return to Search Results
                        </div>
                    </div>
            ) : ''}
            <div className="flex flex-col gap-10 md:flex-row items-center">
                <div className="flex flex-col md:flex-row gap-4 md:gap-4 items-center  w-full px-10 md:px-0 flex-1">
                    <div>
                        <img
                            alt=""
                            src={agent.agentPhotoFileName}
                            className="w-80 md:w-64 mx-auto rounded-lg"
                            loading="lazy"
                        />

                    </div>
                    <div className="flex flex-col items-center md:items-start  w-full">
                        <AgentLabel agent={agent} />
                    </div>
                </div>
                <div className="text-xl text-center">
                    {agent.agentRating && agent.agentRating.totalReviews > 0
                        && (
                            <div
                                className="flex flex-col gap-1 cursor-pointer"
                                onClick={() => executeScroll(reviewsRef)}
                            >
                                <div>

                                    <StarRatings
                                        rating={agent.agentRating.rating}
                                        starRatedColor="#0A7790"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="40px"
                                        starSpacing="2px"
                                    />

                                </div>
                                <div
                                    className="cursor-pointer"

                                >
                                    {agent.agentRating.totalReviews}
                                    {' '}
                                    {agent.agentRating.totalReviews > 1 ? 'reviews' : 'review'}
                                </div>
                                <div className="font-semibold">
                                    {' '}
                                    {((agent.agentRating.recommended / agent.agentRating.totalReviews) * 100).toFixed(0)}
                                    % Recommended
                                </div>
                            </div>
                        )}

                    {agent.yearsActive && (
                        <div>
                            {agent.yearsActive}
                            {' '}
                            Years of Experience
                        </div>
                    )}
                    {agent.agentLanguages.length > 0 && (
                        <div className="mt-4">
                            <div className="flex justify-center">
                                <IoGlobeOutline />
                            </div>
                            <div className="text-xs uppercase font-bold">
                                Additional Languages
                            </div>
                            <div className="justify-center">
                                {agent.agentLanguages.map((x, index) => (
                                    <div
                                        className="tl-cyan cursor-pointer hover:text-black mr-1 text-sm"
                                        key={index}
                                        onClick={() => history.push(`/agents?slctLanguage=${encodeURIComponent(x.language)}&agentId=${agent.agentId}`)}
                                    >
                                        {`${x.language}${index + 1 < agent.agentLanguages.length ? ',' : ''}`}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="text-red-600 text-4xl mt-4 flex flex-row gap-2 justify-center">

                        {agent.agentConnections.map((x) => (
                            <div key={x.contactMethod}>
                                {x.contactMethod === 'Facebook'
                                    && (
                                        <a href={x.contactValue} target="_blank" aria-label="Visit me on Facbook" rel="noreferrer">
                                            <i className="fab fa-facebook-square text-gray-900" />
                                        </a>
                                    )}
                                {x.contactMethod === 'Instagram'
                                    && (
                                        <a href={x.contactValue} target="_blank" aria-label="Visit me on Instagram" rel="noreferrer">
                                            <i className="fab fa-instagram-square text-gray-900" />
                                        </a>
                                    )}
                                {x.contactMethod === 'Twitter'
                                    && (
                                        <a href={x.contactValue} target="_blank" aria-label="Visit me on X - Formerly Twitter" rel="noreferrer">
                                            <i className="fab fa-twitter-square text-gray-900" />
                                        </a>
                                    )}
                                {x.contactMethod === 'YouTube'
                                    && (
                                        <a href={x.contactValue} target="_blank" aria-label="Visit me on YouTube" rel="noreferrer">
                                            <i className="fab fa-youtube-square text-gray-900" />
                                        </a>
                                    )}
                                {x.contactMethod === 'Pinterest'
                                    && (
                                        <a href={x.contactValue} target="_blank" aria-label="Visit me on Pinterest" rel="noreferrer">
                                            <i className="fab fa-pinterest-square text-gray-900" />
                                        </a>
                                    )}

                                {x.contactMethod === 'LinkedIn'
                                    && (
                                        <a href={x.contactValue} target="_blank" aria-label="Visit me on LinkedIn" rel="noreferrer">
                                            <i className="fab fa-linkedin text-gray-900" />
                                        </a>
                                    )}

                                {x.contactMethod === 'My Blog'
                                    && (
                                        <a href={x.contactValue} target="_blank" aria-label="Visit me website" rel="noreferrer">
                                            <i className="fab fa-blogger text-gray-900" />
                                        </a>
                                    )}
                            </div>
                        ))}

                    </div>
                </div>

            </div>
            <div className="flex flex-col gap-2 px-4  md:px-0 md:py-4">
                <h2 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900">{agentTitle}</h2>
                <div className="text-lg pb-6 agentText">
                    {(agent.addlInfoHeader === '') && (agent.addlInfoText === '') && (agent.agentAddlSpecializations.length <= 1) ? (
                        <div className="float-left mr-4 pt-6">
                            <img src={agent.travelPhotoFileName} className="w-96 " alt={agent.travelPhotoCaption} loading="lazy" />
                            <div className="text-center text-sm text-gray-600 italic">
                                {agent.travelPhotoCaption}
                            </div>
                        </div>
                    ) : ''}
                    {parser(sanitizeHtml(agentText))}
                </div>
                {(agent.addlInfoHeader !== '') && (agent.addlInfoText !== '') && (agent.agentAddlSpecializations.length <= 1) ? (
                    <>
                        <h3 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900">{agent.addlInfoHeader}</h3>
                        <div className="text-lg pt-2 pb-6">
                            <div className="float-left mr-4">
                                <img src={agent.travelPhotoFileName} className="w-96 " alt={agent.travelPhotoCaption} loading="lazy" />
                                <div className="text-center text-sm text-gray-600 italic">
                                    {agent.travelPhotoCaption}
                                </div>
                            </div>
                            {parser(sanitizeHtml(agent.addlInfoText))}
                        </div>
                    </>
                ) : ''}
            </div>
        </div>
    );

    const tabBar = () => {
        const item = '';
        const baseClass = 'text-red-600 md:border rounded-3xl border-red-400 hover:bg-red-400 hover:text-white p-2 mx-2  cursor-pointer hover:scale-105 transition transform  ease-in-out duration-500';

        const separator = () => <hr className="w-10 mx-auto border-gray-700 md:hidden my-2" />;

        const displayTabBar = ((additionalBio.length > 0 ? 1 : 0)
            + ((certificates.length > 0 || agent.agentCertifications.length > 0) ? 1 : 0)
            + (agent.agentReviews.length > 0 ? 1 : 0)
            + (agent.agentTestimonials.length > 0 ? 1 : 0)
            + (agent.agentMaps.length > 0 ? 1 : 0)) >= 2;

        return (
            <>
                {displayTabBar && (
                    <div className="p-5 border shadow-xl z-10 flex md:flex-row flex-col text-center text-sm bg-white justify-center uppercase full-length">
                        {additionalBio.length > 0
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join('')}
                                        onClick={() => {
                                            executeScroll(travelRef);
                                        }}
                                    >
                                        My Travel Specialties

                                    </div>
                                    {separator()}
                                </>
                            )}

                        {(certificates.length > 0 || agent.agentCertifications.length > 0)
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(certificateRef);
                                        }}
                                    >
                                        Certificates &amp; Awards
                                    </div>
                                    {separator()}
                                </>
                            )}
                        {(albums.length > 0 || videos.length > 0 || stories.length > 0)
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(myAlbumRef);
                                        }}
                                    >
                                    Travel Stories &amp; Media
                                    </div>
                                    {separator()}
                                </>
                            )}
                        {itineraries.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(itinerariesRef);
                                    }}
                                >
                                   Itineraries
                                </div>
                                {separator()}
                            </>
                        )}
                        {agent.agentReviews.length > 0 && (
                            <>
                                {' '}
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(reviewsRef);
                                    }}
                                >
                                    Client Reviews
                                </div>
                                {separator()}
                            </>
                        )}
                        {agent.agentMaps.length > 0
                            && (
                                <>
                                    <div
                                    className={[item, baseClass].join('')}
                                        onClick={() => {
                                            executeScroll(myPlacesRef);
                                        }}
                                    >
                                        My Places
                                    </div>
                                    {separator()}
                                </>
                            )}
                        {agent.agentTestimonials.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(testimonialRef);
                                    }}
                                >
                                    Client Testimonials
                                </div>
                                {separator()}
                            </>
                        )}
                    </div>
                )}
            </>
        );
    };

    const agentAreaOfExpertise = () => (
        <div className="">
            <div id="Expertise" className="flex flex-col gap-2 px-10 py-14 -mx-2 md:-mx-20 items-center justify-center bg-light-blue">
                <h3 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900 mb-4">Areas of expertise</h3>
                <div className="flex flex-col md:flex-row text-center md:text-left justify-center gap-4 md:gap-10 w-full md:px-4">
                    {agent.agentDestinations.length > 0 && (
                        <div className="pb-4 border-b border-blue-700  md:border-0">
                            <CollapsibleSection title="Destinations">
                                <div className="flex flex-col md:flex-row gap-10 justify-center my-2">
                                    <div className="flex flex-col items-center md:grid grid-cols-1  md:grid-cols-2  gap-1 text-xs">
                                        {agent.agentDestinations.slice(0, 6).map((x) => (
                                            <div
                                                className="px-4 flex md:block justify-center"
                                                key={x.locationId}
                                                onClick={() => history.push(`/agents?slctDestination=${x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area)}&destinationId=${x.locationId}&agentId=${agent.agentId}`)}
                                            >
                                                <ListItem title={x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area)} isClickable />
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                {agent.agentDestinations.length > 5
                                    && (
                                        <div
                                            onClick={() => setModalType('destinations')}
                                            className="text-red-700 text-sm cursor-pointer hover:text-red-900 px-4 text-center"
                                        >
                                            See All Destinations &gt;
                                        </div>
                                        )}
                            </CollapsibleSection>
                        </div>
                    )}
                    {agent.agentInterests.length > 0 && (
                        <div className="pb-4 border-b border-blue-700  md:border-0">
                            <CollapsibleSection title="Interests">
                                <div className="flex flex-col md:flex-row gap-10 justify-center my-2">
                                    <div className="flex flex-col items-center md:grid grid-cols-1  md:grid-cols-2  gap-1 text-xs">
                                        {agent.agentInterests.slice(0, 6).map((x) => (
                                            <div
                                                className=" px-4 flex md:block  justify-center"
                                                key={x.interestName}
                                                onClick={() => history.push(`/agents?slctInterest=${encodeURIComponent(x.interestName)}&agentId=${agent.agentId}`)}
                                            >
                                                <ListItem title={x.interestName} isClickable />
                                            </div>
                                        ))}

                                    </div>

                                </div>
                                {agent.agentInterests.length > 5
                                    && (
                                        <div
                                            onClick={() => setModalType('interests')}
                                            className="text-red-700 text-sm cursor-pointer hover:text-red-900 px-4 text-center"
                                        >
                                        See All Interests &gt;
                                        </div>
                                        )}
                            </CollapsibleSection>
                        </div>
                    )}

                    {agent.agentSuppliers.length > 0 && (
                        <div className="pb-4 border-b border-blue-700  md:border-0">
                            <CollapsibleSection title="Supplier Partners">
                                <div className="flex flex-col md:flex-row gap-10 justify-center my-2">
                                    <div className="flex flex-col items-center md:grid grid-cols-1  md:grid-cols-2  gap-1 text-xs">
                                        {(supplier.length === 0) || (agent.agentSuppliers.find(({ supplierName }) => supplierName.toLowerCase().trim() === supplier.toLowerCase().trim()) !== undefined) ? '' : (
                                            <div
                                                className="px-4 flex md:block  justify-center capitalize"
                                                onClick={() => history.push(`/agents?slctSupplier=${encodeURIComponent(supplier)}&agentId=${agent.agentId}`)}
                                            >
                                                <ListItem title={supplier} isClickable />
                                            </div>
                                        )}
                                        {agent.agentSuppliers.slice(0, 6).map((x) => (
                                            <div
                                                className="px-4 flex md:block  justify-center"
                                                key={x.supplierName}
                                                onClick={() => history.push(`/agents?slctSupplier=${encodeURIComponent(x.supplierName)}&agentId=${agent.agentId}`)}

                                            >
                                                <ListItem title={x.supplierName} isClickable />
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                {agent.agentSuppliers.length > 5
                                    && (
                                        <div
                                            onClick={() => setModalType('suppliers')}
                                            className="text-red-700 text-sm cursor-pointer hover:text-red-900 px-4 text-center"
                                        >
                                            See All Supplier Partners &gt;
                                        </div>
                                        )}
                            </CollapsibleSection>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );

    const wrapperClass = 'md:-mx-20 -mx-2 md:px-20 md:py-14 flex flex-col';

    const agentTravelSpecialist = () => (
        <div className={[wrapperClass, 'bg-white py-14'].join(' ')} ref={travelRef}>
            <h2 className="text-center md:text-left  tl-cyan uppercase text-4xl tracking-wide px-4">My Travel Specialties</h2>
            <div className="grid grid-cols-1  md:grid-cols-3  gap-4 mt-10">
                {additionalBio.map((x) => (
                    <div
                        className="flex flex-col gap-4 px-4  cursor-pointer"
                        onClick={() => history.push(`/agent/${agent.agentId}/bio/${x.addlBioId}`)}
                        key={x.displayTitle}
                    >
                        <div className="">
                            <img src={x.travelImage} className="w-96" alt={x.displayTitle} loading="lazy" />
                            {' '}
                        </div>
                        <div className="text-l font-semibold uppercase">{x.displayTitle}</div>
                        <div
                            className="text-base"
                        >

                            {x.bioText.length > 300 ? parser(`${sanitizeHtml(x.bioText.slice(0, 300), { allowedTags: [], allowedAttributes: {} })}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)
                                : parser(`${sanitizeHtml(x.bioText, { allowedTags: [], allowedAttributes: {} })}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)}
                        </div>
                        <div />
                    </div>
                ))}
            </div>
        </div>
    );

    const agentTravelStories = () => (
        <div className={[wrapperClass, 'bg-white py-14'].join(' ')} ref={myAlbumRef}>
            {stories.length > 0 && (
                <div>
                    <h2 className="text-center md:text-left tl-cyan uppercase text-4xl tracking-wide px-4">My Travel Stories</h2>
                    <div className="mt-10">
                        <SlideDown className="my-dropdown-slidedown">
                            {stories.slice(0, showFull ? stories.length : itemToShow).map((x) => (
                                <div
                                    className="flex flex-wrap md:flex-nowrap space-x-2 p-4  cursor-pointer"
                                    key={x.postTitle}
                                    onClick={() => history.push(`/agent/${agent.agentId}/story/${x.postId}`)}
                                >
                                    <div className="w-full md:w-1/4  mb-4 md:m-0">
                                        <img src={x.travelImage} className="w-96" alt={x.travelImageCaption} loading="lazy" />
                                        {' '}
                                    </div>
                                    <div className="w-full md:w-3/4 md:px-10 self-center">
                                        <div className="text-l font-semibold uppercase">{x.postTitle}</div>
                                        <div className="text-base">
                                            {x.postText.length > 350 ? parser(`${sanitizeHtml(x.postText.slice(0, 350), { allowedTags: [], allowedAttributes: {} })}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span><p>`)
                                                : parser(`${sanitizeHtml(x.postText, { allowedTags: [], allowedAttributes: {} })}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span><p>`)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SlideDown>
                        {stories.length > itemToShow && (
                            <div className="flex justify-center space-x-2">
                                {!showFull ? (
                                    <ButtonInverted
                                        title="View all Travel Stories"
                                        onClick={() => setShowFull(!showFull)}
                                        width="md:w-1/4 uppercase"
                                    />
                                ) : (
                                        <ButtonInverted
                                            title="View less Travel Stories"
                                            onClick={() => setShowFull(!showFull)}
                                            width="w-1/4 uppercase"
                                        />
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {(albums.length > 0 || videos.length > 0) && (
                <div className="flex flex-col gap-2 py-14 md:px-10 -mx-4 md:-mx-20 items-center justify-center bg-light-blue">
                    <div className="text-center px-10 md:text-left md:px-0 text-2xl font-medium uppercase">My Photo Albums and Videos</div>
                    {/*Destktop Display*/}
                    <div className="hidden md:grid md:grid-cols-3 md:gap-4 mt-10 px-4 md:px-10">
                        {albums.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4 pb-4 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-1000"
                                key={x.albumId}
                                onClick={() => {
                                    setSelectedAlbumId(x.albumId);
                                    setModalType('album');
                                }}
                            >
                                <div className="flex  w-full  overflow-hidden">
                                    <img
                                        src={x.albumImages[0].image}
                                        className="w-full h-56 mx-auto object-cover"
                                        alt=""
                                        loading="lazy"
                                    />
                                    {' '}
                                </div>

                                <div className="flex flex-wrap md:flex-nowrap space-x-2">
                                    <div className="flex w-full self-center text-center tl-cyan text-lg font-medium leading-tight">{x.albumTitle}</div>
                                    <div className="w-full text-center">
                                        <div className="text-sm cursor-pointer text-red-700 bg-white font-medium border-2 p-2 border-red-500 rounded-full w-full text-center">
                                            VIEW ALBUM
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                        {videos.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-1000"
                                key={x.videoId}
                            >
                                <div className="flex md:h-56 overflow-hidden mx-auto bg-black">
                                    <ReactPlayer
                                        controls
                                        url={x.videoURL}
                                        width="100%"
                                        height="100%"
                                    />
                                </div>

                                <div className="flex">
                                    <div className="item w-full text-center"><span className="tl-cyan text-lg font-medium">{x.videoTitle}</span></div>
                                </div>
                            </div>

                        ))}
                    </div>
                    {/*Mobile Display*/}
                    <div className="md:hidden w-full text-center">
                        <Slide
                            duration={5000}
                            arrows={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            transitionDuration={500}
                            pauseOnHover={true}
                            indicators={true}
                            canSwipe={true}
                            autoplay={false}
                        >
                            {albums.map((x) => (
                                <div
                                    className="flex flex-col gap-4 px-4 pb-4"
                                    key={x.albumId}
                                    onClick={() => {
                                        setSelectedAlbumId(x.albumId);
                                        setModalType('album');
                                    }}
                                >
                                    <div className="flex  w-full md:w-96 overflow-hidden">
                                        <img
                                            src={x.albumImages[0].image}
                                            className="w-full md:w-96 h-56 mx-auto object-cover"
                                            alt=""
                                            loading="lazy"
                                        />
                                        {' '}
                                    </div>

                                    <div className="flex flex-wrap md:flex-nowrap space-x-2">
                                        <div className="w-full md:w-2/3 text-center"><span className="tl-cyan text-lg font-medium">{x.albumTitle}</span></div>
                                        <div className="w-full md:w-1/3 text-center">
                                            <div className="text-base cursor-pointer text-red-500 bg-white font-medium border-2 p-2 border-red-500 rounded-full w-full text-center">
                                                VIEW ALBUM
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Slide>
                    </div>
                    <div className="md:hidden w-full text-center">
                        <Slide
                            duration={7500}
                            arrows={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            transitionDuration={500}
                            pauseOnHover={true}
                            indicators={true}
                            canSwipe={true}
                            autoplay={false}
                        >
                            {videos.map((x) => (
                                <div
                                    className="flex flex-col gap-4 px-4 cursor-pointer"
                                    key={x.videoId}
                                >
                                    <div className="flex  md:w-96 h-44 md:h-56 overflow-hidden mx-auto bg-black">
                                        <ReactPlayer
                                            controls
                                            url={x.videoURL}
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>

                                    <div className="flex">
                                        <div className="item w-full text-center"><span className="tl-cyan text-lg font-medium">{x.videoTitle}</span></div>
                                    </div>
                                </div>

                            ))}
                        </Slide>
                    </div>
                    {/*END MOBILE DISPLAY*/}
                </div>
            )}
        </div>
    );

    const agentMaps = () => (
        <div
            id="MyPlaces"
            className={[wrapperClass, 'bg-gray-900 py-14 text-gray-500  items-center'].join(' ')}
            ref={myPlacesRef}
        >
            <h2 className="text-white uppercase text-4xl tracking-wide px-4">My Places</h2>
            <div className="text-lg leading-7 text-white text-center mt-4 px-8">When it comes to travel planning, real-world experience makes a huge difference. Check out the map below to see all the places I have personally visited or booked for my clients. I know these destinations well and can give you reliable guidance.</div>
            <div className="flex flex-col md:flex-row gap-0 mt-10 w-full " ref={myPlacesContainerRef}>
                <div className="md:w-2/3 md:inline-block">
                    <AgentMap
                        selectedLocation={selectedLocation}
                        callback={(val) => {
                            setSelectedLocation(val);
                        }}
                        locations={agent.agentMaps}
                        containerElement={<div className="w-full h-map" />}
                        mapElement={<div style={{ height: '100%' }} />}
                    />
                </div>
                <div className="md:w-1/3 inline-block bg-white overflow-y-auto h-96 md:h-map z-10 sidebar-shadow" tabIndex="0">

                    {agent.agentMaps.map((x) => (
                        <div
                            key={x.mapId}
                            ref={selectedLocation && x.mapId === selectedLocation.mapId
                                ? selectedPlaceRef : null}
                            className={['p-2 border-b border-gray-300',

                                selectedLocation && selectedLocation.mapId === x.mapId ? 'bg-gray-300' : '',
                            ].join(' ')}

                        >
                            <div className="flex justify-center space-x-2">
                                <div className="item w-full">
                                    <div
                                        className="text-sm font-bold uppercase cursor-pointer pb-2"
                                        onClick={() => setSelectedLocation(x)}
                                    >
                                        {`${x.locale}${x.locale ? ', ' : ''}${x.area}${x.area ? ', ' : ''}${x.region}`}

                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center space-x-2">
                                <div className="item w-1/2">
                                    <div className="pb-2">
                                        {x.bookedIt
                                            && (
                                                <div className="text-sm font-light">
                                                    <i className="fas fa-plane text-blue-700" />
                                                    {' '}
                                                    {x.bookingCount > 1 ? `Booked It ${x.bookingCount} Times` : 'Booked It'}
                                                </div>
                                            )}
                                        {x.beenThere
                                            && (
                                                <div className="text-sm font-light">
                                                    <i className="fas fa-suitcase text-green-700" />
                                                    {' '}
                                                    {x.visitCount > 1 ? `Been There ${x.visitCount} Times` : 'Been There'}
                                                </div>
                                            )}
                                    </div>
                                    <StarRatings
                                        rating={x.reviewRating}
                                        starRatedColor="#0A7790"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="2px"
                                    />
                                    <div
                                        className="text-sm text-gray-500 font-light"
                                        onClick={() => handleParentButtonClick(x.reviewId)}
                                    >
                                        {x.review.length > 50 ? parser(`${sanitizeHtml(x.review.slice(0, 50))}...<br/><span class="float-right md:float-none md:block pt-2 tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`) : x.review}

                                    </div>
                                </div>
                                <div className="item w-1/2 self-center">
                                    {x.locationPhoto
                                    && (
                                        <img
                                            src={x.locationPhoto}
                                            className="object-contain w-full"
                                            alt=""
                                            loading="lazy"
                                        />
                                     )}
                                </div>
                            </div>
                            <div className="flex justify-center space-x-2">
                                <div className="item w-full">
                                    {x.locationTip && (
                                        <>
                                            <div className="text-sm text-gray-500 font-semibold mt-4">Must Do:</div>
                                            <div className="text-sm text-gray-500 font-light">{x.locationTip}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );

    const agentCertificate = () => {
        const numberOfCertificates = agent.agentCertifications.filter((x) => x.certification !== 'TTAUS'
            && x.certification !== 'TTACA'
            && x.certification !== 'CAMP').length + certificates.length;
        return (
            <div id="Awards" className="py-6 px-12  flex flex-col gap-6 -mx-2 md:-mx-20 items-center justify-center md:py-16 bg-light-blue" ref={certificateRef}>
                <h2 className="text-center md:text-left tl-cyan uppercase text-4xl tracking-wide px-4">Certificates & Awards</h2>
                <div className={[
                    'grid gap-10 text-xs font-semibold mx-auto mt-4 md:mt-16',
                    numberOfCertificates === 1 ? 'grid-cols-'
                        : numberOfCertificates < 6 ? `grid-cols-2  md:grid-cols-${numberOfCertificates}`
                        : 'grid-cols-3  md:grid-cols-6',
                ].join(' ')}
                >
                    {certificates.map((x, index) => (
                        <div key={index} className="flex flex-col gap-2 items-center tl-cyan">
                            <Tooltip placement="bottom" padding={5} content={`${x.description} `}>
                                {x.image === '/images/HMDW-Registry-Icon.webp' ? (
                                    parser(`<a target="_blank" class="cursor-pointer" href="https://travelleaders.honeymoonwishes.com/?agentId=${agent.agentId}"><img src="${x.image}" className="w-48" alt="${x.description}" loading="lazy" /></a>`)
                                ) : (
                                        <img
                                            src={x.image}
                                            className="w-48"
                                            alt={x.description}
                                            loading="lazy"
                                        />
                                    )}
                            </Tooltip>
                        </div>
                    ))}
                    {agent.agentCertifications.filter((x) => x.certification !== 'TTAUS'
                        && x.certification !== 'TTACA'
                        && x.certification !== 'CAMP').map((x) => (
                            <div key={`${x.certification}-${x.certificationRank}`} className="flex flex-col gap-2 self-top items-center tl-cyan">
                                {!x.imageFile ? (
                                    <IoStarOutline className="w-16 h-16 md:w-20 md:h-20 mx-auto" />
                                ) : (
                                        <img
                                            src={`${PROMO_IMAGE_URL()}/supplierCerts/${x.imageFile}`}
                                            className="w-16 h-16 md:w-24 md:h-24 mx-auto"
                                            alt={x.certification}
                                            loading="lazy"
                                        />
                                    )}
                                <div className="text-xs md:text-sm  font-medium w-20 md:w-40 text-center">{x.certification}</div>
                            </div>
                        ))}
                </div>

            </div>
        );
    };

    const appendSupplier = (data, suplr) => {
        if (suplr === '') {
            return data;
        }
        const appendedSupplier = data || [];
        if (data.length > 0) {
            if (!data.some((x) => x.supplierName === suplr)) {
                appendedSupplier.push({
                    supplierName: suplr,
                });
            }
        } else {
            appendedSupplier.push({
                supplierName: suplr,
            });
        }
        return appendedSupplier;
    };

    useEffect(() => {
        if (selectedLocation) { executeScrollPlace(); }
    }, [selectedLocation]);

    return (
        <div className="max-w-screen-2xl mx-auto">
            <ToastContainer />
            {agent && (
                <div className="h-full  flex flex-col pt-24 md:pt-12 px-4 md:px-10 mx-auto">
                        <div className="md:mx-10">
                            {agentBio()}
                        </div>
                        {tabBar()}
                        <div className="md:mx-10">
                            {agentAreaOfExpertise()}
                            {additionalBio.length > 0 && agentTravelSpecialist()}
                            {(certificates.length > 0 || agent.agentCertifications.length > 0)
                                && agentCertificate()}
                            {(albums.length > 0 || videos.length > 0 || stories.length > 0) && agentTravelStories()}
                            {itineraries.length > 0
                                && (
                                    <div ref={itinerariesRef}>
                                        <AgentItineraries agent={agent} itineraries={itineraries} />
                                    </div>
                                )}
                            {agent.agentReviews.length > 0
                                && (
                                    <div ref={reviewsRef}>
                                        <AgentClientReview
                                            agent={agent}
                                            refs={refs}
                                            setLoading={setLoading}
                                            showFull={showFullReviews}
                                            setShowFull={setShowFullReviews}
                                            callback={() => getAgentBio()}
                                        />
                                    </div>
                                )}
                            {agent.agentMaps.length > 0 && agentMaps()}

                            {agent.agentTestimonials.length > 0
                                && (
                                    <div ref={testimonialRef}>
                                        <AgentTestimonials agent={agent} />
                                    </div>
                                    )}
                        </div>
                        <div className={wrapperClass}>
                            <WhyComponent displayStats="hidden" />
                        </div>
                        <Modal
                            isOpen={ModalTypes.some((x) => x === modalType)}
                            onRequestClose={() => closeModal()}
                            style={modalCustomStyles}
                            contentLabel="Modal"
                            ariaHideApp={false}
                        >
                            <div className="max-w-6xl">
                                <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                                    <IoClose className="text-red-500 text-right" />
                                </div>
                                <div className=" px-4">
                                    {modalType === 'interests'
                                        && (
                                            <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                                {agent.agentInterests.map((x) => (
                                                    <div
                                                        key={x.interestName}
                                                        onClick={() => history.push(`/agents?slctInterest=${encodeURIComponent(x.interestName)}&agentId=${agent.agentId}`)}
                                                    >

                                                        <ListItem title={x.interestName} isClickable />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    {modalType === 'destinations'
                                        && (
                                            <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                                {agent.agentDestinations.map((x) => (
                                                    <div
                                                        key={x.locationId}
                                                        onClick={() => history.push(`/agents?slctDestination=${x.locationType === 'Cit' ? encodeURIComponent(x.locale) : (x.locationType === 'Reg' ? encodeURIComponent(x.region) : encodeURIComponent(x.area))}&destinationId=${x.locationId}&agentId=${agent.agentId}`)}
                                                    >

                                                        <ListItem
                                                            title={x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area)}
                                                            isClickable
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    {modalType === 'suppliers'
                                        && (
                                            <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                                {agent.agentSuppliers.map((x) => (
                                                    <div
                                                        key={x.supplierName}
                                                        onClick={() => history.push(`/agents?slctSupplier=${encodeURIComponent(x.supplierName)}&agentId=${agent.agentId}`)}
                                                    >

                                                        <ListItem title={x.supplierName} isClickable />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    {modalType === 'album'
                                        && (
                                            <div>
                                                <div className="text-red-700 text-xl font-medium text-center mb-6">
                                                    {albums.find((x) => x.albumId === selectedAlbumId).albumTitle}
                                                </div>
                                                <ReactImageGallery
                                                    items={albums.find((x) => x.albumId === selectedAlbumId)
                                                        .albumImages.map((y) => ({
                                                            original: y.image,
                                                            thumbnail: y.imageThumb,
                                                            thumbnailAlt: y.imageCaption,
                                                            originalTitle: y.imageCaption,
                                                            originalAlt: y.imageCaption,
                                                            originalWidth: 100,
                                                            description: y.imageCaption,
                                                        }))}
                                                    additionalClass="max-w-xs md:max-w-3xl"
                                                    lazyLoad={true}
                                                    showNav={true}
                                                    showPlayButton={false}
                                                    showBullets={false}
                                                />
                                            </div>
                                        )}

                                    {modalType === 'travel' && (
                                        <div className="h-56 flex justify-center items-center">
                                            REDIRECT TO  ADDITIONAL BIO
                                        </div>
                                    )}

                                </div>
                            </div>
                        </Modal>
                </div>
            )}
            {noAgentReturned === true ? (
                <>
                    <Helmet>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                    <div className="mx-4 md:mx-20 py-20 text-center h-screen">
                        <h2 className="tl-cyan uppercase text-4xl tracking-wide">Sorry, this agent unavailable</h2>
                        <div>
                            <h3 className="uppercase text-xl my-2 font-semibold tracking-wide">
                                It looks like the agent you have selected is no longer a part of our network.
                                <br />
                                <a href="/find_travel_agent" className="tl-red hover:text-black ">Don’t worry, we have plenty of other agents to choose from</a>
                            </h3>
                        </div>
                    </div>
                </>
            ) : ''}
            {loading && <Loading /> && loader()}
        </div>
    );
}

export default Agent;
